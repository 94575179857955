import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/forms/base-form/base-form.component';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent extends BaseComponent implements OnInit {
  ngOnInit(): void {
    // Clear all relevant authentication and session data
    localStorage.removeItem('csrf_token');
    this._authService.set_user_authenticated(false);
    this._authService.current_user = null;
    this._authService.studios = null;

    // Call logout API
    this._authRESTService.logout()
    .subscribe(response => {
      console.log(response);
      this._router.navigate(['/auth/login'], {queryParams: {logged_out: true}});
    }, error => {
      // Even if API call fails, still redirect to login
      this._router.navigate(['/auth/login'], {queryParams: {logged_out: true}});
    });
  }
}
