import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CalendarOptions, createElement, FullCalendarComponent } from '@fullcalendar/angular'; // useful for typechecking
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import momentPlugin from '@fullcalendar/moment';

import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { LessonComponent } from 'src/app/components/forms/lesson/lesson.component';
import { GroupLessonComponent } from 'src/app/components/forms/group-lesson/group-lesson.component';
import { SchedulesComponent } from 'src/app/components/forms/schedules/schedules.component';
import { ServicesComponent } from 'src/app/components/forms/services/services.component';
import { StudentAccountComponent } from 'src/app/components/forms/student-account/student-account.component';
import { AppointmentDetailsComponent } from 'src/app/components/forms/appointment-details/appointment-details.component';
import { InquiryComponent } from 'src/app/components/forms/inquiry/inquiry.component';

import $, { data } from "jquery";
import moment from 'moment';
import { ComponentType } from '@angular/cdk/portal';
import { UtilityService } from 'src/app/services/utility.service';
import { Router } from '@angular/router';
import { EventLessonEntityComponent } from '../forms/event-lesson-entity/event-lesson-entity.component';
import { PaymentsEntityComponent } from '../forms/payments-entity/payments-entity.component';
import { DialogService } from 'src/app/services/dialog.service';
import { EventSchedulesEntityComponent } from '../forms/event-schedules-entity/event-schedules-entity.component';
import { StudentInquiryEntityComponent } from '../forms/student-inquiry-entity/student-inquiry-entity.component';
import { EventGroupLessonEntityComponent } from '../forms/event-group-lesson-entity/event-group-lesson-entity.component';
import { EventServicesEntityComponent } from '../forms/event-services-entity/event-services-entity.component';
import { UpdateLessonComponent } from '../forms/update-lesson/update-lesson.component';
import { UpdateGroupLessonComponent } from '../forms/update-group-lesson/update-group-lesson.component';
import { UpdateServiceComponent } from '../forms/update-service/update-service.component';
import { AuthService } from 'src/app/services/auth.service';

// Tippy JS
import { createPopper } from '@popperjs/core';
import tippy from 'tippy.js';
import { hideAll } from 'tippy.js';
import { roundArrow } from 'tippy.js';
import { BaseComponent } from '../forms/base-form/base-form.component';

/**
 * IMPORTANT NOTE: Year Boundary Fix
 *
 * This component includes a special fix for handling dates at year boundaries.
 *
 * The issue:
 * When viewing dates like Sunday, December 29, 2024, which should be considered
 * part of week 1 of 2025, the API was incorrectly receiving 2024 as the year parameter.
 *
 * The fix:
 * A custom method `getCorrectYearForWeek()` was implemented to properly determine
 * the correct year for week-based calculations, especially for dates at the end
 * of December that belong to week 1 of the next year.
 *
 * See the `getCorrectYearForWeek()` method documentation for more details.
 */

@Component({
  selector: 'app-day-view',
  templateUrl: './day-view.component.html',
  styleUrls: ['./day-view.component.css']
})
export class DayViewComponent extends BaseComponent implements OnInit {

  @ViewChild('instructorInfo') instructorInfo;
  // @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  @ViewChild('customPopover') customPopover: ElementRef;


  LessonComponent = LessonComponent;
  GroupLessonComponent = GroupLessonComponent;
  SchedulesComponent = SchedulesComponent;
  ServicesComponent = ServicesComponent;
  StudentAccountComponent = StudentAccountComponent;
  InquiryComponent = InquiryComponent;
  EventLessonEntityComponent = EventLessonEntityComponent
  PaymentsEntityComponent = PaymentsEntityComponent
  EventScheduleEntityComponent = EventSchedulesEntityComponent;
  StudentInquiryEntityComponent = StudentInquiryEntityComponent;
  EventGroupLessonEntityComponent = EventGroupLessonEntityComponent;
  EventServicesEntityComponent = EventServicesEntityComponent;
  UpdateLessonComponent = UpdateLessonComponent;
  UpdateGroupLessonComponent = UpdateGroupLessonComponent;
  UpdateServiceComponent = UpdateServiceComponent;

  copyData = {};
  AMTDayView: any = "";
  AMTConfiguration: any = "";
  InstructorList: {} = "";
  AMTCalendarConfiguration: any = "";

  Events: any;
  Resources = {};

  // Filter configuration
  filterInstructorCategory: string[];
  filterLessonType: any;
  filterInstructor: string[] = [];
  filterStudent: string = "";
  filterDate: string = "";
  filterGroupLesson: boolean = false;
  filterLesson: boolean = false;
  filterServices: boolean = false;
  filterHiddenEvents: boolean = false;

  // FullCalendar
  currentSelectedInstructorID;
  currentSelectedDuration;
  currentSelectedStartTime;

  summaryCount = {
    privateLessonDaily: 0,
    privateLessonWeekly: 0,
    privateTakenLessonDaily: 0,
    privateTakenLessonWeekly: 0,
  }

  newDateStart: string;
  newDateEnd: string;
  AMTSessionTime: Object;

  tippyHideDuration = 100;
  tippyInstructorHoverInstances = new Map();
  weekNumber: string;

  intervalId = setInterval(() => {
    this.refreshCalendar();
  }, 60000);
  noTippy: boolean = false;

  ngOnInit(): void {
    this.getAMTCalendarConfiguration();
    this.getAMTFilterConfiguration();
    this.getAMTSessionTime();
  }

  ngOnDestroy(): void {
    // Close all dialogs, otherwise dialogs will stay open when navigating.
    this._dialogService.closeAll();

    clearInterval(this.intervalId);
  }

  openEntityComponent(component: ComponentType<unknown>, eckType: any, bundle: any, action: any, EntityID?: any, fieldsData?: {}) {
    // console.log('fieldsData')
    // console.log(fieldsData)
    this._dialogService.openDialog(component, "defaultWithData", {
      data: {
        EntityID: EntityID,
        eckType: eckType,
        bundle: bundle,
        action: action,
        fieldsData: fieldsData ?? '',
      },
    }).afterClosed().subscribe(data => {
      this.refreshCalendar();
    });
  }

  public dialogAddLessonData(configOptions) {
    configOptions['data'] = {
      instructors: this.filterInstructorCategory,
      lesson_types: this.filterLessonType
    };
    return configOptions;
  }

  /**
   * Utility to open dialogs with a default configuration.
   *
   * @param formName
   * @param dialogConfig
   */
  public openDialog(formName: ComponentType<unknown>, configName?, data?) {
    this._dialogService.openDialog(formName, configName, data)
      .afterClosed()
      .subscribe(data => {
        // wait 1 second and refresh the calendar.
        setTimeout(() => {
          this.refreshCalendar();
        }, 1000);
      });
  }

  /**
   * FullCalendar Configuration.
   */
  calendarOptions: CalendarOptions = {
    timeZone: 'local',
    plugins: [momentPlugin],
    firstDay: 0,
    schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
    initialView: 'resourceTimeGridDay',
    height: "auto",
    refetchResourcesOnNavigate: true,
    allDaySlot: false,
    // Don't allow events to overlap.
    slotEventOverlap: false,
    droppable: true,
    editable: true,

    events: this.getCalendarEvents(),
    resources: this.getCalendarResources(),

    loading: function (isLoading) {
      if (isLoading) {
      } else {
        // Hide custom popover if mouse hover exits.
        $("#custom-popover").hover(null, function () {
          $(this).hide();
        });
        // Hide custom context menu if mouse hover exits.
        $("#custom-context-menu").hover(null, function () {
          $(this).hide();
        });
      }
    },

    eventDrop: (info: any) => {
      let eventID = info?.oldEvent?._def?.extendedProps?.entityId;
      let eventType = info?.event?._def?.extendedProps?.appointmentType;
      let newStartTime = moment(info.event.start).format('YYYY-MM-DDTHH:mm:ss');
      let newInstructor = info?.newResource?._resource?.id;
      let isPosted = info?.event?._def?.extendedProps?.['eventDetails']?.data?.field_status == '59';
      // hideAll() to hide all tippy instances.
      hideAll({ duration: this.tippyHideDuration });
      this.noTippy = true;

      // If the event is posted, revert the move and show message
      if (isPosted) {
        info.revert();
        this.showSnackbar('Posted events cannot be moved.');
        return;
      }

      let patchData = {
        'field_date_and_time': newStartTime,
      };

      if (eventType === 'schedules') {
        patchData['field_schedule_instructor'] = newInstructor;
      } else if (eventType === 'services') {
        patchData['field_executive'] = newInstructor;
      } else {
        patchData['field_instructor'] = newInstructor;
      }

      this._entityRESTService.patchEntity('events', eventType, eventID, patchData).subscribe({
        next: (data) => {
          // Convert the response time to UTC
          let responseTimeUTC = moment(data?.field_date_and_time[0]?.value).utc().format('YYYY-MM-DDTHH:mm:ss');
          if (responseTimeUTC === newStartTime) {
            this.refreshCalendar(); // Refresh to show the new position
          } else {
            info.revert(); // Only revert if the server response doesn't match
            this.showSnackbar('Event is already posted. Unable to update the date and time.');
          }
        },
        error: (error) => {
          // Handle any errors from the API
          info.revert(); // Revert on error
          this.showSnackbar('An error occurred while updating.');
        },
        complete: () => {
          this.noTippy = false;
        }
      });
    },

    // Change in duration
    eventResize: (eventResizeInfo: any) => {
      let eventID = eventResizeInfo?.oldEvent?._def?.extendedProps?.entityId;
      let eventType = eventResizeInfo?.event?._def?.extendedProps?.appointmentType;

      // hideAll() to hide all tippy instances.
      hideAll({ duration: this.tippyHideDuration });
      this.noTippy = true;

      this._entityRESTService.patchEntity('events', eventType, eventID, {
        'field_duration': this.calcDuration(eventResizeInfo.event.start, eventResizeInfo.event.end),
      }).subscribe((data) => {
        this.noTippy = false;
        this.refreshCalendar();
      });
    },

    eventContent: (arg: any) => {
      // Confirmation status for events.
      let isConfirmed = arg.event._def.extendedProps?.['eventDetails']?.data?.isConfirmed;
      let lessonType = arg.event._def.extendedProps?.['appointmentType'];

      // This is where the meat of content comes through.
      let contentToShow = document.createElement('div');
      contentToShow.innerHTML = arg.event._def.extendedProps['dataToShow'];

      // Add confirmation status.
      let confirmationStatus = document.createElement('div');
      confirmationStatus.className = "confirmation-status";

      // Only show confirmations for regular lessons.
      if (isConfirmed && lessonType == 'lesson') {
        confirmationStatus.innerHTML = '<span class="material-icons">check_box</span>';
      } else if (isConfirmed == false && lessonType == 'lesson') {
        confirmationStatus.innerHTML = '<span class="material-icons">check_box_outline_blank</span>';
      }

      // Makes confirmation clickable.
      confirmationStatus.addEventListener("click", (e: Event) => {
        e.stopPropagation();
        this.toggleEventConfirmation(arg);
      })

      // Notes icon
      let notesIcon = document.createElement('div');
      notesIcon.className = "notes-icon";
      notesIcon.innerHTML = '<span class="material-icons">notes</span>';

      // Check if the event has notes
      let hasNotes = arg.event._def.extendedProps?.['eventDetails']?.data?.field_notes || arg.event._def.extendedProps?.['eventDetails']?.data?.field_reason_for_canceling;
      if (!hasNotes) {
        notesIcon.style.display = 'none';
      }

      notesIcon.addEventListener("click", (e: Event) => {
        e.stopPropagation();
      });

      let arrayOfDomNodes = [confirmationStatus, contentToShow, notesIcon];

      return { domNodes: arrayOfDomNodes }
    },

    eventDidMount: (info) => {
      // Custom context menu.
      let eventId = info.event.id

      info.el.addEventListener("contextmenu", (jsEvent) => {
        let bundle = info.event._def?.extendedProps?.['appointmentType'];
        if (bundle == 'lesson' /*|| bundle == 'group_lesson'*/ || bundle == 'services' || bundle == 'schedules') {
          jsEvent.preventDefault();
          this.copyData = info.event?._def?.extendedProps?.['eventDetails']?.data;

          // Custom popup box for when selecting times in the calendar.
          var left = jsEvent.pageX;
          var top = jsEvent.pageY;
          var theHeight = $("#custom-context-menu").height();
          $("#custom-context-menu").show();
          $("#custom-context-menu").css(
            "left",
            left - $("#custom-context-menu").width() / 2 + "px"
          );
          $("#custom-context-menu").css(
            "top",
            top - theHeight / 2 + 20 + "px"
          );
          $("#custom-context-menu").css("display", "block");
        }
      });
      if (info.event.extendedProps?.['tooltip'] != '') {
        // Popups for events.
        tippy(info.el, {
          allowHTML: true,
          placement: "bottom",
          arrow: roundArrow,
          animation: 'fade',
          theme: 'light',
          duration: [500, 0],
          content: info.event.extendedProps?.['tooltip'],
          // trigger: "click",
        })
      }

    },

    customButtons: {
      goBackAWeek: {
        text: '<<',
        hint: 'Go back a week',
        click: () => {
          let calendarApi = this.calendarComponent.getApi();
          calendarApi.incrementDate({ weeks: -1 }); // Moves the calendar one week back
        }
      },
      goForwardWeek: {
        text: '>>',
        hint: 'Go forward a week',
        click: () => {
          let calendarApi = this.calendarComponent.getApi();
          calendarApi.incrementDate({ weeks: 1 }); // Moves the calendar one week forward
        }
      }
    },

    headerToolbar: {
      start: 'goBackAWeek,prev,today,next,goForwardWeek',
      center: 'title',
      end: ''
    },

    titleFormat: function (date) {
      let newTitleFormat = moment(date.end.marker).format('dddd[,] MMMM DD[, ]YYYY[ - Week #] ');
      let newTitleWeek = moment;
      newTitleWeek.updateLocale('en', {
        week: {
          dow: 0, // Sunday as the first DOW.
        }
      });

      newTitleFormat += newTitleWeek(date.end.marker).format('w');

      // The weekNumber number
      this.weekNumber = moment(date.end.marker).format('w');

      return (newTitleFormat).toString();
    },

    eventClick: (info) => {
      this.openDialog(AppointmentDetailsComponent, "defaultWithData", info);

      // hideAll() to hide all tippy instances.
      hideAll({ duration: this.tippyHideDuration });
    },

    eventMouseLeave: function (mouseLeaveInfo) {
      // hideAll() to hide all tippy instances.
      hideAll({ duration: this.tippyHideDuration });
    },

    selectable: true,

    select: (info) => {
      // Custom popup box for when selecting times in the calendar.
      var left = info.jsEvent.pageX;
      var top = info.jsEvent.pageY;
      var theHeight = $("#custom-popover").height();
      $("#custom-popover").show();
      $("#custom-popover").css(
        "left",
        left - $("#custom-popover").width() / 2 + "px"
      );
      $("#custom-popover").css(
        "top",
        top - theHeight / 2 + 20 + "px"
      );
      $("#custom-popover").css("display", "block");

      // Get the current starting time.
      this.currentSelectedStartTime = moment(info.start).format('YYYY-MM-DD[T]HH:mm:ss');

      // Calculate duration of selected area.
      this.currentSelectedDuration = this.calcDuration(info.start, info.end);

      // Grab the instructor ID, to be used to pass into the form.
      this.currentSelectedInstructorID = info.resource.id;
    },

    // When the events over loped it set false the selectable of calendar
    selectOverlap: function (event) {
      return false;
    },

    selectAllow: (date) => {
      // Find the time diff for checking the druation.
      var fromTime = date.start.getTime() / 1000;
      var toTime = date.end.getTime() / 1000;
      var timeDiff = (toTime - fromTime) / 3600; // will give difference in hrs


      var theHeight = $("#selected-hours").height();
      $("#selected-hours").css({
        "z-index": "9999",
        position: "absolute",
        display: "block",
      });

      return true;
    },

    dateClick: function (info) {
      info.jsEvent.preventDefault();
      if (info.jsEvent.button === 2) {
        this.currentPasteData = info
      }
    },

    resourceLabelDidMount: (mountArg) => {
      // Hover for the instructors.
      let tippyInstance = tippy(mountArg.el, {
        allowHTML: true,
        placement: "bottom",
        arrow: roundArrow,
        animation: 'fade',
        duration: [500, 0],
        theme: 'light',
        content: mountArg.resource?.extendedProps?.['info'],
      })

      this.tippyInstructorHoverInstances.set(mountArg.resource.id, tippyInstance);
    },

    // resourceLabelWillUnmount: function (unmountArg) {
    //   // Get the Tippy instance associated with the element
    //   const tippyInstance = tippy.getInstance(unmountArg.el);

    //   // If there is a Tippy instance, destroy it
    //   if (tippyInstance) {
    //     tippyInstance.destroy();
    //   }
    // },

    // Default configuration.
    slotDuration: "00:15:00",
    slotLabelInterval: "00:01:00",
    displayEventTime: false,
    slotLabelFormat: {
      hour: 'numeric',
      minute: '2-digit',
      omitZeroMinute: false,
      meridiem: 'short'
    },
  };

  updateCalendarEvents(params: any) {
    // FIX: set FullCalendar to go to the correct date.
    this.calendarComponent.getApi().gotoDate(moment(params.filterDate).toISOString());
    this.calendarOptions.events = this.getCalendarEvents(params);
    this.calendarOptions.resources = this.getCalendarResources(params);
  }

  getCalendarEvents(params: any = null, updateColorsOnly?) {
    return (fetchInfo, successCallback, failureCallback) => {
      this.getAMTSessionTime();

      let endpoint = "/api/dayview/calendar/events?";

      this.newDateStart = moment(fetchInfo.startStr).toISOString()
      this.newDateEnd = moment(fetchInfo.endStr).toISOString()
      this.calendarDateInquiry = moment(fetchInfo.start).set({ "hour": 12, "minute": 0 }).toISOString()

      if (params) {

        if (params.filterDate) {
          endpoint = endpoint + "&calendar_date=" + this.newDateStart;
        }

        if (params.filterStudent) {
          endpoint = endpoint + "&student=" + params.filterStudent;
        }

        if (params.filterLessonType) {
          endpoint = endpoint + "&lessonType=" + params.filterLessonType;
        }

        if (params.filterInstructorCategory) {
          endpoint = endpoint + "&instructor_category=" + params.filterInstructorCategory;

          // this.getCalendarResources(params);
        }

        if (params.filterGroupLesson) {
          endpoint = endpoint + "&group_lesson=" + params.filterGroupLesson;
        }

        if (params.filterLesson) {
          endpoint = endpoint + "&lesson=" + params.filterLesson;
        }

        if (params.filterServices) {
          endpoint = endpoint + "&services=" + params.filterServices;
        }

        if (params.filterHiddenEvents) {
          endpoint = endpoint + "&hidden_events=" + params.filterHiddenEvents;
        }

      }

      // Send start && end times.
      endpoint = endpoint + "&start=" + this.newDateStart;
      endpoint = endpoint + "&end=" + this.newDateEnd;

      // Run change on next browser MicroTask.
      Promise.resolve().then(() => {
        this.filterDate = moment(this.newDateStart).toISOString();
      })

      if (updateColorsOnly) {
        this.Events = this.fixEventColors(data);
        successCallback(data);
        return;
      }

      this._drupalRESTService.httpGET(endpoint).subscribe(data => {
        const events = data as any[]; // Cast data to an array
        if (!events || events.length === 0) {
          successCallback([]);
        } else {
          this.Events = this.fixEventColors(events);
          successCallback(events);
        }
      }, error => {
        console.error('Error fetching calendar events:', error);
        successCallback([]);
      });
    }
  }

  fixEventColors(data) {
    // console.log('Events: ', data);

    // Check if colors should change.
    for (const key in data) {
      let event = data[key];
      if (moment(data[key].end) < moment()) {
        // console.log('event', event);
        if (event?.appointmentType == 'lesson' && event?.status != 'Showed' && event?.status !== 'Cancelled' && event?.status !== 'Rescheduled' && event?.status !== 'No Showed Not Charged') {
          // data[key].color = "#FF4136";
        }
        // // Schedules
        // if (event?.appointmentType == 'schedules' && event?.status != 'Showed' && event?.status !== 'Cancelled' && event?.status !== 'Rescheduled' && event?.status !== 'No Showed Not Charged') {
        //   data[key].color = "#474747";
        // }
        // // Services
        // if (event?.appointmentType == 'services' && event?.status != 'Showed' && event?.status !== 'Cancelled' && event?.status !== 'Rescheduled' && event?.status !== 'No Showed Not Charged') {
        //   data[key].color = "#888888";
        // }
      }
      // If event is No Show, Charged
      if (event?.status == 'No Showed, Charged') {
        data[key].color = "#DD7500";
      }
    }
    return data;
  }

  handleEventDidMount(info: any) {
    // console.log(info.event.extendedProps);
    // eventDidMount
  }

  getCalendarResources(thisParams: any = null) {
    let endpoint = "/api/dayview/calendar/resources?";

    return (fetchInfo, successCallback, failureCallback) => {
      if (this.AMTCalendarConfiguration?.['office_hours']?.[moment(fetchInfo.startStr).format('dddd')]?.['start']) {
        this.calendarComponent.options.slotMinTime = this.AMTCalendarConfiguration['office_hours'][moment(fetchInfo.startStr).format('dddd')]['start'];
        this.calendarComponent.options.slotMaxTime = this.AMTCalendarConfiguration['office_hours'][moment(fetchInfo.startStr).format('dddd')]['end'];
      }

      let params = [
        { parameter: 'start', value: fetchInfo.startStr },
        { parameter: 'end', value: fetchInfo.endStr },
        { parameter: 'week', value: moment(fetchInfo.startStr).format('w') },
        // Using custom year calculation to handle year boundary cases correctly
        // Standard moment().isoWeekYear() doesn't work because our weeks start on Sunday
        // while ISO weeks start on Monday, causing issues at year boundaries
        { parameter: 'year', value: this.getCorrectYearForWeek(fetchInfo.startStr) },
      ];

      if (thisParams?.filterInstructorCategory) {
        params = [
          ...params,
          { parameter: 'instructor_category', value: thisParams.filterInstructorCategory },
        ];
      }

      this._drupalRESTService.httpGET(endpoint, params)
        .subscribe(data => {
          this.calcSummary(data);
          successCallback(data);

          // Update the instructor hover info.
          this.tippyInstructorHoverInstances.forEach((tippyInstance, resourceId) => {
            if (this.calendarComponent && this.calendarComponent.getApi()) {
              const resource = this.calendarComponent.getApi().getResourceById(resourceId);
              if (resource) {
                tippyInstance.setContent(resource.extendedProps['info']);
              }
            }
          });
        },
          error => this.handleError(error)
        );
    };
  }

  calcSummary(data) {
    // console.log("calcSummary called...", data)
    let alreadyCounted: number[] = [];

    // Reset Summary
    this.summaryCount = {
      privateLessonDaily: 0,
      privateLessonWeekly: 0,
      privateTakenLessonDaily: 0,
      privateTakenLessonWeekly: 0,
    }

    data.forEach((element: { id: string; privateLesson: number[]; privatePostedLesson: number[]; }) => {
      let id = parseInt(element.id)

      if (alreadyCounted.includes(id)) { } else {
        this.summaryCount.privateLessonWeekly += element.privateLesson[1];
        this.summaryCount.privateLessonDaily += element.privateLesson[0];
        this.summaryCount.privateTakenLessonWeekly += element.privatePostedLesson[1];
        this.summaryCount.privateTakenLessonDaily += element.privatePostedLesson[0];

        alreadyCounted.push(id);
      };
    });
  }

  // Get the session time.
  getAMTSessionTime() {
    let endpoint = "/api/dayview/calendar/configurations-session";
    this._drupalRESTService.httpGET(endpoint)
      .subscribe(data => {
        this.AMTSessionTime = data;
        this.processAMTSessionTime();
      });
  }

  // Load configuration sessions from Drupal.
  processAMTSessionTime() {
    var CurrentDayDate = new Date(this.newDateStart);
    let dataObject = this.AMTSessionTime;

    // Convert day number to day name
    const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    let dayName = daysOfWeek[CurrentDayDate.getDay()];

    // Strip the gray colors.
    $('td:nth-child(2)').css(
      "background",
      "none"
    );

    // Check if the day exists in dataObject and apply styles
    if (dataObject[dayName]) {
      dataObject[dayName].forEach(function (item) {
        $('td[data-time="' + item + '"]:nth-child(2)').css(
          "background",
          "#ccc"
        );
      });
    }

    // Log all variables
    // console.log('CurrentDayDate', CurrentDayDate);
    // console.log('Day of Week (Local)', CurrentDayDate.getDay());
    // console.log('Day Name', dayName);
    // console.log('dataObject', dataObject);
  }

  getAMTCalendarConfiguration() {
    let endpoint = "/api/dayview/calendar/settings";

    this._drupalRESTService.httpGET(endpoint)
      .subscribe({
        next: (data) => {
          this.AMTCalendarConfiguration = data;

          // Get current day's office hours
          const currentDay = moment().format('dddd');
          const daySpecificHours = this.AMTCalendarConfiguration['office_hours'][currentDay];

          this.calendarOptions = {
            ...this.calendarOptions,
            slotDuration: data['duration'],
            nowIndicator: true,
            slotLabelInterval: data['slot_interval'],
            displayEventTime: data['event_time'],
            resourceOrder: 'order_id',
            // Set initial time range based on current day
            slotMinTime: daySpecificHours?.start || data['office_hours']['minTime'],
            slotMaxTime: daySpecificHours?.end || data['office_hours']['maxTime']
          };
        },
        error: (error) => {
          console.error('Error loading calendar configuration:', error);
          // Handle error appropriately
        }
      });
  }

  getAMTFilterConfiguration() {
    let endpoint = "/api/dayview/calendar/filterInit";

    this._drupalRESTService.httpGET(endpoint)
      .subscribe(data => {
        this.filterLessonType = data['lessonTypes'];

        this.filterInstructorCategory = data['teacherCategory'];
        this.filterInstructor = data['teacherCategory'];
      });
  }

  getInstructorList() {
    let endpoint = "/api_rest/v1/teacherList";

    this._drupalRESTService.httpGET(endpoint)
      .subscribe(data => {
        this.InstructorList = data;
      })
  }

  getAMTAutocomplete() {
    let endpoint = "/api_rest/v1/autocomplete?";

    this._drupalRESTService.httpGET(endpoint)
      .subscribe(data => {
        this.AMTConfiguration = data;
      });
  }

  getAMTDayView() {
    let endpoint = "/api/dayview/resource?instructor_category=78&instructor=&lessonType=&student=&calendar_date=&start=2021-12-01T00%3A00%3A00&end=2021-12-02T00%3A00%3A00&_=1638394993251";

    this._drupalRESTService.httpGET(endpoint)
      .subscribe(data => {
        this.AMTDayView = data;
      });
  }

  override handleError(error: any) {
    // console.log("error", error);


    // Handle authentication errors.
    if (error.status == 302) {
      // this.errorMessage = error.error.message;
      // console.log('this means user logged in')
    }

    // Handle authentication errors.
    if (error.status == 403 || error.status == 400) {

      // TODO: this should save state

      // User needs to login.
      this._authService.set_user_authenticated(false);
      this._router.navigate(['/auth/login'], { queryParams: { logged_out_inactive: true } })
    }
  }

  calcDuration(dateStart: moment.MomentInput, dateEnd: moment.MomentInput) {
    let startDate = moment(dateStart);
    let endDate = moment(dateEnd);
    let duration = moment.duration(endDate.diff(startDate));

    let MINUTES = duration.asMinutes();
    let m = duration.asMinutes() % 60;
    let h = (MINUTES - m) / 60;
    let HHMM = (h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();

    return HHMM
  }

  toggleEventConfirmation(event) {
    console.log('event', event);

    // Confirmation status for events.
    let isConfirmed = event.event._def.extendedProps?.['eventDetails']?.data?.isConfirmed;
    let eventID = event.event._def.extendedProps?.['entityId'];

    let body = {
      field_is_confirmed: !isConfirmed,
    }

    this._entityRESTService.patchEntity('events', 'lesson', eventID, body).subscribe(data => {
      this.refreshCalendar();
    })
  }

  isEventHidden(): boolean {
    return this.copyData?.['field_exclude_from_display'] == 1;
  }

  hideClick() {
    this.toggleEventVisibility(true);
  }

  unhideClick() {
    this.toggleEventVisibility(false);
  }

  toggleEventVisibility(hide: boolean) {
    $("#custom-context-menu").hide();

    const bundle = this.copyData?.['bundle'];
    const entityType = 'events';
    const eventId = this.copyData?.['id'];

    if (!bundle || !eventId) {
      console.error('No event selected or missing bundle/id');
      return;
    }

    const patchData = {
      field_exclude_from_display: [{ value: hide }]
    };

    this._entityRESTService.patchEntity(entityType, bundle, eventId, patchData)
      .subscribe(
        response => {
          console.log(`Event ${hide ? 'hidden' : 'unhidden'} successfully`, response);
          this.refreshCalendar();
        },
        error => {
          console.error(`Error ${hide ? 'hiding' : 'unhiding'} event`, error);
          if (this._snackBar) {
            this._snackBar.open(`Failed to ${hide ? 'hide' : 'unhide'} event. Please try again.`, 'Close', {
              duration: 3000,
            });
          }
        }
      );
  }

  copyClick() {
    $("#custom-context-menu").hide();

    const bundle = this.copyData?.['bundle'];
    const entityType = 'events';
    let componentType: ComponentType<unknown>;
    let fieldsData: any = {};
    const ensureArray = (value: any) => Array.isArray(value) ? value : (value ? [value] : []);

    this._entityRESTService.getEntity(entityType, bundle, this.copyData?.['id']).subscribe(data => {
      switch (bundle) {
        case 'lesson':
          componentType = EventLessonEntityComponent;
          fieldsData = {
            'field_type': data?.['field_type'],
            'field_instructor': data?.['field_instructor']?.['id'],
            'field_duration': this.copyData?.['eventDuration'],
            'field_date_and_time': data?.['field_date_and_time'],
            'field_student': [{
              'type': 'attendees',
              'bundle': 'attendance',
              'title': data?.['field_student']?.['title'],
              'field_description': data?.['field_student']?.['field_description'],
              'field_enrollment': data?.['field_student']?.['field_enrollment'],
              'field_status': '64', // Pending status
              'field_students': data?.['field_student']?.['field_students'],
              'field_student_account': (data?.['field_student']?.['title'] + ' (' + data?.['field_student']?.['field_student_account'] + ')'),
            }]
          };
          break;
        case 'services':
          componentType = EventServicesEntityComponent;
          fieldsData = {
            'field_type': data?.['field_type'],
            'field_executive': data?.['field_executive']?.['id'],
            'field_duration': this.copyData?.['eventDuration'],
            'field_date_and_time': data?.['field_date_and_time'],
            'field_notes': data?.['field_notes'],
            'field_student': [{
              'type': 'attendees',
              'bundle': 'attendance',
              'field_student_account': data?.['field_student']?.['field_student_account']
                ? `${data?.['field_student']?.['title']} (${data?.['field_student']?.['field_student_account']})`
                : undefined,
              'field_students': data?.['field_student']?.['field_students'],
            }]
          };
          break;
        case 'group_lesson':
          componentType = EventGroupLessonEntityComponent;
          fieldsData = {
            'field_type': data?.['field_type'],
            'field_instructor': ensureArray(data?.['field_instructor']?.['id']),
            'field_duration': this.copyData?.['eventDuration'],
            'field_date_and_time': data?.['field_date_and_time'],
            'field_students': ensureArray(data?.['field_students']).map(student => this.prepareStudentData(student))
          };
          break;
        case 'schedules':
          componentType = EventSchedulesEntityComponent;
          fieldsData = {
            'field_type': data?.['field_type'],
            'field_duration': this.copyData?.['eventDuration'],
            'field_date_and_time': data?.['field_date_and_time'],
            'field_schedule_instructor': [data?.['field_schedule_instructor']?.['id']],
          };
          break;
        default:
          console.error('Unsupported bundle type:', bundle);
          return;
      }

      this._dialogService.openDialog(componentType, "defaultWithData", {
        data: {
          EntityID: null,
          eckType: entityType,
          bundle: bundle,
          action: 'create',
          fieldsData: fieldsData,
        },
      }).afterClosed().subscribe(data => {
        this.refreshCalendar();
      });
    });
  }

  private prepareStudentData(studentData: any): any {
    if (!studentData) return null;

    return {
      'type': 'attendees',
      'bundle': 'attendance',
      'title': studentData?.['title'],
      'field_description': studentData?.['field_description'],
      'field_enrollment': studentData?.['field_enrollment'],
      'field_status': '64', // Pending status
      'field_students': studentData?.['field_students'],
      'field_student_account': `${studentData?.['title']} (${studentData?.['field_student_account']})`,
    };
  }

  pasteClick() {
    console.log('paste click');
  }

  closeContextMenu() {
    $("#custom-context-menu").hide();
  }

  hideCustomPopover() {
    this.customPopover.nativeElement.style.display = 'none';
  }

  /**
   * Determines the correct year to use for week-based calculations, especially at year boundaries.
   *
   * This function handles special cases where the calendar week and calendar year don't align,
   * particularly for dates at the end of December that belong to week 1 of the next year.
   *
   * The calendar system uses Sunday as the first day of the week, which means:
   * - The last few days of December can be part of week 1 of the next year
   * - For example: Sunday, Dec 29, 2024 is part of week 1 of 2025
   *
   * Without this function, API calls would use the wrong year parameter for these dates,
   * causing incorrect data to be returned.
   *
   * Logic:
   * 1. Direct week/month checks for obvious cases
   * 2. For late December dates, determine if they belong to week 1 of next year by:
   *    - Finding the first Sunday of next year
   *    - If that Sunday is on or before Jan 7, then the last Sunday of current year
   *      starts week 1 of next year
   *    - If our date is on/after that last Sunday, it belongs to next year
   *
   * @param dateStr - ISO date string to determine the correct year for
   * @returns The correct year to use for week-based calculations
   */
  getCorrectYearForWeek(dateStr: string): number {
    const date = moment(dateStr);
    const weekNum = parseInt(date.format('w'));
    const calendarYear = parseInt(date.format('YYYY'));

    // Special case for end of year/beginning of next year
    if (weekNum === 1 && date.month() === 11) {
      // If it's week 1 and December, it belongs to next year
      return calendarYear + 1;
    } else if (weekNum >= 52 && date.month() === 0) {
      // If it's week 52/53 and January, it belongs to previous year
      return calendarYear - 1;
    }

    // For dates near year boundary (last days of December)
    if (date.month() === 11 && date.date() >= 29) {
      // Get the first day of week 1 of next year
      const firstDayNextYear = moment(new Date(calendarYear + 1, 0, 1));
      const dayOfWeek = firstDayNextYear.day();
      const daysToFirstSunday = dayOfWeek === 0 ? 0 : 7 - dayOfWeek;
      const firstSundayNextYear = moment(new Date(calendarYear + 1, 0, 1 + daysToFirstSunday));

      // If first Sunday of next year is January 7 or earlier, then the last days of December
      // belong to week 1 of next year
      if (firstSundayNextYear.date() <= 7) {
        const lastSundayThisYear = moment(new Date(calendarYear, 11, 31));
        lastSundayThisYear.subtract(lastSundayThisYear.day(), 'days');

        // If our date is on or after the last Sunday of the year
        if (date.isSameOrAfter(lastSundayThisYear, 'day')) {
          return calendarYear + 1;
        }
      }
    }

    return calendarYear;
  }

}
