import { Component, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { ViewTableComponent } from '../../base/view-table/view-table.component';
import { environment } from '../../../../environments/environment';
import { PrintService } from '../../../services/print.service';
import { FieldsService } from '../../../services/fields.service';
import { DrupalRESTService } from '../../../services/drupal-rest.service';
import { AuthService } from '../../../services/auth.service';
import { DialogService } from '../../../services/dialog.service';
import { EntityRESTService } from '../../../services/entity-rest.service';
import { UtilityService } from '../../../services/utility.service';
import { TaxonomyService } from '../../../services/taxonomy.service';
import { AuthRESTService } from '../../../services/auth-rest.service';
import { MenuRESTService } from '../../../services/menu-rest.service';
import { FormBuilder } from '@angular/forms';
import { RouteEventsService } from '../../../route-events.service';
import { PermissionsService } from '../../../services/permissions.service';
import { Renderer2 } from '@angular/core';
import { ConfigPagesService } from '../../../services/config-pages.service';
import { PackagesService } from '../../../services/packages.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../../services/user.service';
import { DomSanitizer } from '@angular/platform-browser';

interface StaffSuccessCategory {
  '# Tried': number | null;
  '# Sold': number | null;
  '% Sold (Wk)': number | null;
  '# Tried YTD': number | null;
  '# Sold YTD': number | null;
  '% Sold (YTD)': number | null;
}

interface InstructorSuccessData {
  instructor_name: string;
  categories: {
    DE: StaffSuccessCategory;
    PRE: StaffSuccessCategory;
    ORI: StaffSuccessCategory;
    EXT: StaffSuccessCategory;
    REN: StaffSuccessCategory;
    MISC: StaffSuccessCategory;
    NON: StaffSuccessCategory;
    SUN: StaffSuccessCategory;
  };
}

@Component({
  selector: 'app-staff-success-report',
  templateUrl: './staff-success-report.component.html',
  styleUrls: ['./staff-success-report.component.css']
})
export class StaffSuccessReportComponent extends ViewTableComponent implements OnInit {
  override displayedColumns: string[] = [
    'category',
    'tried',
    'sold',
    'percentWk',
    'triedYtd',
    'soldYtd',
    'percentYtd'
  ];

  year: string;
  week: string;
  override instructorData: InstructorSuccessData[] = [];

  constructor(
    override _activatedRoute: ActivatedRoute,
    override _fieldsService: FieldsService,
    override _drupalRESTService: DrupalRESTService,
    override _authService: AuthService,
    override _dialogService: DialogService,
    override _entityRESTService: EntityRESTService,
    override _utilityService: UtilityService,
    override _taxonomyService: TaxonomyService,
    override _router: Router,
    override _authRESTService: AuthRESTService,
    override _menuRESTService: MenuRESTService,
    override _formBuilder: FormBuilder,
    override _routeEventsService: RouteEventsService,
    override _permissionsService: PermissionsService,
    override _renderer2: Renderer2,
    override _configPages: ConfigPagesService,
    override _packagesService: PackagesService,
    override _snackBar: MatSnackBar,
    override _userRESTService: UserService,
    override _el: ElementRef,
    override _changeDetectorRef: ChangeDetectorRef,
    override _sanitizer: DomSanitizer,
    private printService: PrintService
  ) {
    super(
      _dialogService,
      _entityRESTService,
      _utilityService,
      _fieldsService,
      _drupalRESTService,
      _taxonomyService,
      _router,
      _activatedRoute,
      _authRESTService,
      _authService,
      _menuRESTService,
      _formBuilder,
      _routeEventsService,
      _permissionsService,
      _renderer2,
      _configPages,
      _packagesService,
      _snackBar,
      _userRESTService,
      _el,
      _changeDetectorRef,
      _sanitizer
    );
  }

  override ngAfterContentInit(): void {
    this._activatedRoute.queryParams.subscribe(data => {
      this.ShowProgressBar = false;

      if (data['year']) {
        this.year = data['year'];
      } else {
        this.year = moment().format("YYYY");
      }

      if (data['week']) {
        this.week = data['week'];
      } else {
        this.week = moment().add(0, 'week').format("w");
      }

      this.getData([
        { parameter: 'week', value: this.week},
        { parameter: 'year', value: this.year},
      ]);
    });
  }

  onYearChange(event: any): void {
    this.year = event.value;
    this.WeeksForYearData = this._fieldsService.WeeksForYearData(Number.parseInt(this.year));
  }

  override getData(params: { parameter: string; value: string; }[]): void {
    this.ShowProgressBar = true;
    this.queryParams = params;

    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams);
    }

    this._drupalRESTService.httpGET('/api_rest/v1/staff_success_report', params)
      .subscribe({
        next: (response) => {
          this.ShowProgressBar = false;
          this.instructorData = Object.entries(response).map(([instructor_name, categories]) => ({
            instructor_name,
            categories: categories as InstructorSuccessData['categories']
          }));
        },
        error: (error) => {
          this.ShowProgressBar = false;
          console.error('Error fetching staff success data:', error);
        }
      });
  }

  refreshData(): void {
    this.getData(this.queryParams);
  }

  exportReport(): void {
    // Get the current query parameters
    const params = {
      week: this.week,
      year: this.year
    };

    // Create the URL with query parameters
    const baseUrl = environment.hostUrl;
    const queryString = new URLSearchParams(params).toString();
    const studioId = this._authService.studios?.[0]?.id;
    const url = `${baseUrl}/api_rest/v1/staff_success_report_csv?${queryString}&field_studio_reference_target_id=${studioId}`;

    // Trigger file download
    window.open(url, '_blank');
  }

  override resetForm(form?: NgForm): void {
    if (form) {
      form.resetForm();
    }
    this.getData([]);
  }

  getCategoryData(instructor: InstructorSuccessData) {
    if (!instructor || !instructor.categories) {
      return [];
    }

    return Object.entries(instructor.categories).map(([category, data]) => ({
      category,
      ...data
    }));
  }

  printReport() {
    const content = `
      <h2>Staff Success Report</h2>
      <p>Week: ${this.week}, Year: ${this.year}</p>
      ${this.instructorData.map(instructor => `
        <div class="section">
          <h3>${instructor.instructor_name}</h3>
          <table>
            <thead>
              <tr>
                <th>Category</th>
                <th colspan="3">Weekly</th>
                <th colspan="3">Year to Date</th>
              </tr>
              <tr>
                <th></th>
                <th># Tried</th>
                <th># Sold</th>
                <th>% Sold</th>
                <th># Tried</th>
                <th># Sold</th>
                <th>% Sold</th>
              </tr>
            </thead>
            <tbody>
              ${Object.entries(instructor.categories).map(([category, metrics]) => `
                <tr>
                  <td>${category}</td>
                  <td>${metrics['# Tried'] ?? '-'}</td>
                  <td>${metrics['# Sold'] ?? '-'}</td>
                  <td>${metrics['% Sold (Wk)'] !== null ? metrics['% Sold (Wk)'] + '%' : '-'}</td>
                  <td>${metrics['# Tried YTD'] ?? '-'}</td>
                  <td>${metrics['# Sold YTD'] ?? '-'}</td>
                  <td>${metrics['% Sold (YTD)'] !== null ? metrics['% Sold (YTD)'] + '%' : '-'}</td>
                </tr>
              `).join('')}
            </tbody>
          </table>
        </div>
      `).join('')}
    `;

    this.printService.print(content, {
      title: 'Staff Success Report',
      showSchoolName: true
    });
  }
}
