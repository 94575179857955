<mat-card class="configuration-card">
  <mat-card-header>
    <mat-card-title>Studio Information</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div>
      <mat-progress-spinner class="matProgressSpinnerCentered" *ngIf="showProgressSpinner" mode="indeterminate"></mat-progress-spinner>

      <form class="col-12" [formGroup]="studioInformationForm">
        <app-success-message [message]="this.successMessage"></app-success-message>
        <app-error-message [message]="this.errorMessage"></app-error-message>

        <!-- Studio Identity Section -->
        <div class="form-section">
          <h3 class="section-title">Studio Identity</h3>
          <div class="row">
            <div class="col-12">
              <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Studio Display Name</mat-label>
                <input matInput formControlName="studioDisplayName">
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Studio Email</mat-label>
                <input matInput formControlName="studioEmail">
                <mat-error *ngIf="studioInformationForm.get('studioEmail').invalid">
                  Please enter a valid email address.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Studio Phone Number</mat-label>
                <input appPhoneNumberFormatter matInput formControlName="studioPhoneNumber">
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Country</mat-label>
                <mat-select formControlName="country">
                  <mat-option *ngFor="let country of this._fieldsService.countries" [value]="country.abbreviation">{{ country.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Time Zone</mat-label>
                <mat-select formControlName="studioTimeZone">
                  <!-- Option to show all timezones -->
                  <mat-option *ngIf="filteredTimeZones.length !== timeZones.length" [value]="'show_all'" (click)="showAllTimezones()">
                    -- Show All Timezones --
                  </mat-option>
                  <mat-option *ngFor="let timeZone of filteredTimeZones" [value]="timeZone.value">{{ timeZone.display }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <!-- Studio Website Section -->
        <div class="form-section">
          <h3 class="section-title">Studio Website</h3>
          <div formGroupName="studioWebsite">
            <div class="row">
              <div class="col-md-6">
                <mat-form-field class="full-width-field" appearance="outline">
                  <mat-label>Website URI</mat-label>
                  <input matInput formControlName="uri">
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width-field" appearance="outline">
                  <mat-label>Website Title</mat-label>
                  <input matInput formControlName="title">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

        <!-- Studio Address Section -->
        <div class="form-section">
          <h3 class="section-title">Studio Address</h3>
          <div formGroupName="studioAddress">
            <div class="row">
              <div class="col-md-6">
                <mat-form-field class="full-width-field" appearance="outline">
                  <mat-label>First Name</mat-label>
                  <input matInput formControlName="givenName">
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width-field" appearance="outline">
                  <mat-label>Last Name</mat-label>
                  <input matInput formControlName="familyName">
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <mat-form-field class="full-width-field" appearance="outline">
                  <mat-label>Company</mat-label>
                  <input matInput formControlName="company">
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <mat-form-field class="full-width-field" appearance="outline">
                  <mat-label>Street Address</mat-label>
                  <input matInput formControlName="streetAddress">
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <mat-form-field class="full-width-field" appearance="outline">
                  <mat-label>City</mat-label>
                  <input matInput formControlName="city">
                </mat-form-field>
              </div>

              <div class="col-md-4">
                <mat-form-field class="full-width-field" appearance="outline">
                  <mat-label>{{ administrativeAreaLabel }}</mat-label>
                  <mat-select formControlName="state">
                    <mat-option *ngIf="!countryAdministrativeAreas[studioInformationForm.get('country').value]" value="">None</mat-option>
                    <ng-container *ngIf="countryAdministrativeAreas[studioInformationForm.get('country').value]">
                      <mat-option *ngFor="let area of getAdministrativeAreas(studioInformationForm.get('country').value)"
                                  [value]="area.abbreviation">
                        {{ area.name }}
                      </mat-option>
                    </ng-container>
                    <ng-container *ngIf="!countryAdministrativeAreas[studioInformationForm.get('country').value]">
                      <mat-option value="">Enter manually below</mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>

                <!-- Text input for countries without predefined administrative areas -->
                <mat-form-field *ngIf="!countryAdministrativeAreas[studioInformationForm.get('country').value]"
                                class="full-width-field"
                                appearance="outline">
                  <mat-label>{{ administrativeAreaLabel }} (manual entry)</mat-label>
                  <input matInput formControlName="state">
                </mat-form-field>
              </div>

              <div class="col-md-4">
                <mat-form-field class="full-width-field" appearance="outline">
                  <mat-label>{{ postalCodeLabel }}</mat-label>
                  <input matInput formControlName="zipCode">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-card-content>

  <mat-card-actions align="end">
    <button type="button"
            [disabled]="!studioInformationForm.valid"
            (click)="actionType === 'create' ? onSubmit() : onUpdate()"
            mat-raised-button
            color="accent">
      {{ actionType === 'create' ? 'Save' : 'Update' }}
    </button>
  </mat-card-actions>
</mat-card>
