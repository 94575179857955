<!-- smsconfiguration-settings.component.html -->
<div style="height:64px;" class="page-empty-header"></div>

<div class="container-fluid">
    <div class="row">
        <div style="background:#215DAB; height: calc(100vh - 64px); overflow: scroll;" class="col-2">
            <app-configuration-dashboard-menu></app-configuration-dashboard-menu>
        </div>

        <div class="col-10" style="padding-left: 2.5rem;padding-right: 2.5rem;padding-top:2.5rem;overflow:scroll;max-height: calc(100vh - 64px);">
            <div class="container-fluid">
                <h1>SMS Configuration Settings</h1>

                <div *ngIf="showProgressSpinner" class="matProgressSpinnerCentered">
                    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                </div>

                <form [formGroup]="smsConfigForm">
                    <div class="mt-3" *ngIf="successMessage">
                        <div class="alert alert-success">{{ successMessage }}</div>
                    </div>

                    <div class="mt-3" *ngIf="errorMessage">
                        <div class="alert alert-danger">{{ errorMessage }}</div>
                    </div>

                    <!-- Lesson Reminder SMS -->
                    <mat-card class="configuration-card">
                        <mat-card-header>
                            <mat-card-title>Lesson Reminder SMS</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-4">
                                    <mat-slide-toggle formControlName="enableLessonReminder">Enable Lesson Reminder SMS</mat-slide-toggle>
                                </div>
                                <div class="col-7" style="border:.5px thin black; border-radius:7px;" *ngIf="smsConfigForm.get('enableLessonReminder').value">
                                    <div class="row">
                                        <div class="col-4">
                                            <mat-slide-toggle formControlName="enable24HourReminder">Enable 24 Hour Reminder</mat-slide-toggle>
                                        </div>
                                        <div class="col-4">
                                            <mat-slide-toggle formControlName="enable48HourReminder">Enable 48 Hour Reminder</mat-slide-toggle>
                                        </div>
                                        <div class="col-4">
                                            <mat-slide-toggle formControlName="enable72HourReminder">Enable 72 Hour Reminder</mat-slide-toggle>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3" *ngIf="smsConfigForm.get('enableLessonReminder').value">
                                <div class="row">
                                    <div class="col-12">
                                        <mat-form-field class="full-width-field" appearance="outline">
                                            <mat-label>Lesson Reminder SMS Content</mat-label>
                                            <textarea matInput formControlName="lessonReminderSMSTemplate"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                        <mat-card-actions align="end">
                            <button (click)="this.onSubmit()" mat-raised-button color="accent" [disabled]="!smsConfigForm.valid">Save</button>
                        </mat-card-actions>
                    </mat-card>

                    <!-- New Enrollment SMS -->
                    <mat-card class="configuration-card d-none">
                        <mat-card-header>
                            <mat-card-title>New Enrollment SMS</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-12">
                                    <mat-slide-toggle formControlName="enableNewEnrollment">Enable New Enrollment SMS</mat-slide-toggle>
                                </div>
                            </div>
                            <div class="mt-3" *ngIf="smsConfigForm.get('enableNewEnrollment').value">
                                <div class="row">
                                    <div class="col-12">
                                        <mat-form-field class="full-width-field" appearance="outline">
                                            <mat-label>New Enrollment SMS Content</mat-label>
                                            <textarea matInput formControlName="newEnrollmentTemplate"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                        <mat-card-actions align="end">
                            <button (click)="this.onSubmit()" mat-raised-button color="accent" [disabled]="!smsConfigForm.valid">Save</button>
                        </mat-card-actions>
                    </mat-card>

                    <!-- Lesson Confirmation SMS -->
                    <!-- Commented out as requested
                    <mat-card class="configuration-card">
                        <mat-card-header>
                            <mat-card-title>Lesson Confirmation SMS</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-12">
                                    <mat-slide-toggle formControlName="enableLessonConfirmation">Enable Lesson Confirmation SMS</mat-slide-toggle>
                                </div>
                            </div>
                            <div class="mt-3" *ngIf="smsConfigForm.get('enableLessonConfirmation').value">
                                <div class="row">
                                    <div class="col-12">
                                        <mat-form-field class="full-width-field" appearance="outline">
                                            <mat-label>Lesson Confirmation SMS Content</mat-label>
                                            <textarea matInput formControlName="lessonConfirmationTemplate"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                        <mat-card-actions align="end">
                            <button (click)="this.onSubmit()" mat-raised-button color="accent" [disabled]="!smsConfigForm.valid">Save</button>
                        </mat-card-actions>
                    </mat-card>
                    -->

                    <!-- Lesson Rescheduled SMS -->
                    <!-- Commented out as requested
                    <mat-card class="configuration-card">
                        <mat-card-header>
                            <mat-card-title>Lesson Rescheduled SMS</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-12">
                                    <mat-slide-toggle formControlName="enableLessonRescheduled">Enable Lesson Rescheduled SMS</mat-slide-toggle>
                                </div>
                            </div>
                            <div *ngIf="smsConfigForm.get('enableLessonRescheduled').value">
                                <div class="row">
                                    <div class="col-12">
                                        <mat-form-field class="full-width-field" appearance="outline">
                                            <mat-label>Lesson Rescheduled SMS Content</mat-label>
                                            <textarea matInput formControlName="lessonRescheduledTemplate"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                        <mat-card-actions align="end">
                            <button (click)="this.onSubmit()" mat-raised-button color="accent" [disabled]="!smsConfigForm.valid">Save</button>
                        </mat-card-actions>
                    </mat-card>
                    -->

                    <!-- Thank you for first time lessons -->
                    <!-- Commented out as requested
                    <mat-card class="configuration-card">
                        <mat-card-header>
                            <mat-card-title>Thank You for First Time Lessons SMS</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-12">
                                    <mat-slide-toggle formControlName="enableFirstTimeLessonThankYou">Enable Thank You for First Time Lessons SMS</mat-slide-toggle>
                                </div>
                            </div>
                            <div class="mt-3" *ngIf="smsConfigForm.get('enableFirstTimeLessonThankYou').value">
                                <div class="row">
                                    <div class="col-12">
                                        <mat-form-field class="full-width-field" appearance="outline">
                                            <mat-label>First Time Lesson Thank You SMS Content</mat-label>
                                            <textarea matInput formControlName="firstTimeLessonThankYouTemplate"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                        <mat-card-actions align="end">
                            <button (click)="this.onSubmit()" mat-raised-button color="accent" [disabled]="!smsConfigForm.valid">Save</button>
                        </mat-card-actions>
                    </mat-card>
                    -->
                </form>
            </div>
        </div>
    </div>
</div>
