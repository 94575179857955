<nav class="navbar navbar-expand-lg">
    <div class="container-fluid menu-toolbar-mat custom-navbar">
        <a class="navbar-brand" routerLink="splash">
            <img class="logo cursor-pointer" [src]="logo" alt="Agenda Master" />
            <span class="logoText cursor-pointer">Agenda Master</span>
        </a>

        <!-- Studios Button Dropdown (Desktop View) -->
        <div class="studio-dropdown d-none d-lg-block">
            <button *ngIf="this._permissionsService.has_access('view_admin_links')" class="menu-item" mat-button
                [matMenuTriggerFor]="studioMenu">
                <span class="with-dropdown lighter">{{currentStudio}}
                    <mat-icon aria-hidden="false" aria-label="Studios dropdown"
                        class="menu-dropdown">arrow_drop_down</mat-icon>
                </span>
            </button>
            <span *ngIf="!this._permissionsService.has_access('view_admin_links')" class="lighter studio-text">{{currentStudio}}</span>
            <mat-menu #studioMenu="matMenu" class="scrollable-menu">
                <div class="studio-menu-container" (click)="$event.stopPropagation()">
                    <div class="search-container">
                        <mat-form-field appearance="outline">
                            <input matInput placeholder="Search studios..."
                                   [(ngModel)]="studioSearchText"
                                   (keyup)="filterStudios()"
                                   (click)="$event.stopPropagation()">
                            <mat-icon matSuffix>search</mat-icon>
                        </mat-form-field>
                    </div>
                    <button class="menu-item" mat-menu-item
                        *ngFor="let studio of filteredStudios"
                        (click)="handleStudioClick(studio); $event.stopPropagation(); studioMenu.closed.emit()">
                        <span class="studio-name">{{studio.key}}</span>
                        <span class="studio-id">{{studio.id}}</span>
                    </button>
                </div>
            </mat-menu>
        </div>

        <!-- Add the support button as a new menu item -->
        <button type="button" class="btn ms-auto me-2 d-lg-none" id="contactSupportButton"><mat-icon>contact_support</mat-icon></button>
        <button class="navbar-toggler custom-toggler" type="button"
            (click)="toggleNavbar()" aria-controls="navbarNav"
            aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav"
            [ngClass]="{'show': navbarOpen}">
            <ul class="navbar-nav ms-auto">
                <!-- Studios Button Dropdown (Mobile View) -->
                <li class="nav-item d-lg-none">
                    <button *ngIf="this._permissionsService.has_access('view_admin_links')" class="menu-item" mat-button
                        [matMenuTriggerFor]="studioMenuMobile">
                        <span class="with-dropdown lighter">{{currentStudio}}
                            <mat-icon aria-hidden="false"
                                aria-label="Studios dropdown"
                                class="menu-dropdown">arrow_drop_down</mat-icon>
                        </span>
                    </button>
                    <span *ngIf="!this._permissionsService.has_access('view_admin_links')" class="lighter studio-text">{{currentStudio}}</span>

                    <mat-menu #studioMenuMobile="matMenu" class="scrollable-menu">
                        <div class="studio-menu-container" (click)="$event.stopPropagation()">
                            <div class="search-container">
                                <mat-form-field appearance="outline">
                                    <input matInput placeholder="Search studios..."
                                           [(ngModel)]="studioSearchText"
                                           (keyup)="filterStudios()"
                                           (click)="$event.stopPropagation()">
                                    <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
                            </div>
                            <button class="menu-item" mat-menu-item
                                *ngFor="let studio of filteredStudios"
                                (click)="handleStudioClick(studio); $event.stopPropagation(); studioMenuMobile.closed.emit()">
                                <span class="studio-name">{{studio.key}}</span>
                                <span class="studio-id">{{studio.id}}</span>
                            </button>
                        </div>
                    </mat-menu>
                </li>

                <!-- Authenticated User Menu Items -->
                <ng-container *ngIf="this._authService.is_user_authenticated()">
                    <ng-container
                        *ngFor="let item of this._menuRESTService.loadedMenuItems">
                        <!-- Top Level Routes -->
                        <li class="nav-item" *ngIf="!item.below">
                            <button class="menu-item lighter"
                                (click)="closeNavbar()"
                                routerLinkActive="active" mat-button
                                routerLink="{{item.relative}}">
                                <span
                                    class="with-dropdown">{{item.title}}</span>
                            </button>
                        </li>

                        <!-- Nested Routes -->
                        <li class="nav-item"
                            *ngIf="item.below && item.title != 'Administrative' && item.title != 'Tools'">
                            <button class="menu-item" mat-button
                                [matMenuTriggerFor]="menu">
                                <span class="with-dropdown">{{item.title}}
                                    <mat-icon aria-hidden="false"
                                        aria-label="Menu dropdown"
                                        class="menu-dropdown">arrow_drop_down</mat-icon>
                                </span>
                            </button>
                            <mat-menu #menu="matMenu">
                                <ng-container
                                    *ngFor="let nestedItem of item.below">
                                    <button
                                        (click)="closeNavbar()"
                                        *ngIf="nestedItem.title != 'Add Student Account'"
                                        class="menu-item"
                                        routerLinkActive="active"
                                        routerLink="{{nestedItem.relative}}"
                                        mat-menu-item>
                                        <span>{{nestedItem.title}}</span>
                                    </button>
                                    <button
                                        *ngIf="nestedItem.title == 'Add Student Account'"
                                        class="menu-item"
                                        routerLinkActive="active"
                                        (click)="openEntityComponent(StudentInquiryEntityComponent, 'student_accounts', 'student_account', 'create', '', {
                      'dayview_field_date_and_time': this.calendarDateInquiry,
                      'inquiry': false
                    })" mat-menu-item>
                                        <span>{{nestedItem.title}}</span>
                                    </button>
                                </ng-container>
                            </mat-menu>
                        </li>
                    </ng-container>

                    <!-- Add the support button as a new menu item -->
                    <li class="nav-item">
                        <button class="menu-item" mat-button (click)="openDialog(supportRequestForm, 'statusFormWithData', {})">
                        <mat-icon>contact_support</mat-icon>
                        </button>
                    </li>

                    <!-- Admin Menu -->
                    <li class="nav-item">
                        <button class="menu-item" mat-button
                            [matMenuTriggerFor]="menuAdmin">
                            <span class="material-icons">person</span>
                            <span *ngIf="this._authService?.current_user?.name"
                                class="with-dropdown">
                                {{ this._authService.current_user.name }}
                            </span>
                        </button>
                        <mat-menu #menuAdmin="matMenu">
                            <button
                                *ngIf="this._permissionsService.has_access('view_admin_links')"
                                (click)="openAdminLink( '/studio/' + this.currentStudioID + '/edit/')"
                                class="menu-item" routerLinkActive="active"
                                mat-menu-item>
                                <span>Edit Studio Settings</span>
                            </button>
                            <button
                                *ngIf="this._permissionsService.has_access('view_admin_links')"
                                (click)="openAdminLink('/studio-user-register')"
                                class="menu-item" routerLinkActive="active"
                                mat-menu-item>
                                <span>Studio User Register</span>
                            </button>
                            <button
                                *ngIf="this._permissionsService.has_access('view_admin_links')"
                                (click)="openAdminLink('/instructor-reorder')"
                                class="menu-item" routerLinkActive="active"
                                mat-menu-item>
                                <span>Primary Instructor Reorder</span>
                            </button>
                            <button
                                *ngIf="this._permissionsService.has_access('view_admin_links')"
                                (click)="openAdminLink('/admin/reports/audit-trail')"
                                class="menu-item" routerLinkActive="active"
                                mat-menu-item>
                                <span>Audit Trail</span>
                            </button>
                            <button routerLink="configuration" class="menu-item"
                                routerLinkActive="active" mat-menu-item>
                                <span>Configuration</span>
                            </button>
                            <button class="menu-item" routerLinkActive="active"
                                routerLink="auth/logout" mat-menu-item>
                                <span>Logout</span>
                            </button>
                        </mat-menu>
                    </li>
                </ng-container>

                <!-- Login Button (when not authenticated) -->
                <li class="nav-item"
                    *ngIf="!this._authService.is_user_authenticated()">
                    <button routerLink="auth/login" routerLinkActive="active"
                        class="menu-item" mat-button>
                        Log in
                    </button>
                </li>
            </ul>
        </div>
    </div>
</nav>
