<!-- student-dashboard.wrapper.component.html -->
<app-entity-mat-dialog-title title="Student Dashboard" ></app-entity-mat-dialog-title>

<div [ngClass]="{'loading-shade': showProgressSpinner}">
  <ng-container *ngIf="studentDashboardObservable | async as data">
    <div *ngIf="!showProgressSpinner">

		<div mat-dialog-content style="min-height:400px;">

			<!-- Student Summary  -->
			<div *ngIf="data" id="student-summary" class="row justify-content-center">
				<h3 class="text-center mb-3"><b>{{ data['#studentName'] }}</b> Summary</h3>

				<!-- Changed col-2 to col-6 col-md-4 col-lg-2 for better mobile layout -->
				<div class="col-6 col-md-4 col-lg-2 text-center mb-3">
					<div class="summary-circle" style="border-color: #68ff94;">
						<span class="summary-number">{{ data['#totalLessonsEnrolled'] }}</span>
					</div>
					<div class="summary-label">Total Lessons Enrolled</div>
				</div>

				<div class="col-6 col-md-4 col-lg-2 text-center mb-3">
					<div class="summary-circle" style="border-color: #68ff94;">
						<span class="summary-number">{{ data['#totalLessonsTaken'] }}</span>
					</div>
					<div class="summary-label">Total Lessons Taken</div>
				</div>

				<div class="col-6 col-md-4 col-lg-2 text-center mb-3">
					<div class="summary-circle" style="border-color: #FCC761;">
						<span class="summary-number">{{ data['#totalLessonsRemaining'] }}</span>
					</div>
					<div class="summary-label">Total Lessons Remaining</div>
				</div>

				<div class="col-6 col-md-4 col-lg-2 text-center mb-3">
					<div class="summary-circle" style="border-color: #E5E5E1;">
						<span class="summary-number">{{ data['#totalLessonsPaidAhead'] ? data['#totalLessonsPaidAhead'] : '0' }}</span>
					</div>
					<div class="summary-label">Total Lessons Paid Ahead</div>
				</div>

				<div class="col-6 col-md-4 col-lg-2 text-center mb-3">
					<div class="summary-circle" style="border-color: #0077B7;">
						<span class="summary-number">{{ data['#totalFutureLessons'] }}</span>
					</div>
					<div class="summary-label">Total Future Lessons</div>
				</div>
			</div>

			<div *ngIf="data" class="row text-center mt-4">
				<div class="col-lg-3" style="white-space: nowrap;">
					<strong>Enrollment Total:</strong> ${{ enrollmentBalance?.unused_lesson_value || '0' }}
					<strong>Available: </strong> ${{ enrollmentBalance?.remaining_amount || '0' }}
				</div>
				<div class="col-lg-6">
					<strong>Overall Balance Due: </strong>
					<span>${{ overallEnrollmentBalance?.total_balance_due }}</span>

					<strong id="paymentDue">/ Payment Due: </strong>
					<ng-container *ngIf="overallEnrollmentBalance?.next_payment_due_date; else noNextPayment">
						<span>${{ overallEnrollmentBalance?.next_payment_amount_due }} ({{ overallEnrollmentBalance?.next_payment_due_date | date:'MMM d, y' }})</span>
					</ng-container>
					<ng-template #noNextPayment>
						<span>No Next Payment</span>
					</ng-template>

				</div>
				<div class="col-lg-3">
					<strong>Next Lesson Scheduled: </strong>
					<span>{{ formatNextLesson(nextLessonScheduled) }}</span>
				</div>
			</div>

			<div class="">
				<div class="row">
					<div class="col-lg-5 justify-content-center">
						<!-- Student Basic/Inquiry Info -->
						<mat-card *ngIf="data" id="student-basic-info" class="student-info-card">
							<mat-card-title>
								Student Information
							</mat-card-title>
							<mat-card-content>
								<!-- Student and Partner Information Display -->
								<div class="student-info-container">
									<div class="row g-3">
										<!-- Student Information -->
										<div class="col-12 col-sm-6">
											<div class="info-section">
												<p class="mb-2"><strong>{{data?.['#studentBasicInfoWithPartner']?.['student']?.['name']}} {{data?.['#studentBasicInfoWithPartner']?.['student']?.['lastName']}}</strong></p>
												<p class="mb-2">
													{{ data?.['#studentBasicInfoWithPartner']?.['student']?.['cell'] ? (data?.['#studentBasicInfoWithPartner']?.['student']?.['cell'] | phone) : 'Cell: N/A' }}<br/>
													{{ data?.['#studentBasicInfoWithPartner']?.['student']?.['email'] || 'Email: N/A' }}
												</p>
												<div class="address-section">
													{{ data?.['#studentBasicInfoWithPartner']?.['student']?.['address']?.['address_line1'] || 'Address: N/A' }}<br/>
													{{ data?.['#studentBasicInfoWithPartner']?.['student']?.['address']?.['locality'] || '' }}
													<span *ngIf="data?.['#studentBasicInfoWithPartner']?.['student']?.['address']?.['locality'] && data?.['#studentBasicInfoWithPartner']?.['student']?.['address']?.['administrative_area']">,</span>
													{{ data?.['#studentBasicInfoWithPartner']?.['student']?.['address']?.['administrative_area'] || '' }}
													{{ data?.['#studentBasicInfoWithPartner']?.['student']?.['address']?.['postal_code'] || '' }}
												</div>
											</div>
										</div>

										<!-- Partner Information -->
										<div class="col-12 col-sm-6" *ngIf="data?.['#studentBasicInfoWithPartner']?.['partner']?.['name']">
											<div class="info-section partner-section">
												<p class="mb-2"><strong>{{data?.['#studentBasicInfoWithPartner']?.['partner']?.['name']}} {{data?.['#studentBasicInfoWithPartner']?.['partner']?.['lastName']}}</strong></p>
												<p class="mb-2">
													{{ data?.['#studentBasicInfoWithPartner']?.['partner']?.['cell'] ? (data?.['#studentBasicInfoWithPartner']?.['partner']?.['cell'] | phone) : 'Cell: N/A' }}<br/>
													{{ data?.['#studentBasicInfoWithPartner']?.['partner']?.['email'] || 'Email: N/A' }}
												</p>
												<div class="address-section">
													{{ data?.['#studentBasicInfoWithPartner']?.['partner']?.['address']?.['address_line1'] || 'Address: N/A' }}<br/>
													{{ data?.['#studentBasicInfoWithPartner']?.['partner']?.['address']?.['locality'] || '' }}
													<span *ngIf="data?.['#studentBasicInfoWithPartner']?.['partner']?.['address']?.['locality'] && data?.['#studentBasicInfoWithPartner']?.['partner']?.['address']?.['administrative_area']">,</span>
													{{ data?.['#studentBasicInfoWithPartner']?.['partner']?.['address']?.['administrative_area'] || '' }}
													{{ data?.['#studentBasicInfoWithPartner']?.['partner']?.['address']?.['postal_code'] || '' }}
												</div>
											</div>
										</div>
									</div>
								</div>
							</mat-card-content>

							<mat-card-actions>
								<div class="row g-2">
									<div class="col-6">
										<button
											*ngIf="data?.['#studentBasicInfoWithPartner']?.['student']?.['contact_id']"
											class="w-100 addLesson"
											(click)="openEntityComponent(StudentRecordEntityComponent, 'contacts', 'student_record', 'edit', data?.['#studentBasicInfoWithPartner']?.['student']?.['contact_id'])"
											mat-flat-button>
											Edit User
										</button>
									</div>
									<div class="col-6">
										<button
											*ngIf="data?.['#studentBasicInfoWithPartner']?.['partner']?.['contact_id']"
											class="w-100 addLesson"
											(click)="openEntityComponent(StudentRecordEntityComponent, 'contacts', 'student_record', 'edit', data?.['#studentBasicInfoWithPartner']?.['partner']?.['contact_id'])"
											mat-flat-button>
											Edit Partner
										</button>
									</div>
								</div>
							</mat-card-actions>
						</mat-card>
					</div>

					<div class="col-lg-2 image-container">
						<div style="width:203.66px; position:relative;">
							<div *ngIf="studentBadgeInfo?.['status']">
							<img *ngIf="studentBadgeInfo?.['status'] === 'Active'; else inactiveImage"
									class="img-fluid"
									src="assets/images/ActiveShield-Blank2.jpg"
									alt="Active Shield" />
							<ng-template #inactiveImage>
								<img class="img-fluid"
										src="assets/images/InactiveShield-Blank.jpg"
										alt="Inactive Shield" />
							</ng-template>
							<div class="overlay-text top-overlay">{{studentBadgeInfo?.['department']}}</div>
							<div class="overlay-text bottom-overlay">{{studentBadgeInfo?.['status']}}</div>
							</div>
						</div>
					</div>

					<div class="col-lg-5 justify-content-center">
						<!-- Student Basic/Inquiry Info -->
						<mat-card style="height:auto; min-height:260px;" *ngIf="data">
							<mat-card-title>
								Inquiry Details
							</mat-card-title>
							<mat-card-content>
								<!-- <div class="table-responsive"> -->
									<table class="table inquiry-table" *ngIf="data">
										<tr>
											<th class="text-nowrap">Inquired</th>
											<th class="text-nowrap">Booked</th>
											<th class="text-nowrap">Showed</th>
											<th class="text-nowrap">Inq. Method</th>
											<th class="text-nowrap">Inq. Taker</th>
										</tr>
										<tr>
											<td class="text-nowrap">
												{{ (data?.['#inquiry']?.['#rows']?.['0']?.['inquired'] == '') ? 'Not inquired.' : '' }}
												{{ (data?.['#inquiry']?.['#rows']?.['0']?.['inquired'] != '' ? data?.['#inquiry']?.['#rows']?.['0']?.['inquired'] : '') | date:'MM/dd/yy' }}
											</td>
											<td class="text-nowrap">
													{{ (data?.['#inquiry']?.['#rows']?.['0']?.['bookedOn'] == '') ? 'Not yet booked.' : '' }}
													{{ (data?.['#inquiry']?.['#rows']?.['0']?.['bookedOn'] != '' ? data?.['#inquiry']?.['#rows']?.['0']?.['bookedOn'] : '') | date:'MM/dd/yy' }}
											</td>
											<td class="text-nowrap">
													{{ (data?.['#inquiry']?.['#rows']?.['0']?.['showed'] == '') ? 'Not showed yet.' : '' }}
													{{ (data?.['#inquiry']?.['#rows']?.['0']?.['showed'] != '' ? data?.['#inquiry']?.['#rows']?.['0']?.['showed'] : '') | date:'MM/dd/yy' }}
											</td>
											<td class="text-nowrap">
												{{ data?.['#inquiry']?.['#rows']?.['0']?.['inquiryMethod'] }}
											</td>
											<td class="text-nowrap">
												{{ data?.['#inquiry']?.['#rows']?.['0']?.['inquiryTaker'] }}
											</td>
										</tr>
										<tr>
											<th colspan="5">Notes</th>
										</tr>
										<tr>
											<td colspan="5">
												<div class="inquiry-notes">
													{{ data?.['#inquiry']?.['#rows']?.['0']?.['notes'] | truncate:500:true }}
												</div>
											</td>
										</tr>
									</table>
								<!-- </div> -->
								<button
									class="me-1 addLesson w-100 mt-3"
									mat-flat-button
									(click)="openEntityComponent(StudentInquiryDetailsComponent, 'student_accounts', 'student_account', 'edit', studentAccountID)">
									Edit Inquiry Information
								</button>
							</mat-card-content>
						</mat-card>

					</div>
				</div>

			</div>

			<div class="">
				<mat-card>
					<mat-card-title>
						Upcoming Lessons
					</mat-card-title>
					<app-student-future-lessons [drupal_student_id]="this.user.id"></app-student-future-lessons>
				</mat-card>
			</div>

			<div *ngIf="data" class="mt-4" style="min-height:600px;">
				<div class="row">
					<!-- Tab labels -->
					<div class="col-lg-2">
						<!-- Vertical tabs for large screens -->
						<div class="d-none d-lg-block">
							<div class="nav flex-column nav-pills studentDashboardInfo" role="tablist" aria-orientation="vertical">
								<a class="nav-link" [class.active]="selectedTab === 0" (click)="selectedTab = 0">Lesson List</a>
								<a class="nav-link" [class.active]="selectedTab === 1" (click)="selectedTab = 1">Lesson Enrollments</a>
								<a class="nav-link" [class.active]="selectedTab === 2" (click)="selectedTab = 2">Payments</a>
								<a class="nav-link" [class.active]="selectedTab === 3" (click)="selectedTab = 3">Miscellaneous</a>
								<a class="nav-link" [class.active]="selectedTab === 4" (click)="selectedTab = 4">Sundry</a>
								<a class="nav-link" [class.active]="selectedTab === 5" (click)="selectedTab = 5">Non Unit Lessons</a>
								<a class="nav-link" [class.active]="selectedTab === 6" (click)="selectedTab = 6">Non Unit Enrollments</a>
								<a class="nav-link" [class.active]="selectedTab === 7" (click)="selectedTab = 7">Service List</a>
							</div>
						</div>

						<!-- Grid layout for small screens -->
						<div class="d-lg-none">
							<div class="row g-2 mobile-nav-grid">
								<div class="col-6 col-sm-4">
									<a class="nav-link" [class.active]="selectedTab === 0" (click)="selectedTab = 0">Lesson List</a>
								</div>
								<div class="col-6 col-sm-4">
									<a class="nav-link" [class.active]="selectedTab === 1" (click)="selectedTab = 1">Lesson Enrollments</a>
								</div>
								<div class="col-6 col-sm-4">
									<a class="nav-link" [class.active]="selectedTab === 2" (click)="selectedTab = 2">Payments</a>
								</div>
								<div class="col-6 col-sm-4">
									<a class="nav-link" [class.active]="selectedTab === 3" (click)="selectedTab = 3">Miscellaneous</a>
								</div>
								<div class="col-6 col-sm-4">
									<a class="nav-link" [class.active]="selectedTab === 4" (click)="selectedTab = 4">Sundry</a>
								</div>
								<div class="col-6 col-sm-4">
									<a class="nav-link" [class.active]="selectedTab === 5" (click)="selectedTab = 5">Non Unit Lessons</a>
								</div>
								<div class="col-6 col-sm-4">
									<a class="nav-link" [class.active]="selectedTab === 6" (click)="selectedTab = 6">Non Unit Enrollments</a>
								</div>
								<div class="col-6 col-sm-4">
									<a class="nav-link" [class.active]="selectedTab === 7" (click)="selectedTab = 7">Service List</a>
								</div>
							</div>
						</div>
					</div>

					<!-- Tab content -->
					<div class="col-lg-10">
						<div [hidden]="selectedTab !== 0">
								<div class="row">
										<div class="col-12 pt-3 pb-3">
											<div class="d-flex justify-content-center justify-content-md-end">
												<button class="addLesson" mat-flat-button
													(click)="openEntityComponent(EventLessonEntityComponent, 'events', 'lessons', 'create', null, {
														'__field_students_inline_form': {
															'type': 'attendees',
															'bundle': 'attendance',
															'field_student_account': data['#studentName'] + ' (' + studentAccountID + ')'
														}
													})" mat-button>
													Add Lesson
												</button>
											</div>
										</div>
								</div>


							<div class="row">
								<div class="col-12">
									<div class="table-responsive">
										<div class="overflow-auto">
												<table class="mb-3" mat-table matSort [dataSource]="lessonList2Rows">

													<!-- Date  -->
													<ng-container matColumnDef="date">
														<th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
														<td mat-cell *matCellDef="let element">
															<span class="text-nowrap">
																{{element.date_and_time | date:'medium'}}
															</span>
														</td>
													</ng-container>

													<!-- Service  -->
													<ng-container matColumnDef="service">
														<th mat-header-cell *matHeaderCellDef>Service</th>
														<td mat-cell *matCellDef="let element">
															{{ element.service }}
														</td>
													</ng-container>

													<!-- Length/Duration  -->
													<ng-container matColumnDef="length">
														<th mat-header-cell *matHeaderCellDef>Length</th>
														<td mat-cell *matCellDef="let element">
															{{ element.duration }}
														</td>
													</ng-container>

													<!-- Teacher  -->
													<ng-container matColumnDef="teacher">
														<th mat-header-cell *matHeaderCellDef>Teacher</th>
														<td mat-cell *matCellDef="let element">
															{{ element.teacher }}
														</td>
													</ng-container>

													<!-- Contacts Present  -->
													<ng-container matColumnDef="contacts_present">
														<th mat-header-cell *matHeaderCellDef>Contacts Present</th>
														<td mat-cell *matCellDef="let element">
															{{element.contacts_present}}
														</td>
													</ng-container>

													<!-- Status  -->
													<ng-container matColumnDef="status">
														<th mat-header-cell *matHeaderCellDef>Status</th>
														<td mat-cell *matCellDef="let element">
															<div *ngIf="this._permissionsService.has_access('manage_quick_status_edit')">
																<div *ngIf="element.bundle !== 'enrollment'" class="dropdown">
																	<button class="btn btn-sm dropdown-toggle status-badge" [ngClass]="getStatusClass(element.status)" type="button" id="statusDropdown{{element.id}}" data-bs-toggle="dropdown" aria-expanded="false">
																		<span *ngIf="!element.isUpdating">{{ getStatusText(element.status) }}</span>
																		<span *ngIf="element.isUpdating" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
																	</button>
																	<ul class="dropdown-menu" aria-labelledby="statusDropdown{{element.id}}">
																		<li *ngFor="let status of lessonStatuses">
																			<a class="dropdown-item cursor-pointer" (click)="updateStatus(element.id, status.id)">{{ status.name }}</a>
																		</li>
																	</ul>
																</div>
																<!-- <span *ngIf="element.bundle !== 'enrollment' && !element.isUpdating" class="amt-bg-red badge">{{ element.status === '' ? 'Pending Status' : '' }}</span> -->
															</div>
															<div *ngIf="!this._permissionsService.has_access('manage_quick_status_edit')">
																<span class="amt-bg-gray badge">{{ element.status == '63' ? 'Cancelled' : '' }}</span>
																<span class="amt-bg-gray badge">{{ element.status == '61' ? 'No Showed Not Charged' : '' }}</span>
																<span class="amt-bg-orange badge">{{ element.status == '60' ? 'No Showed, Charged' : '' }}</span>
																<span class="amt-bg-red badge">{{ element.status == '64' ? 'Pending Status' : '' }}</span>
																<span class="amt-bg-red badge">{{ element.status == '' ? '' : '' }}</span>
																<span class="amt-bg-purple badge">{{ element.status == '62' ? 'Rescheduled' : '' }}</span>
																<span class="amt-bg-green badge">{{ element.status == '59' ? 'Showed' : '' }}</span>
																<span class="amt-bg-red badge">{{ element.status == '' && element.bundle != 'enrollment' ? 'Pending Status' : '' }}</span>
															</div>
														</td>
													</ng-container>

													<!-- Taken / Rem  -->
													<ng-container matColumnDef="taken / rem">
														<th mat-header-cell *matHeaderCellDef>Taken / Rem</th>
														<td mat-cell *matCellDef="let element">
															<ng-container *ngIf="element.bundle !== 'group_lesson'; else noLessonCounts">
																{{ element.taken }}<span *ngIf="element.taken !== '' && element.remaining !== ''"> / </span>{{ element.remaining }}
															</ng-container>
															<ng-template #noLessonCounts></ng-template>
														</td>
													</ng-container>

													<!-- Edit  -->
													<ng-container matColumnDef="edit">
														<th mat-header-cell *matHeaderCellDef>Edit</th>
														<td mat-cell *matCellDef="let element">
															<mat-icon
																class="cursor-pointer"
																*ngIf="element.bundle == 'lesson'"
																(click)="openEntityComponent(EventLessonEntityComponent, 'events', 'lesson', 'edit', element.id)">
																edit_square
															</mat-icon>
														</td>
													</ng-container>

													<!-- Row shown when there is no matching data. -->
													<tr class="mat-row" *matNoDataRow>
														<td class="mat-cell" colspan="4">No lessons taken.</td>
													</tr>

													<tr mat-header-row *matHeaderRowDef="lessonListColumns"></tr>
													<tr [ngClass]="{'bg-success': element.bundle === 'enrollment'}" mat-row *matRowDef="let element; columns: lessonListColumns;"></tr>

												</table>
										</div>
								</div>
							</div>
						</div>

							<!-- <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator> -->
						</div>


						<div [hidden]="selectedTab !== 1">
							<!-- Content for Lesson Enrollments -->
							<app-lesson-student-report
								[drupal_student_name]="data['#studentName']"
								[enrollmentsToLoad]="'DE,Bonus/Comp Lesson,Extension,Pre-Original,Original,Renewals,Transfer In,Uncategorized,History'"
								[drupal_studentAccount_id]="studentAccountID"></app-lesson-student-report>
						</div>
						<div [hidden]="selectedTab !== 2">
							<!-- Content for Payments -->
							<app-payment-reports
								[drupal_student_name]="data['#studentName']"
								[drupal_account_id]="studentAccountID" [drupal_student_id]="this.user.id"></app-payment-reports>

						</div>
						<div [hidden]="selectedTab !== 3">
							<!-- Content for Miscellaneous -->
							<app-lesson-student-report
							[drupal_student_name]="data['#studentName']"
							[enrollmentsToLoad]="'Miscellaneous'"
							[drupal_studentAccount_id]="studentAccountID"></app-lesson-student-report>

						</div>

						<div [hidden]="selectedTab !== 4">
							<!-- Content for Sundry -->
							<app-lesson-student-report
								[drupal_student_name]="data['#studentName']"
								[enrollmentsToLoad]="'Sundry'"
								[drupal_studentAccount_id]="studentAccountID"></app-lesson-student-report>

						</div>

						<div [hidden]="selectedTab !== 5">
							<!-- Content for Non Unit Lessons -->
							<app-non-unit-lessons
							[drupal_studentAccount_id]="studentAccountID"></app-non-unit-lessons>

						</div>

						<div [hidden]="selectedTab !== 6">
							<!-- Content for Non Unit Enrollments -->
							<div class="mt-3">
								<strong>Master Class Enrolled:</strong> {{ data['#totalMasterclassEnrolled'] }} •
								<strong>Master Class Taken:</strong> {{ data['#totalMasterclassTaken'] }} •
								<strong>Coaching Enrolled:</strong> {{ data['#totalCoachingEnrolled'] }} •
								<strong>Coaching Taken:</strong> {{ data['#totalCoachingTaken'] }}
							</div>
							<app-lesson-student-report
								[drupal_student_name]="data['#studentName']"
								[enrollmentsToLoad]="'Non-Unit'"
								[drupal_studentAccount_id]="studentAccountID"></app-lesson-student-report>
						</div>

						<div [hidden]="selectedTab !== 7">
							<!-- Content for Service List -->
							<div class="row">
								<div class="col-12 pt-3 pb-3">
										<div class="d-flex justify-content-center justify-content-md-end">
												<button class="addService" mat-flat-button
														(click)="openEntityComponent(EventServicesEntityComponent, 'events', 'services', 'create', null, {
																'__field_students_inline_form': {
																		'type': 'attendees',
																		'bundle': 'attendance',
																		'field_student_account': data['#studentName'] + ' (' + studentAccountID + ')'
																}
														})">
														Add Service
												</button>
										</div>
								</div>
							</div>

							<!-- TABLE  -->
							<!-- <div class="table-responsive"> -->
								<div class="row">
									<div class="col-12">
											<div class="table-responsive">
													<div class="overflow-auto">
															<table *ngIf="studentServicesRows" mat-table [dataSource]="studentServicesRows" class="mt-4 w-100">

									<!-- Date  -->
									<ng-container matColumnDef="date">
										<th mat-header-cell *matHeaderCellDef>Date</th>
										<td mat-cell *matCellDef="let element">{{ element[0].data | date:'medium' }}</td>
									</ng-container>

									<!-- Time  -->
									<ng-container matColumnDef="time">
										<th mat-header-cell *matHeaderCellDef>Time</th>
										<td mat-cell *matCellDef="let element">{{ element[1].data }}</td>
									</ng-container>

									<!-- Service  -->
									<ng-container matColumnDef="service">
										<th mat-header-cell *matHeaderCellDef>Service</th>
										<td mat-cell *matCellDef="let element">{{ element[2] }}</td>
									</ng-container>

									<!-- Length  -->
									<ng-container matColumnDef="length">
										<th mat-header-cell *matHeaderCellDef>Length</th>
										<td mat-cell *matCellDef="let element">{{ element[3] }}</td>
									</ng-container>

									<!-- Executive  -->
									<ng-container matColumnDef="teacher">
										<th mat-header-cell *matHeaderCellDef>Executive</th>
										<td mat-cell *matCellDef="let element">{{ element[4] }}</td>
									</ng-container>

								<!-- Status  -->
								<ng-container matColumnDef="status">
									<th mat-header-cell *matHeaderCellDef>Status</th>
									<td mat-cell *matCellDef="let element">
										<span class="badge" [ngClass]="{
											'amt-bg-gray': (element[5]?.data === 'Cancelled' || element[5]?.data === 'No Showed'),
											'amt-bg-orange': (element[5]?.data === 'Incomplete'),
											'amt-bg-red': (element[5]?.data === 'Pending Status' || element[5]?.data === 'No Sale' || element[5]?.data === 'No Status'),
											'amt-bg-purple': (element[5]?.data === 'Rescheduled'),
											'amt-bg-green': (element[5]?.data === 'Sale'),
											'amt-bg-blue': (element[5]?.data === 'Think It Over')
									}">{{ element[5]?.data }}</span>
									</td>
								</ng-container>

									<!-- Edit  -->
									<ng-container matColumnDef="edit">
										<th mat-header-cell *matHeaderCellDef>Edit</th>
										<td mat-cell *matCellDef="let element">
											<mat-icon
												class="cursor-pointer"
												(click)="openEntityComponent(EventServicesEntityComponent, 'events', 'services', 'edit', element.id)">
												edit_square
											</mat-icon>
										</td>
									</ng-container>

									<tr mat-header-row *matHeaderRowDef="studentServicesColumns"></tr>
									<tr mat-row *matRowDef="let row; columns:
																studentServicesColumns;"></tr>
								</table>
							</div>
					</div>

					<div class="text-center my-3" *ngIf="!studentServicesRows || studentServicesRows.length === 0">
							<div class="alert alert-info">
									No services are available.
							</div>
					</div>
			</div>
						</div>
					</div>
				</div>
			</div>


			<div *ngIf="false && data" class="container-fluid mt-4" style="min-height:600px;">
				<mat-tab-group
					animationDuration="0ms">

					<mat-tab label="Lesson List">

					</mat-tab>

					<mat-tab label="Lesson Enrollments">
						<ng-template matTabContent>
							<app-lesson-student-report
								[drupal_student_name]="data['#studentName']"
								[drupal_account_id]="studentAccountID"
								[enrollmentsToLoad]="'DE,Bonus/Comp Lesson,Extension,Pre-Original,Original,Renewals,Transfer In,Uncategorized'"
								[drupal_studentAccount_id]="studentAccountID"></app-lesson-student-report>
						</ng-template>
					</mat-tab>

					<mat-tab label="Payments">
						<ng-template matTabContent>
							<app-payment-reports
								[drupal_student_name]="data['#studentName']"
								[drupal_account_id]="studentAccountID" [drupal_student_id]="this.user.id"></app-payment-reports>
						</ng-template>
					</mat-tab>

					<mat-tab label="Miscellaneous">
						<ng-template matTabContent>
							<!-- <app-misc-student-report [drupal_studentAccount_id]="studentAccountID"></app-misc-student-report> -->
							<app-lesson-student-report
								[drupal_student_name]="data['#studentName']"
								[drupal_account_id]="studentAccountID"
								[enrollmentsToLoad]="'Miscellaneous'"
								[drupal_studentAccount_id]="studentAccountID"></app-lesson-student-report>
						</ng-template>
					</mat-tab>

					<mat-tab label="Sundry">
						<ng-template matTabContent>
							<!-- <app-sundry-student-report [drupal_studentAccount_id]="studentAccountID"></app-sundry-student-report> -->
							<app-lesson-student-report
								[drupal_student_name]="data['#studentName']"
								[drupal_account_id]="studentAccountID" [enrollmentsToLoad]="'Sundry'"
								[drupal_studentAccount_id]="studentAccountID"></app-lesson-student-report>
						</ng-template>
					</mat-tab>

					<mat-tab label="Non Unit Lessons">
						<ng-template matTabContent>
							<app-non-unit-lessons
								[drupal_student_name]="data['#studentName']"
								[drupal_account_id]="studentAccountID"
								[drupal_studentAccount_id]="studentAccountID"></app-non-unit-lessons>
						</ng-template>
					</mat-tab>

					<mat-tab label="Non Unit Enrollments">
						<ng-template matTabContent>
							<div class="mt-3">
								<strong>Master Class Enrolled:</strong> {{ data['#totalMasterclassEnrolled']
								}} •
								<strong>Master Class Taken:</strong> {{ data['#totalMasterclassTaken']
								}} •
								<strong>Coaching Enrolled:</strong> {{ data['#totalCoachingEnrolled']
								}} •
								<strong>Coaching Taken:</strong> {{ data['#totalCoachingTaken']
								}}
							</div>
							<app-lesson-student-report
								[drupal_student_name]="data['#studentName']"
								[drupal_account_id]="studentAccountID" [enrollmentsToLoad]="'Non-Unit'"
								[drupal_studentAccount_id]="studentAccountID"></app-lesson-student-report>
						</ng-template>
					</mat-tab>

					<mat-tab label="Service List">

						<div class>
							<div class="col-md-12 pt-3 pb-3">
								<div class="float-end">
									<button class="addService" mat-flat-button
										(click)="openEntityComponent(EventServicesEntityComponent, 'events', 'services', 'create', null, {
																				'__field_students_inline_form': {
																						'type': 'attendees',
																						'bundle': 'attendance',
																						'field_student_account': data['#studentName'] + ' (' + studentAccountID + ')'
																				}
																		})">
										Add Service
									</button>
								</div>
							</div>
						</div>

						<!-- TABLE  -->
						<table *ngIf="studentServicesRows" class="table" mat-table
							[dataSource]="studentServicesRows">

							<!-- Date  -->
							<ng-container matColumnDef="date">
								<th mat-header-cell *matHeaderCellDef>Date</th>
								<td mat-cell *matCellDef="let element">{{ element[0].data | date:'medium' }}</td>
							</ng-container>

							<!-- Time  -->
							<ng-container matColumnDef="time">
								<th mat-header-cell *matHeaderCellDef>Time</th>
								<td mat-cell *matCellDef="let element">{{ element[1].data }}</td>
							</ng-container>

							<!-- Service  -->
							<ng-container matColumnDef="service">
								<th mat-header-cell *matHeaderCellDef>Service</th>
								<td mat-cell *matCellDef="let element">{{ element[2] }}</td>
							</ng-container>

							<!-- Length  -->
							<ng-container matColumnDef="length">
								<th mat-header-cell *matHeaderCellDef>Length</th>
								<td mat-cell *matCellDef="let element">{{ element[3] }}</td>
							</ng-container>

							<!-- Teacher  -->
							<ng-container matColumnDef="teacher">
								<th mat-header-cell *matHeaderCellDef>Teacher</th>
								<td mat-cell *matCellDef="let element">{{ element[4] }}</td>
							</ng-container>

							<!-- Status  -->
							<ng-container matColumnDef="status">
								<th mat-header-cell *matHeaderCellDef>Status</th>
								<td mat-cell *matCellDef="let element"><span [ngClass]="{
																		'amt-bg-red': element[5].style,
																		'badge' : element[5].style
																		}">{{ element[5].data }}</span></td>
							</ng-container>

							<!-- Edit  -->
							<ng-container matColumnDef="edit">
								<th mat-header-cell *matHeaderCellDef>Edit</th>
								<td mat-cell *matCellDef="let element">
									<mat-icon
										class="cursor-pointer"
										(click)="openEntityComponent(EventServicesEntityComponent, 'events', 'services', 'edit', element.id)">
										edit_square
									</mat-icon>
									<!-- <button mat-flat-button color="accent"
																		(click)="openEntityComponent(EventServicesEntityComponent, 'events', 'services', 'edit', element.id)">
																		Edit Service
																</button> -->
								</td>
							</ng-container>

							<tr mat-header-row *matHeaderRowDef="studentServicesColumns"></tr>
							<tr mat-row *matRowDef="let row; columns:
														studentServicesColumns;"></tr>
						</table>

						<!-- <app-generic-table [Columns]="studentServices['#header']" [Rows]="studentServices['#rows']"></app-generic-table> -->
					</mat-tab>

				</mat-tab-group>
			</div>
		</div>
	</div>
</div>
