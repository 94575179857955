<div class="text-center" style="display: flex; justify-content: center; align-items: center; height: 90vh;">
	<!-- <img style="max-width: 600px; filter: invert(42%) sepia(93%) saturate(0%) hue-rotate(87deg) brightness(0%) contrast(119%);" src="https://arthurmurray.com/assets/images/arthur-murray-international.svg"> -->
	<div style="display: relative;">
		<img class="splash-img img-fluid" src="assets/images/Publication1Blue.jpg">
		<div style="
			display: absolute;
			padding-right:14px;
			color: black;
			font-size: 25px;
			text-shadow: -1px -1px 0 white,
						 1px -1px 0 white,
						-1px 1px 0 white,
						 1px 1px 0 white;">
			{{this._authService.studios?.[0]?.['name'] ?? this._authService.studios?.[0]?.['key']}}
		</div>
		<!-- <div style="display: absolute;">{{ env add ironment.location }}</div> -->
	</div>

</div>
