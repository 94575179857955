import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import moment from 'moment';
import { BaseSimpleDialogComponent } from '../base-simple-dialog/base-simple-dialog.component';

@Component({
  selector: 'app-enrollment-drop',
  templateUrl: './enrollment-drop.component.html',
  styleUrls: ['./enrollment-drop.component.css']
})
export class EnrollmentDropComponent extends BaseSimpleDialogComponent {

  estimatedRefund;
  droppedData;
  now = moment()

  override ngAfterContentInit(): void {
    console.log('this._injectedDialogData["data"]', this._injectedDialogData['data']);
    this.getDroppedData()

    // Load the enrollment. TODO: Maybe pass the enrollment in. I'm not sure if the view loading has all the data.
    this._entityRESTService.getEntity('packages', 'enrollment', this._injectedDialogData['data']['EntityID'])
    .subscribe(data => {
      console.log('enrollmentEntityData', data);
      this.enrollmentEntityData = data;
      this.estimateRefund();
    })
  }

  estimateRefund() {
    this.estimatedRefund = this.enrollmentEntityData.field_balance_due
    - (((this.enrollmentEntityData.field_enrollment_lesson_count - this.enrollmentEntityData.field_lesson_used) * this.enrollmentEntityData.field_enrollment_lesson_price))
    - this.enrollmentEntityData.field_taxes;

    return Math.abs(this.estimatedRefund);
  }

  getDroppedData() {
    let body = [
      { parameter: 'enrollment_id', value: this._injectedDialogData['data']['EntityID'] },
    ];
    this._drupalRESTService.httpGET('/api_rest/v1/enrollmentTotalPriceTotalDropAmounts', body).subscribe(data => {
      this.droppedData = data;
      console.log('droppedData', data);
    })
  }

  override onSubmit(form: NgForm, refund = false) {
    this.displayProgressSpinner(true);

    let values = form.value;

    let params = [
      { parameter: 'enrollment_id', value: this._injectedDialogData['data']['EntityID'] },
      { parameter: 'drop_date', value: values['drop_date'].format("YYYY/MM/DD") },
    ];

    // Drop the enrollment.
    this._drupalRESTService.httpGET('/api_rest/v1/dropEnrollment', params)
    .subscribe(data => {
      if (refund) {
      } else {
        this.displayProgressSpinner(false);
        console.log('data', data)
      }

      if (data['success']) {
        this.closeDialog();
      }
    },
      error => {
        this.errorMessage = "There was a problem dropping this enrollment.";
        this.handleError(error)
        this.displayProgressSpinner(false);
      }
    );

    if (refund) {
      let paymentID;
      // let paymentSaleDate = moment();
      let paymentSaleDate = moment(values['drop_date']);
      let refundForm;

      // Generate the refund for enrollments drops.
      this.getUniquePaymentID()
      .subscribe(data => {
        paymentID = data;

        refundForm =
        {
          "title": 'Default Refund',
          "field_payment_id": paymentID,
          "field_date_and_time": paymentSaleDate.format("YYYY-MM-DD[T]HH:mm:ss"),
          "field_gross_tuition": -(Math.abs(this.estimateRefund())),
          "field_student_name": this.enrollmentEntityData.field_student.id,
          "field_scheduled_payment": {
            "target_id": this.enrollmentEntityData.field_scheduled_payments.id
          },
          "field_payment_type": "71",
          // "cc_number": "",
          // "cc_expiration": "",
          // "cc_postal_code": null,
          "field_enrollment_name": {
            "target_id": this.enrollmentEntityData.id
          }
        };

        this._entityRESTService.createEntity('payments', 'payment', refundForm).subscribe(data => {
          console.log('data', data);

          this.displayProgressSpinner(false);
          this.closeDialog();
        },
          error => this.handleError(error)
        )
      });
    }
  }

}
