<div class="terminal-devices-section mt-4">
  <h2>Terminal Devices</h2>
  <button class="btn btn-primary mb-3" (click)="loadStripeDevices()">
    <i class="fas fa-sync-alt"></i> Refresh Devices
  </button>

  <div *ngIf="!isSquareAuthorized" class="alert alert-warning">
    You are not connected to Square. Please authorize above.
  </div>

  <mat-progress-spinner *ngIf="showProgressSpinner"
    mode="indeterminate"
    diameter="30">
  </mat-progress-spinner>

  <div *ngIf="errorMessage" class="alert alert-danger">
    {{ errorMessage }}
  </div>

  <ng-container *ngIf="isSquareAuthorized">
    <!-- Active Terminals Section -->
    <div class="terminal-group mt-3">
      <h3 class="mb-3">Active Terminals</h3>
      <div class="row">
        <div *ngFor="let device of activeDevices" class="col-md-4 col-sm-6 mb-4">
          <div class="device-tile" [ngClass]="{'selected': selectedDeviceId === device.id}" (click)="selectDevice(device.id)">
            <span class="tile-label">{{ device.attributes.name }}</span>
            <span class="tile-subtext">{{ device.attributes.model }} - {{ device.attributes.version }}</span>
            <div class="device-details">
              <p>Battery: {{ device.battery }}%</p>
              <p *ngIf="device.wifi">
                {{ device.wifi.ssid }}
                <mat-icon [ngClass]="'wifi-strength-' + device.wifi.signal_strength.value">
                  {{ device.wifi.signal_strength.value === 0 ? 'wifi_off' : 'wifi' }}
                </mat-icon>
              </p>
              <p *ngIf="!device.wifi">Ethernet Connected</p>
            </div>
            <span class="device-status">{{ device.status.category }}</span>
          </div>
        </div>
        <div *ngIf="activeDevices.length === 0" class="col-12">
          <p class="text-muted">No active terminals found.</p>
        </div>
      </div>
    </div>

    <!-- Inactive Terminals Section -->
    <div class="terminal-group mt-4">
      <h3 class="mb-3">Inactive Terminals</h3>
      <div class="row">
        <div *ngFor="let device of inactiveDevices" class="col-md-4 col-sm-6 mb-4">
          <div class="device-tile" [ngClass]="{'selected': selectedDeviceId === device.id}" (click)="selectDevice(device.id)">
            <span class="tile-label">{{ device.attributes.name }}</span>
            <span class="tile-subtext">{{ device.attributes.model }} - {{ device.attributes.version }}</span>
            <div class="device-details">
              <p>Battery: {{ device.battery }}%</p>
              <p *ngIf="device.wifi">
                {{ device.wifi.ssid }}
                <mat-icon [ngClass]="'wifi-strength-' + device.wifi.signal_strength.value">
                  {{ device.wifi.signal_strength.value === 0 ? 'wifi_off' : 'wifi' }}
                </mat-icon>
              </p>
              <p *ngIf="!device.wifi">Ethernet Connected</p>
            </div>
            <span class="device-status">{{ device.status.category }}</span>
          </div>
        </div>
        <div *ngIf="inactiveDevices.length === 0" class="col-12">
          <p class="text-muted">No inactive terminals found.</p>
        </div>
      </div>
    </div>
  </ng-container>
</div>
