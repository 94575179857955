import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ViewTableComponent } from '../../base/view-table/view-table.component';
import { PackageEntityComponent } from '../../forms/package-entity/package-entity.component';
import { PageEvent } from '@angular/material/paginator';
import { HttpParameter } from 'src/app/types/http-parameter';

@Component({
  selector: 'app-package-list',
  templateUrl: './package-list.component.html',
  styleUrls: ['./package-list.component.css']
})
export class PackageListComponent extends ViewTableComponent implements OnInit {

  // Other component refs.
  PackageEntityComponent = PackageEntityComponent

  override displayedColumns: string[] = [
    'id',
    'field_abbreviation',
    'field_lesson_count_value_1',
    'field_lesson_price_value',
    'field_lesson_type',
    'field_package_name',
    'field_sps_code',
    'field_total_price_value',
    'field_custom_text',
  ];

  activeLink = 'Active Packages';
  links = [
    {linkTitle: 'Active Packages', linkRoute: '/packages-list' },
    {linkTitle: 'Retired Packages', linkRoute: '/packages-list-retired' }
  ]

  override ngOnInit() {
    // Initialize pagination values
    this.paginationIndex = 0;
    this.pageSize = 10;  // Set default page size

    // Call parent ngOnInit if it exists
    super.ngOnInit();

    // Initial data load with pagination parameters
    this.getData([
      { parameter: 'page', value: this.paginationIndex.toString() },
      { parameter: 'items_per_page', value: this.pageSize.toString() }
    ]);
  }

  navLinktest(link) {
    this._router.navigate(['auth/login']);
    // this._router.navigate(['packages-list-retired'])
  }

  openPackageEntityComponent(action: string, EntityID?) {
    this._dialogService.openDialog(PackageEntityComponent, "defaultWithData", {
      data: {
        EntityID: EntityID,
        action: action,
      },
    }).afterClosed().subscribe(data => {
      this.getData(this.queryParams);
    });
  }

  override getData(params: { parameter: string; value: string; }[]) {
    // Show Progress Bar (Loading indicator)
    this.ShowProgressBar = true;

    // Store only non-pagination params in queryParams
    this.queryParams = params.filter(param =>
      param.parameter !== 'page' && param.parameter !== 'items_per_page'
    );

    // Add current pagination state to params if not present
    let finalParams = [...params];
    if (!params.some(p => p.parameter === 'page')) {
      finalParams.push(
        { parameter: 'page', value: this.paginationIndex.toString() },
        { parameter: 'items_per_page', value: this.pageSize.toString() }
      );
    }

    // Add sorting parameters if available
    if (this.sortParams !== undefined) {
      finalParams.push(this.sortParams);
    }

    // Add retired parameters.
    finalParams = [
      ...finalParams,
      { parameter: "_format", value: "json" },
      // Add retired parameters.
      { parameter: "field_retired_op", value: "=" },
      { parameter: "field_retired_value", value: "0" }
    ]

    // Load the new data with the filtering parameters.
    this._utilityService.getPackagesListView(
      finalParams
    )
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;

        this.data = data;
        this.dataSource = new MatTableDataSource(data['results']);

        // Pagination
        if (data['results']) {
          this.setupPagination(data, params);
        }
      },
        error => this.handleError(error)
      )
  }

  override resetForm() {
    // Clear all stored params
    this.queryParams = [];
    this.sortParams = undefined;

    // Reset pagination
    if (this.paginator) {
      this.paginator.pageIndex = 0;
      this.paginationIndex = 0;  // Reset the pagination index
      this.pageSize = 10;  // Reset page size
    }

    // Clear the form
    this.form.resetForm();

    // Make a fresh request with explicitly empty params
    this.getData([
      { parameter: 'page', value: '0' },
      { parameter: 'items_per_page', value: '10' }
    ]);

    // Update router last
    this.updateRouterParams({});
  }

  override pageChanged(event: PageEvent) {
    // Update pagination state
    this.paginationIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    // Always include pagination parameters
    const paginationParams: HttpParameter[] = [
      { parameter: 'page', value: this.paginationIndex.toString() },
      { parameter: 'items_per_page', value: this.pageSize.toString() }
    ];

    // Filter out any existing pagination params from queryParams
    const filteredQueryParams = this.queryParams.filter(param =>
      param.parameter !== 'page' && param.parameter !== 'items_per_page'
    );

    // Combine filtered query params with new pagination params
    const combinedParams = [...filteredQueryParams, ...paginationParams];
    this.getData(combinedParams);
  }

  override onPageSizeOptions() {
    return [10, 25, 50];
  }
}
