export const timeZones = [
  { value: "Africa/Abidjan", display: "Greenwich Mean Time (Abidjan) (UTC+0)" },
  { value: "Africa/Accra", display: "Greenwich Mean Time (Accra) (UTC+0)" },
  { value: "Africa/Addis_Ababa", display: "Eastern Africa Time (Addis Ababa) (UTC+3)" },
  { value: "Africa/Algiers", display: "Central European Time (Algiers) (UTC+1)" },
  { value: "Africa/Cairo", display: "Eastern European Time (Cairo) (UTC+2)" },
  { value: "Africa/Casablanca", display: "Western European Time (Casablanca) (UTC+1)" },
  { value: "Africa/Johannesburg", display: "South Africa Standard Time (Johannesburg) (UTC+2)" },
  { value: "Africa/Lagos", display: "West Africa Time (Lagos) (UTC+1)" },
  { value: "Africa/Nairobi", display: "East Africa Time (Nairobi) (UTC+3)" },
  { value: "Africa/Tunis", display: "Central European Time (Tunis) (UTC+1)" },

  { value: "America/Anchorage", display: "Alaska Time (Anchorage) (UTC-9)" },
  { value: "America/Argentina/Buenos_Aires", display: "Argentina Time (Buenos Aires) (UTC-3)" },
  { value: "America/Bogota", display: "Colombia Time (Bogota) (UTC-5)" },
  { value: "America/Chicago", display: "Central Time (Chicago) (UTC-6)" },
  { value: "America/Denver", display: "Mountain Time (Denver) (UTC-7)" },
  { value: "America/Edmonton", display: "Mountain Time (Edmonton) (UTC-7)" },
  { value: "America/Halifax", display: "Atlantic Time (Halifax) (UTC-4)" },
  { value: "America/Los_Angeles", display: "Pacific Time (Los Angeles) (UTC-8)" },
  { value: "America/Mexico_City", display: "Central Time (Mexico City) (UTC-6)" },
  { value: "America/Montevideo", display: "Uruguay Time (Montevideo) (UTC-3)" },
  { value: "America/New_York", display: "Eastern Time (New York) (UTC-5)" },
  { value: "America/Phoenix", display: "Mountain Standard Time (Phoenix) (UTC-7)" },
  { value: "America/Santiago", display: "Chile Time (Santiago) (UTC-4)" },
  { value: "America/Sao_Paulo", display: "Brasilia Time (São Paulo) (UTC-3)" },
  { value: "America/St_Johns", display: "Newfoundland Time (St. John's) (UTC-3:30)" },
  { value: "America/Toronto", display: "Eastern Time (Toronto) (UTC-5)" },
  { value: "America/Vancouver", display: "Pacific Time (Vancouver) (UTC-8)" },

  { value: "Asia/Bangkok", display: "Indochina Time (Bangkok) (UTC+7)" },
  { value: "Asia/Dubai", display: "Gulf Standard Time (Dubai) (UTC+4)" },
  { value: "Asia/Hong_Kong", display: "Hong Kong Time (UTC+8)" },
  { value: "Asia/Istanbul", display: "Turkey Time (Istanbul) (UTC+3)" },
  { value: "Asia/Jerusalem", display: "Israel Standard Time (Jerusalem) (UTC+2)" },
  { value: "Asia/Kolkata", display: "India Standard Time (Kolkata) (UTC+5:30)" },
  { value: "Asia/Kuwait", display: "Arabia Standard Time (Kuwait) (UTC+3)" },
  { value: "Asia/Manila", display: "Philippine Time (Manila) (UTC+8)" },
  { value: "Asia/Seoul", display: "Korea Standard Time (Seoul) (UTC+9)" },
  { value: "Asia/Shanghai", display: "China Standard Time (Shanghai) (UTC+8)" },
  { value: "Asia/Singapore", display: "Singapore Time (UTC+8)" },
  { value: "Asia/Tokyo", display: "Japan Standard Time (Tokyo) (UTC+9)" },

  { value: "Australia/Adelaide", display: "Australian Central Time (Adelaide) (UTC+9:30)" },
  { value: "Australia/Brisbane", display: "Australian Eastern Standard Time (Brisbane) (UTC+10)" },
  { value: "Australia/Melbourne", display: "Australian Eastern Time (Melbourne) (UTC+10)" },
  { value: "Australia/Perth", display: "Australian Western Time (Perth) (UTC+8)" },
  { value: "Australia/Sydney", display: "Australian Eastern Time (Sydney) (UTC+10)" },

  { value: "Europe/Amsterdam", display: "Central European Time (Amsterdam) (UTC+1)" },
  { value: "Europe/Athens", display: "Eastern European Time (Athens) (UTC+2)" },
  { value: "Europe/Berlin", display: "Central European Time (Berlin) (UTC+1)" },
  { value: "Europe/Brussels", display: "Central European Time (Brussels) (UTC+1)" },
  { value: "Europe/Dublin", display: "Irish Standard Time (Dublin) (UTC+0)" },
  { value: "Europe/Helsinki", display: "Eastern European Time (Helsinki) (UTC+2)" },
  { value: "Europe/Lisbon", display: "Western European Time (Lisbon) (UTC+0)" },
  { value: "Europe/London", display: "British Time (London) (UTC+0)" },
  { value: "Europe/Madrid", display: "Central European Time (Madrid) (UTC+1)" },
  { value: "Europe/Moscow", display: "Moscow Standard Time (UTC+3)" },
  { value: "Europe/Oslo", display: "Central European Time (Oslo) (UTC+1)" },
  { value: "Europe/Paris", display: "Central European Time (Paris) (UTC+1)" },
  { value: "Europe/Prague", display: "Central European Time (Prague) (UTC+1)" },
  { value: "Europe/Rome", display: "Central European Time (Rome) (UTC+1)" },
  { value: "Europe/Stockholm", display: "Central European Time (Stockholm) (UTC+1)" },
  { value: "Europe/Vienna", display: "Central European Time (Vienna) (UTC+1)" },
  { value: "Europe/Warsaw", display: "Central European Time (Warsaw) (UTC+1)" },
  { value: "Europe/Zurich", display: "Central European Time (Zurich) (UTC+1)" },

  { value: "Pacific/Auckland", display: "New Zealand Time (Auckland) (UTC+12)" },
  { value: "Pacific/Fiji", display: "Fiji Time (UTC+12)" },
  { value: "Pacific/Honolulu", display: "Hawaii Time (Honolulu) (UTC-10)" },
  { value: "Pacific/Midway", display: "Samoa Standard Time (Midway) (UTC-11)" },
  { value: "Pacific/Tahiti", display: "Tahiti Time (UTC-10)" }
];

// Mapping of country codes to their associated timezone values
export const countryTimezones: { [countryCode: string]: string[] } = {
  // United States
  'US': [
    'America/New_York',    // Eastern Time
    'America/Chicago',     // Central Time
    'America/Denver',      // Mountain Time
    'America/Phoenix',     // Mountain Standard Time (no DST)
    'America/Los_Angeles', // Pacific Time
    'America/Anchorage',   // Alaska Time
    'Pacific/Honolulu'     // Hawaii Time
  ],

  // Canada
  'CA': [
    'America/St_Johns',    // Newfoundland Time
    'America/Halifax',     // Atlantic Time
    'America/Toronto',     // Eastern Time
    'America/Winnipeg',    // Central Time
    'America/Edmonton',    // Mountain Time
    'America/Vancouver'    // Pacific Time
  ],

  // United Kingdom
  'GB': [
    'Europe/London'        // British Time
  ],

  // Australia
  'AU': [
    'Australia/Sydney',    // Eastern Time
    'Australia/Melbourne', // Eastern Time
    'Australia/Brisbane',  // Eastern Standard Time (no DST)
    'Australia/Adelaide',  // Central Time
    'Australia/Perth'      // Western Time
  ],

  // Mexico
  'MX': [
    'America/Mexico_City', // Central Time
    'America/Chihuahua',   // Mountain Time
    'America/Tijuana'      // Pacific Time
  ],

  // Italy
  'IT': [
    'Europe/Rome'          // Central European Time
  ],

  // France
  'FR': [
    'Europe/Paris'         // Central European Time
  ],

  // Germany
  'DE': [
    'Europe/Berlin'        // Central European Time
  ],

  // Spain
  'ES': [
    'Europe/Madrid'        // Central European Time
  ],

  // China
  'CN': [
    'Asia/Shanghai'        // China Standard Time
  ],

  // Japan
  'JP': [
    'Asia/Tokyo'           // Japan Standard Time
  ],

  // India
  'IN': [
    'Asia/Kolkata'         // India Standard Time
  ],

  // Brazil
  'BR': [
    'America/Sao_Paulo',   // Brasilia Time
    'America/Manaus',      // Amazon Time
    'America/Rio_Branco'   // Acre Time
  ],

  // Russia (partial list - Russia has many timezones)
  'RU': [
    'Europe/Moscow',       // Moscow Time
    'Europe/Kaliningrad',  // Kaliningrad Time
    'Asia/Yekaterinburg',  // Yekaterinburg Time
    'Asia/Novosibirsk',    // Novosibirsk Time
    'Asia/Irkutsk',        // Irkutsk Time
    'Asia/Vladivostok'     // Vladivostok Time
  ]
};

export const getTimeZoneDisplay = (value: string): string => {
  const timezone = timeZones.find(tz => tz.value === value);
  return timezone ? timezone.display : value;
};

// Function to filter timezones by country
export const getTimezonesByCountry = (countryCode: string): any[] => {
  if (!countryCode || !countryTimezones[countryCode]) {
    return timeZones; // Return all timezones if no country selected or country not in mapping
  }

  // Filter timezones based on the country's timezone values
  return timeZones.filter(tz => countryTimezones[countryCode].includes(tz.value));
};
