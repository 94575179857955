import { CommonModule } from "@angular/common";
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UtilityService } from 'src/app/services/utility.service';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { NgForm } from "@angular/forms";
import { DrupalRESTService } from "src/app/services/drupal-rest.service";
import { Router } from "@angular/router";
import { saveAs } from 'file-saver';
import moment from "moment";
import { ViewTableComponent } from "../../base/view-table/view-table.component";
import { StudentDashboardWrapperComponent } from "../student-dashboard-wrapper/student-dashboard-wrapper.component";
import { ComponentType } from "@angular/cdk/overlay";
import { ConfirmDialogComponent } from "../../utility/confirm-dialog/confirm-dialog.component";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: 'app-students-list',
  templateUrl: './students-list.component.html',
  styleUrls: [
    './students-list.component.css',
    '../../../app.component.css'
  ]
})

export class StudentsListComponent extends ViewTableComponent implements OnInit {

  currentPage: number;
  StudentDashboardWrapperComponent = StudentDashboardWrapperComponent;
  isArchived: boolean = false;
  activeLink: string = 'Active Students';
  links = [
    {linkTitle: 'Active Students', value: false},
    {linkTitle: 'Retired Students', value: true}
  ];
  lastChecked: string = '';

  override ngOnInit() {
    super.ngOnInit();
    this.getData(this.queryParams);
  }

  override displayedColumns: string[] = [
    'field_last_name_value',
    'field_first_name_value',
    'field_student_department_target_id',
    // 'field_last_lesson_date_day_value',
    'field_last_lesson_taken_value',
    'field_upcoming_lesson_value',
    'field_total_lessons_enrolled_value',
    'field_total_lessons_taken_value',
    'field_total_lessons_remaining_value',
    'field_total_lessons_paid_ahead_value',
    'instructor',
    'field_email_value',
    'field_cell_phone_value',
    'dashboard',
    'edit'
  ];

  isExporting: boolean = false;

  override getData(params: { parameter: string; value: string; }[]) {
    this.ShowProgressBar = true;
    this.queryParams = params;

    // Choose the endpoint based on isArchived flag
    const baseEndpoint = this.isArchived ? "/api/v1/student-list-retired" : "/api/v1/students";

    // Only add field_archive to the filter if we're using the students endpoint
    let filters = this.isArchived
      ? '?'
      : `?field_archive=${this.isArchived ? 1 : 0}`;

    filters += "&sort_order=ASC&sort_by=field_last_name_value";

    if (params) {
      let hasActiveFilter = false;
      let hasInactiveFilter = false;
      let hasNFAFilter = false;
      let hasPaidFinishedFilter = false;

      // First pass - identify which filters are present
      for (const property in params) {
        if (params[property]?.['parameter'] === 'Active') hasActiveFilter = true;
        if (params[property]?.['parameter'] === 'Inactive') hasInactiveFilter = true;
        if (params[property]?.['parameter'] === 'NFA') hasNFAFilter = true;
        if (params[property]?.['parameter'] === 'paid_finished') hasPaidFinishedFilter = true;
      }

      // Second pass - apply filters with logic to handle conflicts
      // Handle Active/Inactive filters (mutually exclusive)
      if (hasActiveFilter && !hasInactiveFilter) {
        filters += "&field_last_lesson_taken_value=" + moment().subtract(30, 'days').format('YYYY-MM-DD') + "--";
      } else if (!hasActiveFilter && hasInactiveFilter) {
        filters += "&field_last_lesson_taken_value=" + "--" + moment().subtract(30, 'days').format('YYYY-MM-DD');
      }

      // Handle NFA filter
      if (hasNFAFilter) {
        filters += "&field_nfa=1";
        filters += "&field_total_lessons_remaining_value[value]=0";
        filters += "&field_total_lessons_remaining_value[min]=";
        filters += "&field_total_lessons_remaining_value[max]=";
        filters += "&field_total_lessons_remaining_value_op=>";
      }

      // Handle paid_finished filter (only if NFA is not active to avoid conflicts)
      if (hasPaidFinishedFilter && !hasNFAFilter) {
        filters += "&field_paid_finished=true";
        filters += "&field_total_lessons_paid_ahead_value=0";
        filters += "&field_total_lessons_remaining_value[value]=0";
        filters += "&field_total_lessons_remaining_value[min]=";
        filters += "&field_total_lessons_remaining_value[max]=";
        filters += "&field_total_lessons_remaining_value_op==";
      }

      // Process other parameters that aren't special checkbox filters
      for (const property in params) {
        const param = params[property];
        if (param &&
            param.parameter !== 'Active' &&
            param.parameter !== 'Inactive' &&
            param.parameter !== 'NFA' &&
            param.parameter !== 'paid_finished' &&
            param.value) {
          filters += `&${param.parameter}=${encodeURIComponent(param.value)}`;
        }
      }
    }

    const endpoint = baseEndpoint + filters;

    this._drupalRESTService.httpGET(endpoint, params)
      .subscribe({
        next: (data) => {
          this.ShowProgressBar = false;
          this.dataSource = new MatTableDataSource(data['results']);
          if (data['results']) {
            this.setupPagination(data, params);
          }
        },
        error: error => this.handleError(error)
      });
  }

  setStudentArchive(student_account_id: number, value: boolean) {
    let body = {
      field_archive: value ? 1 : 0,
    };
    this._entityRESTService.patchEntity('student_accounts', 'student_account', student_account_id, body)
      .subscribe({
        next: () => {
          this.getData(this.queryParams);
          const message = value ? 'Student archived successfully' : 'Student unarchived successfully';
          this._snackBar.open(message, 'Close', { duration: 3000 });
        },
        error: (error) => {
          console.error('Error updating student archive status:', error);
          this._snackBar.open('Error updating student status', 'Close', { duration: 3000 });
        }
      });
  }

  deleteStudent(student_account_id: number) {
    const dialogRef = this._dialogService.open(
      ConfirmDialogComponent,
      this._dialogService.dialogConfig('confirm', {
        title: 'Delete Student',
        message: 'Are you sure you want to delete this student? This action cannot be undone.',
        confirmText: 'Delete',
        cancelText: 'Cancel'
      })
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._drupalRESTService.httpDELETE(`/api_entity/eck/student_accounts/student_account/${student_account_id}`)
          .subscribe({
            next: () => {
              this.getData(this.queryParams);
              this._snackBar.open('Student deleted successfully', 'Close', {
                duration: 3000
              });
            },
            error: (error) => {
              this._snackBar.open('Error deleting student', 'Close', {
                duration: 3000
              });
              console.error('Error deleting student:', error);
            }
          });
      }
    });
  }

  prepareExportParams(params: { parameter: string; value: string; }[]): any {
    return params?.reduce((acc, curr) => {
      acc[curr.parameter] = curr.value;
      return acc;
    }, {}) || {};
  }

  exportStudentListReport() {
    this.isExporting = true;
    const exportParams = this.prepareExportParams(this.queryParams);

    const paramArray = Object.entries(exportParams).map(([parameter, value]) => ({
        parameter,
        value: value.toString()
    }));

    paramArray.push({ parameter: 'field_archive', value: '1' });
    paramArray.push({ parameter: 'sort_order', value: 'ASC' });
    paramArray.push({ parameter: 'sort_by', value: 'field_last_name_value' });

    // Call the download method with the prepared parameters
    try {
        this.downloadCSVDrupalViews('/api/v1/students/export', 'student_list', paramArray);
    } finally {
        this.isExporting = false;
    }
  }

  onTabChange(link: {linkTitle: string, value: boolean}) {
    this.activeLink = link.linkTitle;
    this.isArchived = link.value;
    this.getData(this.queryParams);
  }

  // Override the onSubmit method to handle the "None" selection for instructor
  override onSubmit(form: NgForm): void {
    // Check if instructor field exists and is explicitly set to null or empty string
    const formValues = form.form.value;
    const hasInstructorField = 'instructor' in formValues;

    // Call the parent onSubmit to process most of the form
    super.onSubmit(form);

    // If instructor field exists but was filtered out because it's empty/null,
    // we need to explicitly remove any existing instructor filter
    if (hasInstructorField && (formValues.instructor === null || formValues.instructor === '')) {
      // Find and remove any existing instructor parameter
      this.queryParams = this.queryParams.filter(param => param.parameter !== 'instructor');

      // Update route params too by removing the instructor parameter
      if (this.routeParams && 'instructor' in this.routeParams) {
        const { instructor, ...restParams } = this.routeParams;
        this.routeParams = restParams;

        // Update the router params
        this.updateRouterParams(this.routeParams);
      }
    }

    // Get the data with the updated parameters
    this.getData(this.queryParams);
  }

  onCheckboxChange(form: NgForm) {
    // Get current form values
    const formValues = form.form.value;

    // Determine which checkbox was just checked
    if (formValues.Active) {
      this.lastChecked = 'Active';
    } else if (formValues.Inactive) {
      this.lastChecked = 'Inactive';
    } else if (formValues.NFA) {
      this.lastChecked = 'NFA';
    } else if (formValues.paid_finished) {
      this.lastChecked = 'paid_finished';
    }

    // Handle mutually exclusive checkboxes
    if (formValues.Active && formValues.Inactive) {
      // Either disable one checkbox or prioritize one over the other
      // For example, if Active is newly checked, uncheck Inactive
      if (this.lastChecked === 'Active') {
        form.form.patchValue({ Inactive: false });
      } else {
        form.form.patchValue({ Active: false });
      }
    }

    // Handle conflicts between NFA and paid_finished
    if (formValues.NFA && formValues.paid_finished) {
      if (this.lastChecked === 'NFA') {
        form.form.patchValue({ paid_finished: false });
      } else {
        form.form.patchValue({ NFA: false });
      }
    }

    // Now submit the form with the corrected values
    this.onSubmit(form);
  }
}
