<div class="container-fluid">
  <h1 class="page-header">Staff Tracking Report</h1>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Filter</mat-panel-title>
      <mat-panel-description>
        <app-filter-results-panel [parameters]="queryParams"></app-filter-results-panel>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <form (ngSubmit)="onSubmit(f)" #f="ngForm" class="form">
      <div class="row">
        <div class="col-3">
          <mat-form-field class="full-width-field">
            <mat-label>Week</mat-label>
            <mat-select matInput [ngModel]="week" name="week" (selectionChange)="onSubmit(f)">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let element of WeeksForYearData" [value]="element.key">
                {{ element.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-3">
          <mat-form-field class="full-width-field">
            <mat-label>Year</mat-label>
            <mat-select matInput [ngModel]="year" name="year" (selectionChange)="onYearChange($event); onSubmit(f)">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let element of LastFourYearsData" [value]="element.key">
                {{ element.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <section class="section">
          <div class="float-end bring-inline">
            <button (click)="resetForm()" color="primary" mat-flat-button class="me-1">
              Clear
            </button>
          </div>
        </section>
      </div>
    </form>
  </mat-expansion-panel>

  <mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>

  <div class="row mt-3">
    <div class="col-md-12">
      <div class="float-end">
        <button mat-raised-button color="primary" class="me-2" (click)="printReport()">
          Print
        </button>
        <button mat-raised-button color="primary" class="me-2" (click)="exportReport()">
          Export
        </button>
        <button mat-raised-button color="primary" (click)="refreshData()">
          Refresh
        </button>
      </div>
    </div>
  </div>

  <!-- Staff Tables -->
  <div class="row">
    <div class="col-md-4 col-sm-6 col-xs-12 mt-4" *ngFor="let staff of staffData">
      <h3>{{staff.name}} <!--({{staff.job_title}})--></h3>

      <table mat-table [dataSource]="getStaffData(staff)" class="w-100">
        <!-- Period Column -->
        <ng-container matColumnDef="period">
          <th mat-header-cell *matHeaderCellDef>Period</th>
          <td mat-cell *matCellDef="let element">{{element.period}}</td>
        </ng-container>

        <!-- Lessons Taught Column -->
        <ng-container matColumnDef="lessons_taught">
          <th mat-header-cell *matHeaderCellDef>Lessons Taught</th>
          <td mat-cell *matCellDef="let element">{{element.lessons_taught || ''}}</td>
        </ng-container>

        <!-- Lesson Sales Column -->
        <ng-container matColumnDef="lesson_sales">
          <th mat-header-cell *matHeaderCellDef>Lesson Sales</th>
          <td mat-cell *matCellDef="let element">{{ (element.lesson_sales ? '$' + element.lesson_sales : '') || ''}}</td>
        </ng-container>

        <!-- Misc Sales Column -->
        <ng-container matColumnDef="misc_sales">
          <th mat-header-cell *matHeaderCellDef>Misc Sales</th>
          <td mat-cell *matCellDef="let element">{{ (element.misc_sales ? '$' + element.misc_sales : '') || ''}}</td>
        </ng-container>

        <!-- Cash Column -->
        <ng-container matColumnDef="cash">
          <th mat-header-cell *matHeaderCellDef>Cash</th>
          <td mat-cell *matCellDef="let element">{{ (element.cash ? '$' + element.cash : '') || ''}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
