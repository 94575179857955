import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(
    public _authService: AuthService
  ) { }

  role_based_access_control_list = {
    administrator: {
      view_admin_links: true,
      manage_franchisee_role: true,
      manage_quick_status_edit: true
    },
    franchisee: {
      manage_franchisee_role: true,
      manage_quick_status_edit: true
    },
    authenticated: {
      "example": true
    }
  }

  get_user_roles() {
    return this._authService.current_user?.['roles'];
  }

  // TODO: use a more efficient data structure.
  has_access(permission) {
    for (const role in this.get_user_roles()) {
      if (this.role_based_access_control_list?.[this.get_user_roles()[role]]?.[permission]) {
        return true;
      }
    }
    return false;
  }
}
