import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { ViewTableComponent } from '../../base/view-table/view-table.component';

@Component({
  selector: 'app-lessons-list-report',
  templateUrl: './lessons-list-report.component.html',
  styleUrls: ['./lessons-list-report.component.css']
})
export class LessonsListReportComponent extends ViewTableComponent implements OnInit {
  enrollmentFilter: boolean | null = null;
  override displayedColumns: string[] = [
    'id',
    // 'title',
    'field_status',
    'nothing_1',
    // 'field_instructor',
    'view_user',
    'views_conditional_field',
    'title_1',
    'field_email',
    'field_date_and_time_value',
    'views_conditional_field_1',
    'field_date_and_time_1',
    'field_expiration_date_1',
    'field_duration',
    'created',
    'field_type',
    // 'field_repetition_frequency',
    // 'field_type_1',
    // 'field_category',
    // 'edit_events',
    // 'views_conditional_field_2',
    'event_actions',
  ];

  override ngOnInit() {
    // Initialize pagination values
    this.paginationIndex = 0;
    this.pageSize = 10;  // Set default page size

    // Call parent ngOnInit if it exists
    super.ngOnInit();

    // Initial data load with pagination parameters and default parameters
    this.getData([
      { parameter: 'page', value: this.paginationIndex.toString() },
      { parameter: 'items_per_page', value: this.pageSize.toString() },
      { parameter: 'type_event_entity_type', value: 'All' },
      { parameter: 'instructor', value: '' }
    ]);
  }

  exportLessonListReport() {
    let exportParams = this.prepareExportParams(this.queryParams);
    console.log('Export params:', exportParams); // For debugging
    this.downloadCSVDrupalViews('/api/export/appointment-list', 'appointment_list', exportParams);
  }

  private prepareExportParams(params: { parameter: string; value: string; }[]): { parameter: string; value: string; }[] {
    let exportParams = [...params]; // Create a copy of the params

    // Ensure type_event_entity_type is set to 'All'
    this.addOrUpdateParam(exportParams, 'type_event_entity_type', 'All');

    // Ensure instructor is set to an empty string if not already set
    if (!exportParams.find(p => p.parameter === 'instructor')) {
      this.addOrUpdateParam(exportParams, 'instructor', '');
    }

    // Handle enrollment filter
    const enrollmentParamIndex = exportParams.findIndex(p => p.parameter === 'id_op_enrollment');
    if (this.enrollmentFilter === true) {
      this.updateQueryParam(enrollmentParamIndex, 'empty', exportParams);
    } else if (this.enrollmentFilter === false) {
      this.updateQueryParam(enrollmentParamIndex, 'not+empty', exportParams);
    } else {
      // Remove the parameter for the undefined state
      if (enrollmentParamIndex !== -1) {
        exportParams.splice(enrollmentParamIndex, 1);
      }
    }

    return exportParams;
  }

  private addOrUpdateParam(params: { parameter: string; value: string; }[], parameter: string, value: string) {
    const index = params.findIndex(p => p.parameter === parameter);
    if (index !== -1) {
      params[index].value = value;
    } else {
      params.push({ parameter, value });
    }
  }

  private updateQueryParam(index: number, value: string, params: { parameter: string; value: string; }[]) {
    const param = { parameter: 'id_op_enrollment', value: value };
    if (index === -1) {
      params.push(param);
    } else {
      params[index] = param;
    }
  }

  override getData(params: { parameter: string; value: string; }[]) {
    this.ShowProgressBar = true;

    // Store only non-pagination params in queryParams
    this.queryParams = params.filter(param =>
      param.parameter !== 'page' && param.parameter !== 'items_per_page'
    );

    // Add current pagination state to params if not present
    let finalParams = [...params];
    if (!params.some(p => p.parameter === 'page')) {
      finalParams.push(
        { parameter: 'page', value: this.paginationIndex.toString() },
        { parameter: 'items_per_page', value: this.pageSize.toString() }
      );
    }

    // Ensure default parameters are always included
    let hasInstructor = finalParams.some(p => p.parameter === 'instructor');
    let hasTypeEventEntityType = finalParams.some(p => p.parameter === 'type_event_entity_type');

    if (!hasInstructor) {
      finalParams.push({ parameter: 'instructor', value: '' });
    }

    if (!hasTypeEventEntityType) {
      finalParams.push({ parameter: 'type_event_entity_type', value: 'All' });
    }

    // Also ensure these default parameters are in queryParams for future reference
    let instructorInQueryParams = this.queryParams.some(p => p.parameter === 'instructor');
    let typeEventEntityTypeInQueryParams = this.queryParams.some(p => p.parameter === 'type_event_entity_type');

    if (!instructorInQueryParams) {
      this.queryParams.push({ parameter: 'instructor', value: '' });
    }

    if (!typeEventEntityTypeInQueryParams) {
      this.queryParams.push({ parameter: 'type_event_entity_type', value: 'All' });
    }

    // Update the id_op_enrollment parameter based on enrollmentFilter state
    const enrollmentParamIndex = this.queryParams.findIndex(p => p.parameter === 'id_op_enrollment');
    if (this.enrollmentFilter === true) {
      this.updateQueryParam(enrollmentParamIndex, 'empty', this.queryParams);
    } else if (this.enrollmentFilter === false) {
      this.updateQueryParam(enrollmentParamIndex, 'not+empty', this.queryParams);
    } else {
      // Remove the parameter for the undefined state
      if (enrollmentParamIndex !== -1) {
        this.queryParams.splice(enrollmentParamIndex, 1);
      }
    }

    // Add sorting parameters if available
    if (this.sortParams !== undefined) {
      finalParams.push(this.sortParams);
    }

    // Load the new data with the filtering parameters.
    this._utilityService.getLessonListReport(
      finalParams
    )
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;

        this.data = data;
        this.dataSource = new MatTableDataSource(data['results']);

        // Pagination
        if (data['results']) {
          this.setupPagination(data, params);
        }

        // Post process the data.
        this.data['results'].forEach(element => {
          if (element?.field_date_and_time && element?.field_duration) {
            // Calculate the start time + duration to get the end time of the event.
            element.field_expiration_date_1 = moment(element.field_date_and_time).add(moment.duration(element.field_duration)).format('hh:mm A');
            element.views_conditional_field_1 = moment(element.field_date_and_time).add(moment.duration(element.field_duration)).format('M/D/Y hh:mm A')
            // Pretty the date and time field.
            element.field_date_and_time = moment(element.field_date_and_time).format('M/D/Y hh:mm A')
          }
        });
      },
      error => this.handleError(error)
    );
  }

  onEnrollmentFilterChange() {
    // Update the enrollment filter state
    if (this.enrollmentFilter === null) {
      this.enrollmentFilter = true;
    } else if (this.enrollmentFilter === true) {
      this.enrollmentFilter = false;
    } else {
      this.enrollmentFilter = null;
    }

    // Reset pagination when filter changes
    this.paginationIndex = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }

    // Get data with updated filter and reset pagination
    const paginationParams = [
      { parameter: 'page', value: '0' },
      { parameter: 'items_per_page', value: this.pageSize.toString() }
    ];

    // Filter out any existing pagination params
    const filteredQueryParams = this.queryParams.filter(param =>
      param.parameter !== 'page' && param.parameter !== 'items_per_page'
    );

    // Ensure default parameters are included
    let hasInstructor = filteredQueryParams.some(p => p.parameter === 'instructor');
    let hasTypeEventEntityType = filteredQueryParams.some(p => p.parameter === 'type_event_entity_type');

    if (!hasInstructor) {
      filteredQueryParams.push({ parameter: 'instructor', value: '' });
    }

    if (!hasTypeEventEntityType) {
      filteredQueryParams.push({ parameter: 'type_event_entity_type', value: 'All' });
    }

    // Combine and get data
    this.getData([...filteredQueryParams, ...paginationParams]);
  }

  override resetForm() {
    // Clear all stored params
    this.queryParams = [];
    this.sortParams = undefined;

    // Reset enrollment filter
    this.enrollmentFilter = null;

    // Reset pagination
    if (this.paginator) {
      this.paginator.pageIndex = 0;
      this.paginationIndex = 0;  // Reset the pagination index
      this.pageSize = 10;  // Reset page size
    }

    // Clear the form
    this.form.resetForm();

    // Clear any autocomplete options
    this.autoCompleteStudentContactOptions = [];

    // Make a fresh request with explicitly empty params plus the required default params
    this.getData([
      { parameter: 'page', value: '0' },
      { parameter: 'items_per_page', value: '10' },
      { parameter: 'type_event_entity_type', value: 'All' },
      { parameter: 'instructor', value: '' }
    ]);

    // Update router last
    this.updateRouterParams({});
  }

  override pageChanged(event: any) {
    // Update pagination state
    this.paginationIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    // Always include pagination parameters
    const paginationParams = [
      { parameter: 'page', value: this.paginationIndex.toString() },
      { parameter: 'items_per_page', value: this.pageSize.toString() }
    ];

    // Filter out any existing pagination params from queryParams
    const filteredQueryParams = this.queryParams.filter(param =>
      param.parameter !== 'page' && param.parameter !== 'items_per_page'
    );

    // Ensure default parameters are included
    let hasInstructor = filteredQueryParams.some(p => p.parameter === 'instructor');
    let hasTypeEventEntityType = filteredQueryParams.some(p => p.parameter === 'type_event_entity_type');

    if (!hasInstructor) {
      filteredQueryParams.push({ parameter: 'instructor', value: '' });
    }

    if (!hasTypeEventEntityType) {
      filteredQueryParams.push({ parameter: 'type_event_entity_type', value: 'All' });
    }

    // Combine filtered query params with new pagination params
    const combinedParams = [...filteredQueryParams, ...paginationParams];
    this.getData(combinedParams);
  }

  override onPageSizeOptions() {
    return [10, 25, 50, 100, 250];
  }
}
