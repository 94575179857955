import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { ViewTableComponent } from '../../base/view-table/view-table.component';
import { PageEvent } from '@angular/material/paginator';
import { HttpParameter } from 'src/app/types/http-parameter';

@Component({
  selector: 'app-payments-made-encompassing',
  templateUrl: './payments-made-encompassing.component.html',
  styleUrls: ['./payments-made-encompassing.component.css']
})
export class PaymentsMadeEncompassingComponent extends ViewTableComponent implements OnInit {

  currentPage: number;

  override displayedColumns: string[] = [
    'id',
    'field_date_and_time',
    'field_student_name',
    'field_student_department',
    'field_enrollment_package_name_label',
    'field_gross_tuition',
    'field_payment_type_label',
    'field_instructor_label',
    'closer',
  ];

  override ngOnInit() {
    // Initialize pagination values
    this.paginationIndex = 0;
    this.pageSize = 10;  // Set default page size

    // Call parent ngOnInit if it exists
    super.ngOnInit();

    // Initial data load with pagination parameters
    this.getData([
      { parameter: 'page', value: this.paginationIndex.toString() },
      { parameter: 'items_per_page', value: this.pageSize.toString() }
    ]);
  }

  override getData(params: { parameter: string; value: string; }[]) {
    this.ShowProgressBar = true;

    // Store only non-pagination params in queryParams
    this.queryParams = params.filter(param =>
      param.parameter !== 'page' && param.parameter !== 'items_per_page'
    );

    // Add current pagination state to params if not present
    let finalParams = [...params];
    if (!params.some(p => p.parameter === 'page')) {
      finalParams.push(
        { parameter: 'page', value: this.paginationIndex.toString() },
        { parameter: 'items_per_page', value: this.pageSize.toString() }
      );
    }

    // Add sorting parameters if available
    if (this.sortParams !== undefined) {
      finalParams.push(this.sortParams);
    }

    // Alter start_date and end_date params for Drupal.
    let start_date;
    let end_date;
    let field_next_scheduled_payment;

    if (finalParams) {
      for (const property in finalParams) {
        if (finalParams[property]?.['parameter'] == 'start_date') {
          start_date = moment(finalParams[property]?.['value']).format('YYYY-MM-DD').toString();
        }
        if (finalParams[property]?.['parameter'] == 'end_date') {
          end_date = moment(finalParams[property]?.['value']).format('YYYY-MM-DD').toString();
        }
      }

      field_next_scheduled_payment = {
        parameter: 'field_date_and_time',
        value: start_date + "--" + end_date,
      }
    }

    if (field_next_scheduled_payment && field_next_scheduled_payment?.['value'] != 'undefined--undefined')  {
      finalParams = [
        ...finalParams,
        field_next_scheduled_payment
      ];
    }


    // Handle different checkbox based filters.
    let field_sps_code;
    let sps_codes = '';
    if (finalParams) {
      for (const property in finalParams) {
        // console.log(finalParams[property])
        if (finalParams[property]?.['parameter'] == 'Sundry') {
          sps_codes = sps_codes + "558\+";
        }
        if (finalParams[property]?.['parameter'] == 'Misc') {
          sps_codes = sps_codes + "554\+";
        }
        if (finalParams[property]?.['parameter'] == 'Lesson') {
          sps_codes = sps_codes + "1394\+550\+549\+547\+552";
        }
      }

      field_sps_code = {
        parameter: 'field_sps_code',
        value: sps_codes,
      }
    }

    if (field_sps_code && field_sps_code?.['value'] != '')  {
      finalParams = [
        ...finalParams,
        field_sps_code
      ];
    }

    // Load the new data with the filtering parameters.
    let endpoint: string = "/api/v1/reports/cash";

    this._drupalRESTService.httpGET(endpoint, finalParams)
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;

        this.dataSource = new MatTableDataSource(data['results']);

        // Pagination
        if (data['results']) {
          this.setupPagination(data, params);
        }
      },
        error => this.handleError(error)
      )
  }

  override resetForm() {
    // Clear all stored params
    this.queryParams = [];
    this.sortParams = undefined;

    // Reset pagination
    if (this.paginator) {
      this.paginator.pageIndex = 0;
      this.paginationIndex = 0;  // Reset the pagination index
      this.pageSize = 10;  // Reset page size
    }

    // Clear the form
    this.form.resetForm();

    // Clear any autocomplete options
    this.autoCompleteEnrollmentOptions = [];

    // Make a fresh request with explicitly empty params
    this.getData([
      { parameter: 'page', value: '0' },
      { parameter: 'items_per_page', value: '10' }
    ]);

    // Update router last
    this.updateRouterParams({});
  }

  override pageChanged(event: PageEvent) {
    // Update pagination state
    this.paginationIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    // Always include pagination parameters
    const paginationParams: HttpParameter[] = [
      { parameter: 'page', value: this.paginationIndex.toString() },
      { parameter: 'items_per_page', value: this.pageSize.toString() }
    ];

    // Filter out any existing pagination params from queryParams
    const filteredQueryParams = this.queryParams.filter(param =>
      param.parameter !== 'page' && param.parameter !== 'items_per_page'
    );

    // Combine filtered query params with new pagination params
    const combinedParams = [...filteredQueryParams, ...paginationParams];
    this.getData(combinedParams);
  }

  override onPageSizeOptions() {
    return [10, 25, 50, 100, 250];
  }
}
