import { Component, OnInit } from '@angular/core';

interface Tutorial {
  title: string;
  iframeSrc: string;
}

interface TutorialCategory {
  icon: string;
  name: string;
  tutorials: Tutorial[];
}

@Component({
  selector: 'app-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.css']
})
export class TutorialsComponent implements OnInit {
  categories: TutorialCategory[] = [
    {
      icon: '📋',
      name: 'Getting Started',
      tutorials: [
        {
          title: 'Agenda Master Studio Setup Tutorial: Configuring Studio Information',
          iframeSrc: 'https://app.tango.us/app/embed/8c8e6e99-c757-450e-96e0-ec250132dbe2'
        },
        {
          title: 'Setting Up Sales Tax Configuration in Agenda Master',
          iframeSrc: 'https://app.tango.us/app/embed/1c122db7-8fdb-4525-ae75-525d26c9e686'
        }
      ]
    },
    {
      icon: '👥',
      name: 'Student Management',
      tutorials: [
        {
          title: 'Adding an Inquiry for New Student Accounts',
          iframeSrc: 'https://app.tango.us/app/embed/38646a8f-f087-4ca9-8154-9a04b278d6aa'
        },
        {
          title: 'Creating a Student Appointment (Includes Standing and Alternative)',
          iframeSrc: 'https://app.tango.us/app/embed/bb46f9db-172a-41c2-aa2d-4e967ac6fe7c'
        },
        {
          title: 'Creating an Enrollment (Including Payment and Scheduling Process)',
          iframeSrc: 'https://app.tango.us/app/embed/eb476c91-7deb-45d6-b051-b01b1359a8af'
        },
        {
          title: 'Creating an Enrollment for Splitting a Payment',
          iframeSrc: 'https://app.tango.us/app/embed/16c98385-f969-44d8-bc19-ef6974d9fbe3'
        },
        {
          title: 'Creating and Managing Digital Lesson Blocks in Agenda Master',
          iframeSrc: 'https://app.tango.us/app/embed/bd66e8b1-2a13-44b9-9d50-33c04098977d'
        }
      ]
    },
    {
      icon: '💼',
      name: 'Staff & Administration',
      tutorials: [
        {
          title: 'Adding a New Staff Member in Agenda Master',
          iframeSrc: 'https://app.tango.us/app/embed/0881f9be-b0ab-4326-8bb7-3e4618720629'
        },
        {
          title: 'Submitting Weekly Reports to Arthur Murray International and Online',
          iframeSrc: 'https://app.tango.us/app/embed/859850d4-76ac-4549-8a6f-297fa8d8d851'
        },
        {
          title: 'Uploading an Excel Spreadsheet in Agenda Master',
          iframeSrc: 'https://app.tango.us/app/embed/eed814d4-ecb1-4c63-9788-a1caa32c793b'
        },
        {
          title: 'Creating a Miscellaneous Service Report in Agenda Master',
          iframeSrc: 'https://app.tango.us/app/embed/cf9392db-a973-48f4-ba70-02562d0d4a73'
        }
      ]
    },
    {
      icon: '📅',
      name: 'Appointment Settings',
      tutorials: [
        {
          title: 'Appointment Confirmation in Day View with SMS Reminders',
          iframeSrc: 'https://app.tango.us/app/embed/a02d572c-9c56-4ef8-8e96-c38ef35f18b9'
        },
        {
          title: 'Configuring Appointment Colors in Agenda Master',
          iframeSrc: 'https://app.tango.us/app/embed/5900a482-9bbb-44b5-8665-a5c3038eae34'
        },
        {
          title: 'Configuring Lesson Reminder Emails in Agenda Master',
          iframeSrc: 'https://app.tango.us/app/embed/d68a4019-5bb8-4400-a119-38c0ed979439'
        },
        {
          title: 'Hiding/Managing Events in Agenda Master',
          iframeSrc: 'https://app.tango.us/app/embed/07cf412a-089e-4fec-96e0-d04874d9f2f2'
        }
      ]
    },
    {
      icon: '💰',
      name: 'Packages & Payments',
      tutorials: [
        {
          title: 'Creating a New Package in Agenda Master',
          iframeSrc: 'https://app.tango.us/app/embed/b735b72d-264a-44b2-a90f-effa59a780d5'
        },
        {
          title: 'Configuring Square Integration in Agenda Master',
          iframeSrc: 'https://app.tango.us/app/embed/2067b5f7-6363-42bf-83cf-71aebd73bd3d'
        }
      ]
    }
  ];

  selectedCategory: TutorialCategory;
  selectedTutorial: Tutorial | null = null;

  constructor() {
    this.selectedCategory = this.categories[0];
  }

  selectCategory(category: TutorialCategory): void {
    this.selectedCategory = category;
    this.selectedTutorial = null;
  }

  selectTutorial(tutorial: Tutorial): void {
    this.selectedTutorial = tutorial;
  }

  ngOnInit(): void {
  }
}
