import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Params } from '@angular/router';
import { ViewTableComponent } from 'src/app/components/base/view-table/view-table.component';
import { ViewPaymentsFromEnrollmentComponent } from 'src/app/components/forms/view-payments-from-enrollment/view-payments-from-enrollment.component';
import { EnrollmentEntityComponent } from 'src/app/components/forms/enrollment-entity/enrollment-entity.component';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import moment from 'moment';
import { DialogService } from 'src/app/services/dialog.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-lesson-student-report',
  templateUrl: './lesson-student-report.component.html',
  styleUrls: ['./lesson-student-report.component.css']
})
export class LessonStudentReportComponent extends ViewTableComponent implements OnInit {

  @Input() drupal_studentAccount_id: number;
  @Input() drupal_student_name: string;
  @Input() enrollmentsToLoad: string;

  sendNonUnitPrivateParam = false;
  sendNonUnitGroupParam = false;

  ViewPaymentsFromEnrollmentComponent = ViewPaymentsFromEnrollmentComponent;
  override EnrollmentEntityComponent = EnrollmentEntityComponent;

  override displayedColumns: string[] = [
    'enrollmentID',
    // 'field_contacts',
    'field_sale_date',
    'field_instructor',
    'field_category',
    'field_package_name',
    'field_enrollment_lesson_count',
    'field_enrollment_total_price',
    'field_total_paid',
    'field_balance_due',
    // 'field_additional_notes',
    'field_lesson_available',
    'isDropped',
    // 'nothing',
    'nothing_1',
  ];

  override getData(params: { parameter: string; value: string; }[]) {
    // Show Progress Bar (Loading indicator)
    this.ShowProgressBar = true;
    this.queryParams = params;

    let override_params = [
      { parameter: 'field_category_target_id', value: this.enrollmentsToLoad },
      { parameter: 'id', value: this.drupal_studentAccount_id+'' },
      { parameter: 'items_per_page', value: 'All' },
    ]

    // Conditional Parameter Addition
    if (this.sendNonUnitPrivateParam) {
      override_params.push({ parameter: 'field_lesson_type_target_id', value: '1411'});
      // override_params.push({ parameter: 'field_package_code_target_id', value: '173'});
    }
    // Conditional Parameter Addition
    if (this.sendNonUnitGroupParam) {
      override_params.push({ parameter: 'field_lesson_type_target_id', value: '1410'});
      // override_params.push({ parameter: 'field_package_code_target_id', value: '173'});
    }

    // Add sorting paramaters, if available.
    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams)
    }

    // Load the new data with the filtering parameters.
    this._utilityService.getEnrollmentyView(
      this.queryParams.concat(override_params)
    )
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;

        this.data = data;

        // Process data to add dropped enrollment rows
        const processedData = this.processDroppedEnrollments(data['results']);
        this.dataSource = new MatTableDataSource(processedData);

        // Pagination
        if (data['results']) {
          // this.paginator.length = data['count'];
          // this.paginator.pageSize = this.pageSize;
          // this.paginator.pageIndex = this.paginationIndex;
          this.paginator.length = data['count'];
          this.paginator.pageSize = data['count'];
          this.paginator.pageIndex = 0;
        }
      },
        error => this.handleError(error)
      )
  }

  /**
   * Process dropped enrollments to add adjustment rows
   */
  processDroppedEnrollments(enrollments: any[]): any[] {
    if (!enrollments || enrollments.length === 0) {
      return [];
    }

    const processedData = [...enrollments];
    let insertOffset = 0; // Track how many rows we've inserted

    // Find dropped enrollments and create adjustment rows
    enrollments.forEach((enrollment, index) => {
      if (enrollment.field_dropped_date) {
        // Create a single adjustment row for this dropped enrollment
        const adjustmentRow = { ...enrollment };

        // Mark as an adjustment row
        adjustmentRow.isAdjustmentRow = true;
        adjustmentRow.showTotals = true;

        // Ensure the adjustment row is also marked as a dropped enrollment
        // This will help with styling consistency
        adjustmentRow.field_dropped_date = enrollment.field_dropped_date;

        // Generate a unique ID for the adjustment row
        adjustmentRow.id = `${enrollment.id}-adjustment`;

        const lessonsUsed = enrollment.field_lesson_used ? parseInt(enrollment.field_lesson_used) : 0;
        const totalLessons = parseInt(enrollment.field_enrollment_lesson_count);
        const unusedLessons = totalLessons - lessonsUsed;

        // Calculate the per-lesson price
        const lessonPrice = parseFloat(enrollment.field_enrollment_total_price) / totalLessons;

        // Store original values for display
        adjustmentRow.originalLessonCount = enrollment.field_enrollment_lesson_count;
        adjustmentRow.originalTotalPrice = enrollment.field_enrollment_total_price;
        adjustmentRow.originalTotalPaid = enrollment.field_total_payments_made;
        adjustmentRow.originalBalanceDue = enrollment.field_balance_due;

        // Set the adjustment values (only subtract the unused lessons)
        const adjustmentLessonCount = `-${unusedLessons}`;

        // Calculate the price for unused lessons
        const unusedLessonsPrice = (unusedLessons * lessonPrice).toFixed(2);
        const adjustmentTotalPrice = `-${unusedLessonsPrice}`;

        // Calculate adjustments for payments and balance due
        let adjustmentTotalPaid = "0";
        let adjustmentBalanceDue = "0";

        // Determine if payment was made only for lessons taken or for all lessons
        const isByTheLesson = enrollment.field_payments_structure_type === "By The Lesson";

        // Check if the amount paid corresponds to lessons taken or total lessons
        let paidForLessonsTaken = false;

        if (enrollment.field_total_payments_made) {
          const totalPaid = parseFloat(enrollment.field_total_payments_made);
          const expectedPaymentForUsedLessons = lessonsUsed * lessonPrice;

          // If the payment is close to the expected payment for used lessons (within $1 to account for rounding)
          // or if it's a "By The Lesson" payment structure, assume they paid only for lessons taken
          paidForLessonsTaken = Math.abs(totalPaid - expectedPaymentForUsedLessons) < 1 || isByTheLesson;

          // For cases where they paid only for lessons taken
          if (paidForLessonsTaken) {
            // No adjustment needed for payments
            adjustmentTotalPaid = "0";
          } else {
            // For cases where they paid for all lessons, calculate proportional adjustment
            const paidPerLesson = totalPaid / totalLessons;
            const unusedPaid = (unusedLessons * paidPerLesson).toFixed(2);
            adjustmentTotalPaid = `-${unusedPaid}`;
          }
        }

        if (enrollment.field_balance_due) {
          const balanceDue = parseFloat(enrollment.field_balance_due);

          // If they paid only for lessons taken, the balance due is likely for unused lessons
          if (paidForLessonsTaken) {
            // The balance due should be reduced by the full amount of unused lessons
            const unusedDue = (unusedLessons * lessonPrice).toFixed(2);
            adjustmentBalanceDue = `-${unusedDue}`;
          } else {
            // For other cases, calculate proportional adjustment
            const duePerLesson = balanceDue / totalLessons;
            const unusedDue = (unusedLessons * duePerLesson).toFixed(2);
            adjustmentBalanceDue = `-${unusedDue}`;
          }
        }

        // Calculate final values after adjustment
        const finalLessonCount = lessonsUsed;
        const finalTotalPrice = (parseFloat(enrollment.field_enrollment_total_price) - parseFloat(unusedLessonsPrice)).toFixed(2);

        let finalTotalPaid = enrollment.field_total_payments_made || "0";
        if (!paidForLessonsTaken && enrollment.field_total_payments_made) {
          const totalPaid = parseFloat(enrollment.field_total_payments_made);
          const paidPerLesson = totalPaid / totalLessons;
          const usedPaid = (lessonsUsed * paidPerLesson).toFixed(2);
          finalTotalPaid = usedPaid;
        }

        let finalBalanceDue = "0";
        if (paidForLessonsTaken) {
          // For cases where they paid only for lessons taken, calculate balance due for lessons taken but not paid
          const lessonsTakenNotPaid = lessonsUsed - (parseFloat(enrollment.field_total_payments_made || "0") / lessonPrice);
          finalBalanceDue = (lessonsTakenNotPaid > 0 ? lessonsTakenNotPaid * lessonPrice : 0).toFixed(2);
        } else if (enrollment.field_balance_due) {
          const balanceDue = parseFloat(enrollment.field_balance_due);
          const duePerLesson = balanceDue / totalLessons;
          const usedDue = (lessonsUsed * duePerLesson).toFixed(2);
          finalBalanceDue = usedDue;
        }

        // Store adjustment and final values
        adjustmentRow.adjustmentLessonCount = adjustmentLessonCount;
        adjustmentRow.adjustmentTotalPrice = adjustmentTotalPrice;
        adjustmentRow.adjustmentTotalPaid = adjustmentTotalPaid;
        adjustmentRow.adjustmentBalanceDue = adjustmentBalanceDue;

        adjustmentRow.finalLessonCount = finalLessonCount;
        adjustmentRow.finalTotalPrice = finalTotalPrice;
        adjustmentRow.finalTotalPaid = finalTotalPaid;
        adjustmentRow.finalBalanceDue = finalBalanceDue;

        // Set lessons available to 0 for the adjustment
        adjustmentRow.field_lesson_available = "0";

        // Insert the adjustment row immediately after the dropped enrollment
        // We need to account for previously inserted rows, so we use the original index + offset
        processedData.splice(index + insertOffset + 1, 0, adjustmentRow);
        insertOffset++;
      }
    });

    return processedData;
  }

  updateGetData() {
    // Assuming other parts of the component rely on getData with updated state
    this.getData(this.queryParams);
  }

  /**
   * Opens the enrollment dialog with pre-populated instructor and executive fields
   */
  openEnrollment() {
    if (!this.drupal_studentAccount_id || !this.drupal_student_name) {
      console.error('Student account ID or display name is undefined');
      this._snackBar.open('Unable to open enrollment: Student information is missing', 'Close', {
        duration: 3000,
        horizontalPosition: 'center',
        panelClass: ['warning-snackbar']
      });
      return;
    }

    // First, get the student account data
    this._entityRESTService.getEntity('student_accounts', 'student_account', this.drupal_studentAccount_id)
      .subscribe(
        studentData => {
          const fieldsData = {
            field_student: `${this.drupal_student_name} (${this.drupal_studentAccount_id})`,
            field_executive: studentData?.['field_executive']?.uid || '',
            field_sale_date: moment().format('YYYY-MM-DD'),
            field_expiration_date: moment().add(6, 'months').format('YYYY-MM-DD'),
            field_enrollment_status: 'Open',
            field_visibility: false,
            giftCertificates: []
          };

          console.log('Enrollment fields data:', fieldsData);

          // Open the dialog using the dialog service directly to get the MatDialogRef
          const dialogRef = this._dialogService.openDialog(
            EnrollmentEntityComponent,
            "defaultWithData",
            {
              data: {
                EntityID: null,
                eckType: 'packages',
                bundle: 'enrollment',
                action: 'create',
                fieldsData: fieldsData,
              },
            }
          );

          // Subscribe to the afterOpened observable
          dialogRef.afterOpened().subscribe(() => {
            // Get the component instance from the dialogRef
            const enrollmentComponent = dialogRef.componentInstance as EnrollmentEntityComponent;
            if (enrollmentComponent && enrollmentComponent.enrollmentForm) {
              this.setInstructorPercentages(enrollmentComponent, studentData);
            }
          });

          // Subscribe to the afterClosed observable to refresh the data
          dialogRef.afterClosed().subscribe(() => {
            this.getData(this.queryParams);
          });
        },
        error => {
          console.error('Error fetching student data:', error);
          this._snackBar.open('Error preparing enrollment form. Please try again.', 'Close', {
            duration: 3000,
            horizontalPosition: 'center',
            panelClass: ['error-snackbar']
          });
        }
      );
  }

  /**
   * Sets the instructor percentages in the enrollment form
   */
  private setInstructorPercentages(enrollmentComponent: EnrollmentEntityComponent, studentData: any) {
    if (studentData?.['field_teacher']?.uid) {
      const instructorPercentagesArray = enrollmentComponent.enrollmentForm.get('field_instructor_percentages') as FormArray;

      // Clear existing entries
      while (instructorPercentagesArray.length !== 0) {
        instructorPercentagesArray.removeAt(0);
      }

      // Add new entry
      const newInstructorPercentage = this._formBuilder.group({
        id: [null],
        field_amount: [null],
        field_instructor: [studentData['field_teacher'].uid, Validators.required],
        field_percentage: [100, Validators.required],
        field_studio_reference: [this._authService.studios?.[0]?.id],
        bundle: ['instructor_percentages', Validators.required],
        type: ['instructor_percentages', Validators.required],
      });

      instructorPercentagesArray.push(newInstructorPercentage);

      // Trigger change detection
      enrollmentComponent.enrollmentForm.updateValueAndValidity();
      console.log('Updated enrollment form:', enrollmentComponent.enrollmentForm.value);
    }
  }
}
