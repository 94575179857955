<div class="full-screen-payment-container">
  <div class="payment-content">
    <h3 class="text-center" *ngIf="!saveCardStatus">
      Initiating card saving on the Square Terminal.
    </h3>
    <h3 class="text-center" *ngIf="saveCardStatus === 'PENDING'">
      Card saving in progress. Please follow the instructions on the Square Terminal.
    </h3>
    <h3 class="text-center" *ngIf="saveCardStatus === 'SUCCESS'">
      Card saved successfully!
    </h3>
    <h3 class="text-center" *ngIf="saveCardStatus === 'FAILURE'">
      Failed to save card. Please try again.
    </h3>
    <h3 class="text-center" *ngIf="saveCardStatus === 'CANCELED'">
      Card saving was canceled.
    </h3>

    <mat-progress-bar mode="indeterminate" *ngIf="isProcessing || saveCardStatus === 'PENDING'"></mat-progress-bar>

    <div *ngIf="saveCardStatus" class="payment-status mt-3"
         [ngClass]="{
           'text-success': saveCardStatus === 'SUCCESS',
           'text-warning': saveCardStatus === 'PENDING',
           'text-danger': saveCardStatus === 'FAILURE',
           'text-info': saveCardStatus === 'CANCELED'
         }">
      <mat-icon>
        {{ saveCardStatus === 'SUCCESS' ? 'check_circle' :
           saveCardStatus === 'PENDING' ? 'hourglass_empty' :
           saveCardStatus === 'CANCELED' ? 'cancel' : 'error' }}
      </mat-icon>
      {{ statusMessage }}
    </div>

    <div *ngIf="actionId" class="action-id mt-2">
      Action ID: {{ actionId }}
    </div>

    <button mat-raised-button
            color="warn"
            (click)="onCancelClick()"
            [disabled]="isProcessing"
            *ngIf="saveCardStatus === 'PENDING'"
            type="button">
      <mat-icon>cancel</mat-icon>
      Cancel
    </button>

    <button mat-raised-button
            color="primary"
            (click)="onClose()"
            *ngIf="saveCardStatus === 'SUCCESS' || saveCardStatus === 'CANCELED'"
            type="button">
      <mat-icon>check</mat-icon>
      Close
    </button>
  </div>
</div>
