<div class="container-fluid">
  <h1 class="page-header">Weekly Student Activity Report</h1>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Filter</mat-panel-title>
      <mat-panel-description>
        <app-filter-results-panel [parameters]="queryParams"></app-filter-results-panel>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <form (ngSubmit)="onSubmit(f)" #f="ngForm" class="form">
      <div class="row">
        <div class="col-3">
          <mat-form-field class="full-width-field">
            <mat-label>Week</mat-label>
            <mat-select matInput [ngModel]="week" name="week" (selectionChange)="onWeekChange($event)">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let element of WeeksForYearData" [value]="element.key">
                {{ element.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-3">
          <mat-form-field class="full-width-field">
            <mat-label>Year</mat-label>
            <mat-select matInput [ngModel]="year" name="year" (selectionChange)="onYearChange($event); onSubmit(f)">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let element of LastFourYearsData" [value]="element.key">
                {{ element.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <section class="section">
          <div class="float-end bring-inline">
            <button (click)="resetForm()" color="primary" mat-flat-button class="me-1">
              Clear
            </button>
          </div>
        </section>
      </div>
    </form>
  </mat-expansion-panel>

  <mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>

  <div class="row mt-3">
    <div class="col-md-12">
      <div class="float-end">
        <button mat-raised-button color="primary" class="me-2" (click)="printReport()">
          Print
        </button>
        <button mat-raised-button color="primary" class="me-2" (click)="exportReport()">
          Export
        </button>
        <button mat-raised-button color="primary" (click)="refreshData()">
          Refresh
        </button>
      </div>
    </div>
  </div>

  <!-- Instructor Tables -->
  <div class="row">
    <div class="col-12 mt-4" *ngFor="let instructor of instructorData">
      <h3>{{instructor.instructor_name}}</h3>
      <p class="mb-2">Total Lessons: {{instructor.data.with_lessons.total_lessons}}</p>

      <!-- Combined Students Table -->
      <table mat-table [dataSource]="[{
        'Front Dept': { with: instructor.data.with_lessons['Front Dept'], without: instructor.data.without_lessons['Front Dept'] },
        'Middle Dept': { with: instructor.data.with_lessons['Middle Dept'], without: instructor.data.without_lessons['Middle Dept'] },
        'Back Dept': { with: instructor.data.with_lessons['Back Dept'], without: instructor.data.without_lessons['Back Dept'] }
      }]" class="w-100 mat-elevation-z8">

        <ng-container matColumnDef="Front Dept" [style.width.%]="33">
          <th mat-header-cell *matHeaderCellDef class="border" style="width: 33.33%">Front Dept</th>
          <td mat-cell *matCellDef="let element" class="border" style="width: 33.33%">
            <!-- With Lessons -->
            <div class="mb-3">
              <strong>With Lessons ({{element['Front Dept'].with.count}})</strong>
              <div class="small text-muted" *ngFor="let student of element['Front Dept'].with.students">
								<div class="cursor-pointer" (click)="openDialog(StudentDashboardWrapperComponent, 'largerScreenDashboard', { student_id: student?.contact_ids[0] })">{{student.name}}</div>
              </div>
            </div>
            <!-- Without Lessons -->
            <div>
              <strong>Without Lessons ({{element['Front Dept'].without.count}})</strong>
              <div class="small text-muted" *ngFor="let student of element['Front Dept'].without.students">
                <div class="cursor-pointer" (click)="openDialog(StudentDashboardWrapperComponent, 'largerScreenDashboard', { student_id: student?.contact_ids[0] })">{{student.name}}</div>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="Middle Dept" [style.width.%]="33">
          <th mat-header-cell *matHeaderCellDef class="border" style="width: 33.33%">Middle Dept</th>
          <td mat-cell *matCellDef="let element" class="border" style="width: 33.33%">
            <!-- With Lessons -->
            <div class="mb-3">
              <strong>With Lessons ({{element['Middle Dept'].with.count}})</strong>
              <div class="small text-muted" *ngFor="let student of element['Middle Dept'].with.students">
                <div class="cursor-pointer" (click)="openDialog(StudentDashboardWrapperComponent, 'largerScreenDashboard', { student_id: student?.contact_ids[0] })">{{student.name}}</div>
              </div>
            </div>
            <!-- Without Lessons -->
            <div>
              <strong>Without Lessons ({{element['Middle Dept'].without.count}})</strong>
              <div class="small text-muted" *ngFor="let student of element['Middle Dept'].without.students">
                <div class="cursor-pointer" (click)="openDialog(StudentDashboardWrapperComponent, 'largerScreenDashboard', { student_id: student?.contact_ids[0] })">{{student.name}}</div>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="Back Dept" [style.width.%]="33">
          <th mat-header-cell *matHeaderCellDef class="border" style="width: 33.33%">Back Dept</th>
          <td mat-cell *matCellDef="let element" class="border" style="width: 33.33%">
            <!-- With Lessons -->
            <div class="mb-3">
              <strong>With Lessons ({{element['Back Dept'].with.count}})</strong>
              <div class="small text-muted" *ngFor="let student of element['Back Dept'].with.students">
                <div class="cursor-pointer" (click)="openDialog(StudentDashboardWrapperComponent, 'largerScreenDashboard', { student_id: student?.contact_ids[0] })">{{student.name}}</div>
              </div>
            </div>
            <!-- Without Lessons -->
            <div>
              <strong>Without Lessons ({{element['Back Dept'].without.count}})</strong>
              <div class="small text-muted" *ngFor="let student of element['Back Dept'].without.students">
                <div class="cursor-pointer" (click)="openDialog(StudentDashboardWrapperComponent, 'largerScreenDashboard', { student_id: student?.contact_ids[0] })">{{student.name}}</div>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
