import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';

@Component({
  selector: 'app-square-terminal-save-card-component',
  templateUrl: './square-terminal-save-card-component.component.html',
  styleUrls: ['./square-terminal-save-card-component.component.css']
})
export class SquareTerminalSaveCardComponent implements OnInit, OnDestroy {
  studentAccountId: string | null = null;
  saveCardStatus: 'SUCCESS' | 'PENDING' | 'FAILURE' | 'CANCELED' | null = null;
  statusMessage: string = '';
  isProcessing: boolean = false;
  actionId: string | null = null;
  private pollingInterval: any;

  constructor(
    private drupalRESTService: DrupalRESTService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<SquareTerminalSaveCardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.studentAccountId = data.studentAccountId;
  }

  ngOnInit() {
    if (!this.studentAccountId || !this.data.studioId) {
      console.error('Invalid student account ID or studio ID provided:', { studentAccountId: this.studentAccountId, studioId: this.data.studioId });
      this.snackBar.open('Invalid student account ID or studio ID provided', 'Close', { duration: 5000 });
      this.dialogRef.close({ status: 'ERROR', message: 'Invalid student account ID or studio ID' });
    } else {
      console.log('SquareTerminalSaveCardComponent initialized with:', { studentAccountId: this.studentAccountId });
      this.initiateSaveCard();
    }
  }

  private initiateSaveCard() {
    if (this.isProcessing) return;

    this.isProcessing = true;
    this.saveCardStatus = null;
    this.statusMessage = '';
    this.actionId = null;

    const endpoint = `/api/square/terminal/save-card/${this.data.studioId}`;
    const body = {
      studentAccountId: this.studentAccountId,
      shouldSaveCard: true,
      idempotencyKey: this.data.idempotencyKey
    };

    this.drupalRESTService.httpPOST(endpoint, body).subscribe({
      next: (response: any) => {
        if (response.success && response.action?.id) {
          this.actionId = response.action.id;
          this.saveCardStatus = 'PENDING';
          this.statusMessage = 'Please follow the instructions on the terminal';
          this.isProcessing = false;
          this.startPolling();
        } else {
          this.handleError(new Error('Failed to initiate card saving'));
        }
      },
      error: (error) => this.handleError(error)
    });
  }

  private startPolling() {
    this.pollingInterval = setInterval(() => {
      if (!this.actionId || !this.data.studioId) return;

      // Use the new action-status endpoint instead of the checkout status endpoint
      const endpoint = `/api/square/terminal/action-status/${this.data.studioId}/${this.actionId}`;
      this.drupalRESTService.httpGET(endpoint).subscribe({
        next: (response: any) => {
          if (response?.action) {
            switch (response.action.status) {
              case 'COMPLETED':
                this.saveCardStatus = 'SUCCESS';
                this.statusMessage = 'Card saved successfully';
                this.stopPolling();
                this.onClose();
                break;
              case 'CANCELED':
                this.saveCardStatus = 'CANCELED';
                this.statusMessage = 'Card saving was canceled';
                this.stopPolling();
                this.onClose();
                break;
              case 'FAILED':
                this.saveCardStatus = 'FAILURE';
                this.statusMessage = 'Failed to save card';
                this.stopPolling();
                this.onClose();
                break;
              case 'IN_PROGRESS':
              case 'PENDING':
                this.saveCardStatus = 'PENDING';
                this.statusMessage = 'Card saving in progress...';
                break;
            }
          }
        },
        error: (error) => {
          console.error('Error polling save card status:', error);
          this.saveCardStatus = 'FAILURE';
          this.statusMessage = 'Error checking save card status';
        }
      });
    }, 2000);
  }

  onCancelClick() {
    if (!this.actionId || !this.data.studioId) return;

    const endpoint = `/api/square/terminal/cancel/${this.data.studioId}/${this.actionId}`;
    this.drupalRESTService.httpPOST(endpoint, {}).subscribe({
      next: () => {
        this.saveCardStatus = 'CANCELED';
        this.statusMessage = 'Card saving canceled';
        this.stopPolling();
      },
      error: (error) => {
        console.error('Error canceling card save:', error);
        this.snackBar.open('Failed to cancel card saving', 'Close', { duration: 5000 });
      }
    });
  }

  onClose() {
    this.dialogRef.close({
      status: this.saveCardStatus,
      actionId: this.actionId,
      error: this.saveCardStatus === 'FAILURE' ? this.statusMessage : null
    });
  }

  private handleError(error: any) {
    this.isProcessing = false;
    this.saveCardStatus = 'FAILURE';
    this.statusMessage = error.message || 'Failed to save card';
    console.error('Error saving card:', error);
    this.snackBar.open(this.statusMessage, 'Close', { duration: 5000 });
    this.onClose();
  }

  private stopPolling() {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
      this.pollingInterval = null;
    }
  }

  ngOnDestroy() {
    this.stopPolling();
  }
}
