import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { DrupalRESTService } from './drupal-rest.service';

interface PrintOptions {
  title: string;
  styles?: string;
  promptPrint?: boolean;
  additionalHeadContent?: string;
  showSchoolName?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  private studioDisplayName: string;

  private normalizeStyles = `
    /* normalize.css v8.0.1 */
    html{line-height:1.15;-webkit-text-size-adjust:100%}
    body{margin:0}
    main{display:block}
    h1{font-size:2em;margin:.67em 0}
    hr{box-sizing:content-box;height:0;overflow:visible}
    pre{font-family:monospace,monospace;font-size:1em}
    a{background-color:transparent}
    abbr[title]{border-bottom:none;text-decoration:underline;text-decoration:underline dotted}
    b,strong{font-weight:bolder}
    code,kbd,samp{font-family:monospace,monospace;font-size:1em}
    small{font-size:80%}
    sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline}
    sub{bottom:-.25em}
    sup{top:-.5em}
    img{border-style:none}
    button,input,optgroup,select,textarea{font-family:inherit;font-size:100%;line-height:1.15;margin:0}
    button,input{overflow:visible}
    button,select{text-transform:none}
    button,[type="button"],[type="reset"],[type="submit"]{-webkit-appearance:button}
    button::-moz-focus-inner,[type="button"]::-moz-focus-inner,[type="reset"]::-moz-focus-inner,[type="submit"]::-moz-focus-inner{border-style:none;padding:0}
    button:-moz-focusring,[type="button"]:-moz-focusring,[type="reset"]:-moz-focusring,[type="submit"]:-moz-focusring{outline:1px dotted ButtonText}
    fieldset{padding:.35em .75em .625em}
    legend{box-sizing:border-box;color:inherit;display:table;max-width:100%;padding:0;white-space:normal}
    progress{vertical-align:baseline}
    textarea{overflow:auto}
    [type="checkbox"],[type="radio"]{box-sizing:border-box;padding:0}
    [type="number"]::-webkit-inner-spin-button,[type="number"]::-webkit-outer-spin-button{height:auto}
    [type="search"]{-webkit-appearance:textfield;outline-offset:-2px}
    [type="search"]::-webkit-search-decoration{-webkit-appearance:none}
    ::-webkit-file-upload-button{-webkit-appearance:button;font:inherit}
    details{display:block}
    summary{display:list-item}
    template{display:none}
    [hidden]{display:none}
  `;

  private defaultStyles = `
    body {
      font-family: Arial, sans-serif;
      line-height: 1.4;
      color: #333;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;
      page-break-inside: avoid;
    }
    th, td {
      border: 1px solid #ddd;
      padding: 8px;
      text-align: left;
    }
    th {
      background-color: #f5f5f5;
      font-weight: bold;
    }
    .report-header {
      margin-bottom: 20px;
      text-align: center;
    }
    .report-header h2 {
      margin: 0 0 10px 0;
      font-size: 24px;
    }
    .school-name {
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 20px;
      color: #333;
      text-transform: uppercase;
      border-bottom: 2px solid #333;
      padding-bottom: 10px;
      letter-spacing: 1px;
    }
    .section {
      margin-bottom: 30px;
      page-break-inside: avoid;
    }
    @media print {
      @page {
        margin: 0.5in;
      }
      body {
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
      }
      .no-print {
        display: none;
      }
      .section {
        page-break-inside: avoid;
      }
      .school-name {
        border-bottom-color: #333 !important;
      }
    }
  `;

  constructor(
    private _authService: AuthService,
    private _drupalRESTService: DrupalRESTService
  ) {
    this.loadStudioDisplayName();
  }

  private loadStudioDisplayName() {
    this._drupalRESTService.httpGET('/api_rest/v1/getStudioInformation')
      .subscribe(
        (data: any) => {
          this.studioDisplayName = data?.field_studio_display_name?.[0]?.value;
        },
        error => {
          console.error('Error loading studio display name:', error);
        }
      );
  }

  print(content: string, options: PrintOptions) {
    const printWindow = window.open('', '_blank');
    if (!printWindow) return;

    const combinedStyles = `
      ${this.normalizeStyles}
      ${this.defaultStyles}
      ${options.styles || ''}
    `;

    const schoolNameHtml = options.showSchoolName && this.studioDisplayName
      ? `<div class="school-name">${this.studioDisplayName}</div>`
      : '';

    const printContent = `
      <!DOCTYPE html>
      <html>
        <head>
          <meta charset="utf-8">
          <meta name="viewport" content="width=device-width, initial-scale=1">
          <title>${options.title}</title>
          <style>${combinedStyles}</style>
          ${options.additionalHeadContent || ''}
        </head>
        <body ${options.promptPrint ? 'onload="window.print();window.close();"' : ''}>
          <div class="report-header">
            ${schoolNameHtml}
            ${content}
          </div>
          <div class="no-print">
            <button onclick="window.print()">Print</button>
            <button onclick="window.close()">Close</button>
          </div>
        </body>
      </html>
    `;

    printWindow.document.write(printContent);
    printWindow.document.close();
  }

  /**
   * Simple print that uses the browser's native print functionality
   */
  simplePrint() {
    window.print();
  }
}
