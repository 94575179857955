import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ViewTableComponent } from '../../base/view-table/view-table.component';
import { EnrollmentEntityComponent } from '../../forms/enrollment-entity/enrollment-entity.component';
import { StudentDashboardWrapperComponent } from '../../student/student-dashboard-wrapper/student-dashboard-wrapper.component';
import { ViewPaymentsFromEnrollmentComponent } from '../../forms/view-payments-from-enrollment/view-payments-from-enrollment.component';
import { PageEvent } from '@angular/material/paginator';
import { HttpParameter } from 'src/app/types/http-parameter';

@Component({
  selector: 'app-enrollments-view',
  templateUrl: './enrollments-view.component.html',
  styleUrls: ['./enrollments-view.component.css']
})
export class EnrollmentsViewComponent extends ViewTableComponent implements OnInit {

  override displayedColumns: string[] = [
    'enrollmentID',
    'field_contacts',
    'field_sale_date',
    'field_instructor',
    'field_category',
    'field_package_name',
    'field_enrollment_lesson_count',
    'field_enrollment_total_price',
    'field_total_paid',
    'field_balance_due',
    'field_additional_notes',
    'field_lesson_available',
    'isDropped',
    // 'nothing',
    'nothing_1',
  ];

  StudentDashboardWrapperComponent = StudentDashboardWrapperComponent;
  ViewPaymentsFromEnrollmentComponent = ViewPaymentsFromEnrollmentComponent;

  override ngOnInit() {
    // Initialize pagination values
    this.paginationIndex = 0;
    this.pageSize = 10;  // Set default page size

    // Call parent ngOnInit if it exists
    super.ngOnInit();

    // Initial data load with pagination parameters
    this.getData([
      { parameter: 'page', value: this.paginationIndex.toString() },
      { parameter: 'items_per_page', value: this.pageSize.toString() }
    ]);
  }

  override getData(params: { parameter: string; value: string; }[]) {
    this.ShowProgressBar = true;

    // Store only non-pagination params in queryParams
    this.queryParams = params.filter(param =>
      param.parameter !== 'page' && param.parameter !== 'items_per_page'
    );

    // Add current pagination state to params if not present
    let finalParams = [...params];
    if (!params.some(p => p.parameter === 'page')) {
      finalParams.push(
        { parameter: 'page', value: this.paginationIndex.toString() },
        { parameter: 'items_per_page', value: this.pageSize.toString() }
      );
    }

    // Add sorting parameters if available
    if (this.sortParams !== undefined) {
      finalParams.push(this.sortParams);
    }

    this._drupalRESTService.httpGET("/api/v1/enrollment_list", finalParams)
      .subscribe((data) => {
        this.ShowProgressBar = false;
        this.data = data;

        // Process data to add dropped enrollment rows
        const processedData = this.processDroppedEnrollments(data['results']);
        this.dataSource = new MatTableDataSource(processedData);

        // Pagination
        if (data['results']) {
          this.setupPagination(data, params);
        }

      }, error => this.handleError(error))
  }

  /**
   * Process dropped enrollments to add adjustment rows
   */
  processDroppedEnrollments(enrollments: any[]): any[] {
    if (!enrollments || enrollments.length === 0) {
      return [];
    }

    const processedData = [...enrollments];
    let insertOffset = 0; // Track how many rows we've inserted

    // Find dropped enrollments and create adjustment rows
    enrollments.forEach((enrollment, index) => {
      if (enrollment.field_dropped_date) {
        // Create a single adjustment row for this dropped enrollment
        const adjustmentRow = { ...enrollment };

        // Mark as an adjustment row
        adjustmentRow.isAdjustmentRow = true;
        adjustmentRow.showTotals = true;

        // Ensure the adjustment row is also marked as a dropped enrollment
        // This will help with styling consistency
        adjustmentRow.field_dropped_date = enrollment.field_dropped_date;

        // Generate a unique ID for the adjustment row
        adjustmentRow.id = `${enrollment.id}-adjustment`;

        const lessonsUsed = enrollment.field_lesson_used ? parseInt(enrollment.field_lesson_used) : 0;
        const totalLessons = parseInt(enrollment.field_enrollment_lesson_count);
        const unusedLessons = totalLessons - lessonsUsed;

        // Calculate the per-lesson price
        const lessonPrice = parseFloat(enrollment.field_enrollment_total_price) / totalLessons;

        // Store original values for display
        adjustmentRow.originalLessonCount = enrollment.field_enrollment_lesson_count;
        adjustmentRow.originalTotalPrice = enrollment.field_enrollment_total_price;
        adjustmentRow.originalTotalPaid = enrollment.field_total_payments_made;
        adjustmentRow.originalBalanceDue = enrollment.field_balance_due;

        // Set the adjustment values (only subtract the unused lessons)
        const adjustmentLessonCount = `-${unusedLessons}`;

        // Calculate the price for unused lessons
        const unusedLessonsPrice = (unusedLessons * lessonPrice).toFixed(2);
        const adjustmentTotalPrice = `-${unusedLessonsPrice}`;

        // Calculate adjustments for payments and balance due
        let adjustmentTotalPaid = "0";
        let adjustmentBalanceDue = "0";

        // Determine if payment was made only for lessons taken or for all lessons
        const isByTheLesson = enrollment.field_payments_structure_type === "By The Lesson";

        // Check if the amount paid corresponds to lessons taken or total lessons
        let paidForLessonsTaken = false;

        if (enrollment.field_total_payments_made) {
          const totalPaid = parseFloat(enrollment.field_total_payments_made);
          const expectedPaymentForUsedLessons = lessonsUsed * lessonPrice;

          // If the payment is close to the expected payment for used lessons (within $1 to account for rounding)
          // or if it's a "By The Lesson" payment structure, assume they paid only for lessons taken
          paidForLessonsTaken = Math.abs(totalPaid - expectedPaymentForUsedLessons) < 1 || isByTheLesson;

          // For cases where they paid only for lessons taken
          if (paidForLessonsTaken) {
            // No adjustment needed for payments
            adjustmentTotalPaid = "0";
          } else {
            // For cases where they paid for all lessons, calculate proportional adjustment
            const paidPerLesson = totalPaid / totalLessons;
            const unusedPaid = (unusedLessons * paidPerLesson).toFixed(2);
            adjustmentTotalPaid = `-${unusedPaid}`;
          }
        }

        if (enrollment.field_balance_due) {
          const balanceDue = parseFloat(enrollment.field_balance_due);

          // If they paid only for lessons taken, the balance due is likely for unused lessons
          if (paidForLessonsTaken) {
            // The balance due should be reduced by the full amount of unused lessons
            const unusedDue = (unusedLessons * lessonPrice).toFixed(2);
            adjustmentBalanceDue = `-${unusedDue}`;
          } else {
            // For other cases, calculate proportional adjustment
            const duePerLesson = balanceDue / totalLessons;
            const unusedDue = (unusedLessons * duePerLesson).toFixed(2);
            adjustmentBalanceDue = `-${unusedDue}`;
          }
        }

        // Calculate final values after adjustment
        const finalLessonCount = lessonsUsed;
        const finalTotalPrice = (parseFloat(enrollment.field_enrollment_total_price) - parseFloat(unusedLessonsPrice)).toFixed(2);

        let finalTotalPaid = enrollment.field_total_payments_made || "0";
        if (!paidForLessonsTaken && enrollment.field_total_payments_made) {
          const totalPaid = parseFloat(enrollment.field_total_payments_made);
          const paidPerLesson = totalPaid / totalLessons;
          const usedPaid = (lessonsUsed * paidPerLesson).toFixed(2);
          finalTotalPaid = usedPaid;
        }

        let finalBalanceDue = "0";
        if (paidForLessonsTaken) {
          // For cases where they paid only for lessons taken, calculate balance due for lessons taken but not paid
          const lessonsTakenNotPaid = lessonsUsed - (parseFloat(enrollment.field_total_payments_made || "0") / lessonPrice);
          finalBalanceDue = (lessonsTakenNotPaid > 0 ? lessonsTakenNotPaid * lessonPrice : 0).toFixed(2);
        } else if (enrollment.field_balance_due) {
          const balanceDue = parseFloat(enrollment.field_balance_due);
          const duePerLesson = balanceDue / totalLessons;
          const usedDue = (lessonsUsed * duePerLesson).toFixed(2);
          finalBalanceDue = usedDue;
        }

        // Store adjustment and final values
        adjustmentRow.adjustmentLessonCount = adjustmentLessonCount;
        adjustmentRow.adjustmentTotalPrice = adjustmentTotalPrice;
        adjustmentRow.adjustmentTotalPaid = adjustmentTotalPaid;
        adjustmentRow.adjustmentBalanceDue = adjustmentBalanceDue;

        adjustmentRow.finalLessonCount = finalLessonCount;
        adjustmentRow.finalTotalPrice = finalTotalPrice;
        adjustmentRow.finalTotalPaid = finalTotalPaid;
        adjustmentRow.finalBalanceDue = finalBalanceDue;

        // Set lessons available to 0 for the adjustment
        adjustmentRow.field_lesson_available = "0";

        // Insert the adjustment row immediately after the dropped enrollment
        // We need to account for previously inserted rows, so we use the original index + offset
        processedData.splice(index + insertOffset + 1, 0, adjustmentRow);
        insertOffset++;
      }
    });

    return processedData;
  }

  override resetForm() {
    // Clear all stored params
    this.queryParams = [];
    this.sortParams = undefined;

    // Reset pagination
    if (this.paginator) {
      this.paginator.pageIndex = 0;
      this.paginationIndex = 0;  // Reset the pagination index
      this.pageSize = 10;  // Reset page size
    }

    // Clear the form
    this.form.resetForm();

    // Clear any autocomplete options
    this.autoCompleteStudentAccountOptions = [];
    this.autoCompletePackageOptions = [];

    // Make a fresh request with explicitly empty params
    this.getData([
      { parameter: 'page', value: '0' },
      { parameter: 'items_per_page', value: '10' }
    ]);

    // Update router last
    this.updateRouterParams({});
  }

  override pageChanged(event: PageEvent) {
    // Update pagination state
    this.paginationIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    // Always include pagination parameters
    const paginationParams: HttpParameter[] = [
      { parameter: 'page', value: this.paginationIndex.toString() },
      { parameter: 'items_per_page', value: this.pageSize.toString() }
    ];

    // Filter out any existing pagination params from queryParams
    const filteredQueryParams = this.queryParams.filter(param =>
      param.parameter !== 'page' && param.parameter !== 'items_per_page'
    );

    // Combine filtered query params with new pagination params
    const combinedParams = [...filteredQueryParams, ...paginationParams];
    this.getData(combinedParams);
  }

  override onPageSizeOptions() {
    return [10, 25, 50];
  }

  isInteger(value: string): boolean {
    if (value && !isNaN(+value)) {
      return Number(value) % 1 === 0;
    }
    return false;
  }
}
