import { Component, Input, OnInit } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ViewTableComponent } from '../../base/view-table/view-table.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import moment from 'moment';
import { PaymentsEntityComponent } from '../../forms/payments-entity/payments-entity.component';
import { StudentDashboardWrapperComponent } from '../../student/student-dashboard-wrapper/student-dashboard-wrapper.component';
import { PageEvent } from '@angular/material/paginator';
import { HttpParameter } from 'src/app/types/http-parameter';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'MMM DD, YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
}

@Component({
  selector: 'app-payments-report-view',
  templateUrl: './payments-report-view.component.html',
  styleUrls: ['./payments-report-view.component.css'],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: MY_DATE_FORMATS }
  ]
})

/**
 * URL: /payment-reports
 */
export class PaymentsReportViewComponent extends ViewTableComponent implements OnInit {

  StudentDashboardWrapperComponent = StudentDashboardWrapperComponent;
  override PaymentsEntityComponent = PaymentsEntityComponent;

  minDate: Date; // Holds the selected min date
  maxDate: Date; // Holds the calculated max date (24 hours from minDate)

  override displayedColumns: string[] = [
    'field_student_name',
    'field_payment_id',
    'field_date_and_time',
    'field_gross_tuition',
    'field_tax_collected',
    'field_enrollment_name',
    'field_payment_type',
    // 'field_status',
    'field_teacher',
    'field_notes',
    'edit',
  ];

  override ngOnInit() {
    // Initialize pagination values
    this.paginationIndex = 0;
    this.pageSize = 10;  // Set default page size

    // Call parent ngOnInit if it exists
    super.ngOnInit();

    // Initial data load
    this.getData([
      { parameter: 'page', value: this.paginationIndex.toString() },
      { parameter: 'items_per_page', value: this.pageSize.toString() }
    ]);
  }

  override getData(params: { parameter: string; value: string; }[]) {
    // Show Progress Bar (Loading indicator)
    this.ShowProgressBar = true;
    this.queryParams = params;

    // Add sorting paramaters, if available.
    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams)
    }

    // Load the new data with the filtering parameters.
    this._utilityService.getPaymentReport(
      this.queryParams
    )
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;

        this.data = data;
        this.dataSource = new MatTableDataSource(data['results']);

        // Check if any of the data has a non-zero or non-empty field_tax_amount
        const hasTaxData = data && data['results'] && data['results'].some(
          (item: any) =>
            item.field_tax_collected !== '' &&
            item.field_tax_collected !== '0' &&
            item.field_tax_collected !== '0.00'
        );

        if (hasTaxData) {
          // If tax data exists, include the "Tax" column
          if (!this.displayedColumns.includes('field_tax_collected')) {
            this.displayedColumns.push('field_tax_collected');
          }
        } else {
          // If no tax data exists, remove the "Tax" column
          const taxColumnIndex = this.displayedColumns.indexOf('field_tax_amount');
          if (taxColumnIndex !== -1) {
            this.displayedColumns.splice(taxColumnIndex, 1);
          }
        }

        // Pagination
        if (data['results']) {
          this.setupPagination(data, params);
        }
      },
        error => this.handleError(error)
      )
  }

  updatePickerMax(event: any): void {
    if (event.value) {
      this.maxDate = moment(event.value).add(24, 'hours').toDate();
      const formattedMinDate = moment(this.minDate).format('YYYY-MM-DD');
      const formattedMaxDate = moment(this.maxDate).format('YYYY-MM-DD');

      // Include minDate and maxDate in the queryParams
      // Make sure to replace or update the existing date parameters instead of just pushing new ones
      const existingMinIndex = this.queryParams.findIndex(param => param.parameter === 'minDate');
      const existingMaxIndex = this.queryParams.findIndex(param => param.parameter === 'maxDate');

      if (existingMinIndex !== -1) {
        this.queryParams[existingMinIndex].value = formattedMinDate;
      } else {
        this.queryParams.push({ parameter: 'field_date_and_time_value[min]', value: formattedMinDate });
      }

      if (existingMaxIndex !== -1) {
        this.queryParams[existingMaxIndex].value = formattedMaxDate;
      } else {
        this.queryParams.push({ parameter: 'field_date_and_time_value[max]', value: formattedMaxDate });
      }

      // Now call the method to fetch data with updated parameters
      this.getData(this.queryParams);
    }
  }

  refreshData(): void {
    this.getData(this.queryParams);
  }

  override resetForm() {
    // Clear all stored params
    this.queryParams = [];
    this.sortParams = undefined;

    // Reset pagination
    if (this.paginator) {
      this.paginator.pageIndex = 0;
      this.paginationIndex = 0;  // Reset the pagination index
      this.pageSize = 10;  // Reset page size
    }

    // Clear the form
    this.form.resetForm();

    // Clear any autocomplete options
    this.autoCompleteStudentAccountOptions = [];
    this.autoCompletePaymentIDOptions = [];

    // Reset date pickers
    this.minDate = null;
    this.maxDate = null;

    // Make a fresh request with explicitly empty params
    this.getData([
      { parameter: 'page', value: '0' },
      { parameter: 'items_per_page', value: '10' }
    ]);

    // Update router last
    this.updateRouterParams({});
  }

  override pageChanged(event: PageEvent) {
    // Update pagination state
    this.paginationIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    // Always include pagination parameters
    const paginationParams: HttpParameter[] = [
      { parameter: 'page', value: this.paginationIndex.toString() },
      { parameter: 'items_per_page', value: this.pageSize.toString() }
    ];

    // Filter out any existing pagination params from queryParams
    const filteredQueryParams = this.queryParams.filter(param =>
      param.parameter !== 'page' && param.parameter !== 'items_per_page'
    );

    // Combine filtered query params with new pagination params
    const combinedParams = [...filteredQueryParams, ...paginationParams];
    this.getData(combinedParams);
  }
}
