<div class="container-fluid">
  <h1 class="page-header">Staff Success Report</h1>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Filter</mat-panel-title>
      <mat-panel-description>
        <app-filter-results-panel [parameters]="queryParams"></app-filter-results-panel>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <form (ngSubmit)="onSubmit(f)" #f="ngForm" class="form">
      <div class="row">
        <div class="col-3">
          <mat-form-field class="full-width-field">
            <mat-label>Week</mat-label>
            <mat-select matInput [ngModel]="week" name="week" (selectionChange)="onSubmit(f)">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let element of WeeksForYearData" [value]="element.key">
                {{ element.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-3">
          <mat-form-field class="full-width-field">
            <mat-label>Year</mat-label>
            <mat-select matInput [ngModel]="year" name="year" (selectionChange)="onYearChange($event); onSubmit(f)">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let element of LastFourYearsData" [value]="element.key">
                {{ element.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <section class="section">
          <div class="float-end bring-inline">
            <button (click)="resetForm()" color="primary" mat-flat-button class="me-1">
              Clear
            </button>
          </div>
        </section>
      </div>
    </form>
  </mat-expansion-panel>

  <mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>

  <div class="row mt-3">
    <div class="col-md-12">
      <div class="float-end">
        <button mat-raised-button color="primary" class="me-2" (click)="printReport()">
          Print
        </button>
        <button mat-raised-button color="primary" class="me-2" (click)="exportReport()">
          Export
        </button>
        <button mat-raised-button color="primary" (click)="refreshData()">
          Refresh
        </button>
      </div>
    </div>
  </div>

  <!-- Instructor Tables -->
  <div class="row">
    <div class="col-6 mt-4" *ngFor="let instructor of instructorData">
      <h3>{{instructor.instructor_name}}</h3>

			<table mat-table [dataSource]="getCategoryData(instructor)" class="w-100">
				<!-- Category Column -->
				<ng-container matColumnDef="category">
					<th mat-header-cell *matHeaderCellDef>Category</th>
					<td mat-cell *matCellDef="let element">{{element.category}}</td>
				</ng-container>

				<!-- Tried Column -->
				<ng-container matColumnDef="tried">
					<th mat-header-cell *matHeaderCellDef># Tried (Wk)</th>
					<td mat-cell *matCellDef="let element">{{element['# Tried'] || '-'}}</td>
				</ng-container>

				<!-- Sold Column -->
				<ng-container matColumnDef="sold">
					<th mat-header-cell *matHeaderCellDef># Sold (Wk)</th>
					<td mat-cell *matCellDef="let element">{{element['# Sold'] || '-'}}</td>
				</ng-container>

				<!-- % Sold (Wk) Column -->
				<ng-container matColumnDef="percentWk">
					<th mat-header-cell *matHeaderCellDef>% Sold (Wk)</th>
					<td mat-cell *matCellDef="let element">{{element['% Sold (Wk)'] !== null ? element['% Sold (Wk)'] + '%' : '-'}}</td>
				</ng-container>

				<!-- Tried YTD Column -->
				<ng-container matColumnDef="triedYtd">
					<th mat-header-cell *matHeaderCellDef># Tried YTD</th>
					<td mat-cell *matCellDef="let element">{{element['# Tried YTD'] || '-'}}</td>
				</ng-container>

				<!-- Sold YTD Column -->
				<ng-container matColumnDef="soldYtd">
					<th mat-header-cell *matHeaderCellDef># Sold YTD</th>
					<td mat-cell *matCellDef="let element">{{element['# Sold YTD'] || '-'}}</td>
				</ng-container>

				<!-- % Sold (YTD) Column -->
				<ng-container matColumnDef="percentYtd">
					<th mat-header-cell *matHeaderCellDef>% Sold (YTD)</th>
					<td mat-cell *matCellDef="let element">{{element['% Sold (YTD)'] !== null ? element['% Sold (YTD)'] + '%' : '-'}}</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
			</table>
    </div>
  </div>

</div>
