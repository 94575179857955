import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ViewTableComponent } from '../../base/view-table/view-table.component';
import { PageEvent } from '@angular/material/paginator';
import { HttpParameter } from 'src/app/types/http-parameter';

@Component({
  selector: 'app-payments-made-sundry',
  templateUrl: './payments-made-sundry.component.html',
  styleUrls: ['./payments-made-sundry.component.css']
})
export class PaymentsMadeSundryComponent extends ViewTableComponent implements OnInit {

  currentPage: number;

  override displayedColumns: string[] = [
    'id',
    'field_date_and_time',
    'field_student_name',
    'field_student_department',
    'field_enrollment_package_name_label',
    // 'AMOUNTPAID',
    'field_payment_type_label',
    'field_instructor_label',
    'closer',
  ];

  override ngOnInit() {
    // Initialize pagination values
    this.paginationIndex = 0;
    this.pageSize = 10;  // Set default page size

    // Call parent ngOnInit if it exists
    super.ngOnInit();

    // Initial data load with pagination parameters
    this.getData([
      { parameter: 'page', value: this.paginationIndex.toString() },
      { parameter: 'items_per_page', value: this.pageSize.toString() }
    ]);
  }

  override getData(params: { parameter: string; value: string; }[]) {
    this.ShowProgressBar = true;

    // Store only non-pagination params in queryParams
    this.queryParams = params.filter(param =>
      param.parameter !== 'page' && param.parameter !== 'items_per_page'
    );

    // Add current pagination state to params if not present
    let finalParams = [...params];
    if (!params.some(p => p.parameter === 'page')) {
      finalParams.push(
        { parameter: 'page', value: this.paginationIndex.toString() },
        { parameter: 'items_per_page', value: this.pageSize.toString() }
      );
    }

    // Add sorting parameters if available
    if (this.sortParams !== undefined) {
      finalParams.push(this.sortParams);
    }

    // Load the new data with the filtering parameters.
    let endpoint: string = "/api/v1/reports/cash?field_sps_code=558";

    this._drupalRESTService.httpGET(endpoint, finalParams)
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;

        this.dataSource = new MatTableDataSource(data['results']);

        // Pagination
        if (data['results']) {
          this.setupPagination(data, params);
        }
      },
        error => this.handleError(error)
      )
  }

  override resetForm() {
    // Clear all stored params
    this.queryParams = [];
    this.sortParams = undefined;

    // Reset pagination
    if (this.paginator) {
      this.paginator.pageIndex = 0;
      this.paginationIndex = 0;  // Reset the pagination index
      this.pageSize = 10;  // Reset page size
    }

    // Clear the form
    this.form.resetForm();

    // Clear any autocomplete options if they exist
    if (this.autoCompleteEnrollmentOptions) {
      this.autoCompleteEnrollmentOptions = [];
    }

    // Make a fresh request with explicitly empty params
    this.getData([
      { parameter: 'page', value: '0' },
      { parameter: 'items_per_page', value: '10' }
    ]);

    // Update router last
    this.updateRouterParams({});
  }

  override pageChanged(event: PageEvent) {
    // Update pagination state
    this.paginationIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    // Always include pagination parameters
    const paginationParams: HttpParameter[] = [
      { parameter: 'page', value: this.paginationIndex.toString() },
      { parameter: 'items_per_page', value: this.pageSize.toString() }
    ];

    // Filter out any existing pagination params from queryParams
    const filteredQueryParams = this.queryParams.filter(param =>
      param.parameter !== 'page' && param.parameter !== 'items_per_page'
    );

    // Combine filtered query params with new pagination params
    const combinedParams = [...filteredQueryParams, ...paginationParams];
    this.getData(combinedParams);
  }

  override onPageSizeOptions() {
    return [10, 25, 50, 100, 250];
  }
}
